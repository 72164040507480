import React, { useState } from "react";
import cx from 'classnames'
import styles from "./GFCreateFenceForm.module.css";
import geofenceAction from '../../../../../store/actions/geofence';
import { GetAddressByCoordinates } from '../../../../../store/actions/map/latlng-to-address';
import InformativeModalLight from "../../../../shared/modals/InformativeModal/InformativeModalLight";
import MultiSelect from "../../../../helpers/hoc/multi-select/MultiSelect";

const decimalToDMS = (decimal) => {
  const degrees = Math.floor(decimal)
  const minutesFloat = (decimal - degrees) * 60
  const minutes = Math.floor(minutesFloat)
  const seconds = (minutesFloat - minutes) * 60

  return `${degrees}° ${minutes}' ${seconds.toFixed(2)}"`
}

const populateCoordinates = (coordinates) => {
  if (!coordinates) {
    return '-'
  }
  const { lat, lng } = coordinates
  return `${decimalToDMS(Math.abs(lat)) + (lat >= 0 ? ' N' : ' S')}, ${decimalToDMS(Math.abs(lng)) + (lng >= 0 ? ' E' : ' W')}`
}

const GFCreateFenceForm = ({ masterData, cityOptions, fenceLocationData, setCreateFenceId, refreshGeofences, onFenceDetailsSubmit }) => {
  const [openSection, setOpenSection] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formErrorData, setFormErrorData] = useState({
    newFenceOwnerName: '',
  });
  const [apiResponse, setApiResponse] = useState({
    isOpen: false,
    message: '',
    variant: '',
    data: null,
  })

  const [formData, setFormData] = useState({
    city: null,
    fenceName: "",
    fenceType: null,
    fenceOwnerType: null,
    fenceOwnerName: null,
    newFenceOwnerName: '',
    amenities: []
  });

  const isDetailsComplete = () => {
    const { city, fenceName, fenceType, fenceOwnerType, fenceOwnerName, newFenceOwnerName } = formData;
    if (!city || !fenceName || errorMessage) {
      return false;
    }

    const isFenceTypeValid = fenceType && fenceType.value;
    const isFenceOwnerTypeValid = fenceOwnerType && fenceOwnerType.value;
    let isFenceOwnerNameValid = fenceOwnerName && fenceOwnerName.value;

    if (isFenceOwnerNameValid === 'OTHER') {
      isFenceOwnerNameValid = newFenceOwnerName;
    }

    return isFenceTypeValid && isFenceOwnerTypeValid && !!isFenceOwnerNameValid;
  };

  const toggleAccordion = (section) => {
    if (section === "location" && !isDetailsComplete()) {
      alert("Please complete the Fence Details section before proceeding.");
      return;
    }
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };


  const handleInputChange = (field, value) => {
    const newFormData = { ...formData };
    
    if (field === 'fenceName') {
      const regex = /^[0-9A-Za-z-_ ]*$/;

      if (value.startsWith(' ') || / {2,}/.test(value)) {
        setErrorMessage('Fence name cannot start with a space and cannot contain consecutive spaces.');
        return;
      }

      if (!regex.test(value)) {
        setErrorMessage('Fence name can only use letters, numbers, space, - and _');
        return;
      }

      if (value.length < 3) {
        setErrorMessage('Minimum 3 characters required.');
      }
      else if (value.length > 25) {
        setErrorMessage('25 - Character limit exceeded.');
      } else {
        setErrorMessage('');
      }
    }

    if (field === 'newFenceOwnerName') {
      const regex = /^[0-9A-Za-z-_ ]*$/;

      if (value.startsWith(' ') || / {2,}/.test(value)) {
        setFormErrorData((prevState) => ({
          ...prevState,
          newFenceOwnerName: 'Name cannot start with a space and cannot contain consecutive spaces'
        }))
        return;
      }

      if (!regex.test(value)) {
        setFormErrorData((prevState) => ({
          ...prevState,
          newFenceOwnerName: 'Name can only use letters, numbers, space, - and _'
        }))
        return;
      }

      if (value.length < 3) {
        setFormErrorData((prevState) => ({
          ...prevState,
          newFenceOwnerName: 'Minimum 3 characters required'
        }))
      } else if (value.length > 20) {
        setFormErrorData((prevState) => ({
          ...prevState,
          newFenceOwnerName: '20 - Character limit exceeded'
        }))
      } else {
        setFormErrorData((prevState) => ({
          ...prevState,
          newFenceOwnerName: ''
        }))
      }
    }

    if (field === 'fenceType') {
      newFormData.fenceOwnerType = null;
      newFormData.fenceOwnerName = null;
      newFormData.newFenceOwnerName = '';
      setFormErrorData((prevState) => ({
        ...prevState,
        newFenceOwnerName: ''
      }))
    }

    if (field === 'fenceOwnerType') {
      newFormData.fenceOwnerName = null;
      newFormData.newFenceOwnerName = '';
      setFormErrorData((prevState) => ({
        ...prevState,
        newFenceOwnerName: ''
      }))
    }

    if (field === 'fenceOwnerName') {
      newFormData.newFenceOwnerName = '';
      setFormErrorData((prevState) => ({
        ...prevState,
        newFenceOwnerName: ''
      }))
    }

    newFormData[field] = value;
    setFormData({ ...newFormData });
  };


  const handleCheckboxChange = (amenity) => {
    setFormData((prevState) => ({
      ...prevState,
      amenities: prevState.amenities.includes(amenity)
        ? prevState.amenities.filter((a) => a !== amenity)
        : [...prevState.amenities, amenity]
    }));
  };

  const handleSubmit = async () => {
    if (!isDetailsComplete()) {
      alert("Please complete all the required fields in the Fence Details section.");
      return;
    }

    let address = fenceLocationData?.address
    if (!address) {
      const res = await GetAddressByCoordinates(fenceLocationData.markerCoordinates)
      address = res?.data?.data?.address
    }

    const payload = {
      fenceName: formData.fenceName,
      city: formData.city?.value,
      address,
      fenceType: formData.fenceType?.value,
      ownerType: formData.fenceOwnerType?.value,
      ownerName: formData.fenceOwnerName.value,
      newFenceOwnerName: formData.newFenceOwnerName,
      amenities: formData.amenities,
      centerCoordinates: Object.values(fenceLocationData?.markerCoordinates),
      fenceGeometryType: fenceLocationData?.layerType,
      fenceGeometryCoordinates: fenceLocationData?.coordinates,
      fenceGeometryAreaValue: fenceLocationData?.area?.toFixed(4),
      fenceGeometryAreaUnit: "square meters"
    };


    try {
      let response = await geofenceAction.createGeofence(payload);
      if (response?.status === 200) {
        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: 'Successfully created the fence',
          variant: 'success',
          data: response?.data?.data?.fenceId
        })
        await refreshGeofences({ withMaster: formData.newFenceOwnerName ? true : false })
      } else {
        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: response?.data?.error || 'Fence creation failed',
          variant: 'error',
          data: null
        })
      }
    } catch (error) {
      console.error("Error creating geofence:", error);
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: 'An error occurred while creating the geofence.',
        variant: 'error',
        data: null
      })
    }
  };

  const handleInfoModalClose = () => {
    if (apiResponse.variant === 'success') {
      setCreateFenceId(apiResponse.data);
    }
    setApiResponse({
      ...apiResponse,
      isOpen: false,
      message: '',
      variant: '',
      data: null
    })
  }

  const AMENITIES_ICONS = {
    CHARGING: "/images/geofenceModuleIcon/chargingIcon.svg",
    PARKING: "/images/geofenceModuleIcon/parkingIcon.svg",
    SERVICING: "/images/geofenceModuleIcon/servicingIcon.svg",
    STORAGE: "/images/geofenceModuleIcon/storageIcon.svg",
    HIRING_DESK: "/images/geofenceModuleIcon/hiringDeskIcon.svg",
    LOADING_UNLOADING: "/images/geofenceModuleIcon/loadingUnload.svg",
  };

  const stepsData = [
    { detail: "Please select fence shape" },
    {
      detail:
        "Please enter the geo coordinate fence shape and press enter to move the map view of location",
    },
    { detail: "For the polygon shape, make four boundary points." },
    {
      detail:
        "Select circular and square shape, move cursor to center of compound, drag to define shape.",
    }
  ];

  const FENCE_OWNER_TYPE = masterData.FENCE_OWNER_TYPE.filter(f => {
    if (!formData.fenceType) {
      return false
    }

    return !f.deps.length || f.deps.includes(`FENCE_TYPE:${formData.fenceType.value}`)
  })

  const FENCE_OWNER_NAME = masterData.FENCE_OWNER_NAME.filter(f => {
    if (!formData.fenceOwnerType) {
      return false
    }

    return !f.deps.length || f.deps.includes(`FENCE_TYPE:${formData.fenceType.value}&&FENCE_OWNER_TYPE:${formData.fenceOwnerType.value}`)
  }).sort((a, b) => {
    if (a.value === 'OTHER') return 1;
    if (b.value === 'OTHER') return -1;
    return 0;
  });
  
  return (
    <div className={styles.accordionContainer}>
      {/* Fence Details Section */}
      <div className={styles.accordionSection}>
        <div
          className={styles.accordionHeader}
          onClick={() => toggleAccordion("details")}
        >
          <h3 className={styles.accordionHeadTitle}>Fence Details</h3>
          <span>
            <img
              src={
                openSection === "details"
                  ? "/images/geofenceModuleIcon/upArrow.svg"
                  : "/images/geofenceModuleIcon/downArrow.svg"
              }
              alt="toggle icon"
            />
          </span>
        </div>
        {openSection === "details" && (
          <div className={styles.accordionContent}>
            {/* City Select */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>City</label>
              <span className={styles.inputGroupSpan}>:</span>
              <MultiSelect
                isMultiSelect={false}
                placeholder="Select"
                options={cityOptions}
                selectedOptions={formData.city ? [formData.city] : []}
                onChange={(value) =>
                  handleInputChange("city", value[0])
                }
                customBackgroundColor="#E7ECF7"
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="210px"
                valueContainerMaxWidthInPx="210px"
                optionPosition="ps-2 justify-content-start"
                placeholderAlignment="flex-start"
                indicatorSeparatorDisplay="none"
                maxMenuHeight={140}
                isClearable={false}
                isSearchable={false}
              />
            </div>

            {/* Fence Name Input */}
            <div className={cx(styles.inputGroup, { [styles.inputGroupError]: errorMessage.length })}>
              <label className={styles.fenceDetailsLabel}>Fence Name</label>
              <span className={styles.inputGroupSpan}>:</span>
              <input
                type="text"
                placeholder="Enter"
                value={formData.fenceName}
                onChange={(e) => handleInputChange("fenceName", e.target.value)}
                className={styles.FenceNameInput}
              />
            </div>


            {errorMessage &&
              <>
                <div className={styles.errorMessageContainer}>
                  <div className={styles.supportBox}></div>
                  <div className={styles.errorMessageDiv}>
                    <img src="/images/geofenceModuleIcon/exclamationIcon.svg" alt="exclamation" />
                    <span>{errorMessage}</span>
                  </div>
                </div>

              </>
            }

            {/* Fence Type Select */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>Fence Type</label>
              <span className={styles.inputGroupSpan}>:</span>
              <MultiSelect
                isMultiSelect={false}
                placeholder="Select"
                options={masterData.FENCE_TYPE}
                selectedOptions={formData.fenceType ? [formData.fenceType] : []}
                onChange={(value) =>
                  handleInputChange("fenceType", value[0])
                }
                customBackgroundColor="#E7ECF7"
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="210px"
                valueContainerMaxWidthInPx="210px"
                optionPosition="ps-2 justify-content-start"
                placeholderAlignment="flex-start"
                indicatorSeparatorDisplay="none"
                maxMenuHeight={140}
                isClearable={false}
                isSearchable={false}
              />
            </div>

            {/* Fence Owner Type Select */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>Fence Owner Type</label>
              <span className={styles.inputGroupSpan}>:</span>
              <MultiSelect
                isMultiSelect={false}
                placeholder="Select"
                options={FENCE_OWNER_TYPE}
                selectedOptions={formData.fenceOwnerType ? [formData.fenceOwnerType] : []}
                onChange={(value) =>
                  handleInputChange("fenceOwnerType", value[0])
                }
                customBackgroundColor="#E7ECF7"
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="210px"
                valueContainerMaxWidthInPx="210px"
                optionPosition="ps-2 justify-content-start"
                placeholderAlignment="flex-start"
                indicatorSeparatorDisplay="none"
                maxMenuHeight={140}
                isClearable={false}
                isSearchable={false}
              />
            </div>

            {/* Fence Owner Name Select */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>Fence Owner Name</label>
              <span className={styles.inputGroupSpan}>:</span>
              <MultiSelect
                isMultiSelect={false}
                placeholder="Select"
                options={FENCE_OWNER_NAME}
                selectedOptions={formData.fenceOwnerName ? [formData.fenceOwnerName] : []}
                onChange={(value) =>
                  handleInputChange("fenceOwnerName", value[0])
                }
                customBackgroundColor="#E7ECF7"
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="210px"
                valueContainerMaxWidthInPx="210px"
                optionPosition="ps-2 justify-content-start"
                placeholderAlignment="flex-start"
                indicatorSeparatorDisplay="none"
                maxMenuHeight={140}
                isClearable={false}
                isSearchable={false}
              />
            </div>

            {/* New Fence Owner Name */}
            {formData.fenceOwnerName?.value === 'OTHER' && (
              <>
                <div className={cx(styles.inputGroup, { [styles.inputGroupError]: formErrorData.newFenceOwnerName })} style={{ alignItems: 'center' }}>
                  <label className={styles.fenceDetailsLabel}>New Fence Owner Name</label>
                  <span className={styles.inputGroupSpan}>:</span>
                  <input
                    type="text"
                    placeholder="Enter"
                    value={formData.newFenceOwnerName}
                    onChange={(e) => handleInputChange("newFenceOwnerName", e.target.value)}
                    className={styles.FenceNameInput}
                  />
                </div>
                {formErrorData.newFenceOwnerName &&
                  <>
                    <div className={styles.errorMessageContainer}>
                      <div className={styles.supportBox}></div>
                      <div className={styles.errorMessageDiv}>
                        <img src="/images/geofenceModuleIcon/exclamationIcon.svg" alt="exclamation" />
                        <span>{formErrorData.newFenceOwnerName}</span>
                      </div>
                    </div>

                  </>
                }
              </>
            )}


            {/* Amenities Section */}
            <div className={styles.amenities}>
              <h4 className={`${styles.accordionHeadTitle} ${styles.amenitiesTitle}`}>Amenities</h4>
              <div className={styles.amenitiesCheckbox}>
                {masterData.AMENITIES.map((amenity) => (
                  <div className={styles.amenitiesIconContainer} key={amenity.value}>
                    <input
                      type="checkbox"
                      checked={formData.amenities.includes(amenity.value)}
                      onChange={() => handleCheckboxChange(amenity.value)}
                    />
                    <img
                      src={AMENITIES_ICONS[amenity.value]}
                      alt={`${amenity.label} icon`}
                    />
                    <p>{amenity.label}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.saveBtnBox}>
              <button
                className={` ${!isDetailsComplete() ? styles.saveBtnDisabled : styles.saveBtn}`}
                onClick={() => {
                  setOpenSection('location')
                  onFenceDetailsSubmit(formData)
                }}
                disabled={!isDetailsComplete()}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Fence Location Section */}
      <div className={styles.accordionSection}>
        <div
          className={styles.accordionHeader}
          onClick={() => toggleAccordion("location")}
        >
          <h3 className={styles.accordionHeadTitle}>Fence Location</h3>
          <span>
            <img
              src={
                openSection === "location"
                  ? "/images/geofenceModuleIcon/upArrow.svg"
                  : "/images/geofenceModuleIcon/downArrow.svg"
              }
              alt="toggle icon"
            />
          </span>
        </div>
        {openSection === "location" && (
          <>
            <div className={styles.locationContentCard}>
              {stepsData.map((step, index) => (
                <div className={styles.locationStepContent} key={index}>
                  <p className={styles.locationStepLabel}>Step {index + 1}</p>
                  <span className={styles.locationStepSpan}>:</span>
                  <p className={styles.locationStepDetail}>{step.detail}</p>
                </div>
              ))}
            </div>

            <div className={styles.locationCordinatesBox}>
              <div className={styles.locationCordinatesContent}>
                <p className={styles.locationCordinatesLabel}>Coordinates</p>
                <span className={styles.inputGroupSpan}>:</span>
                <p className={styles.locationCordinatesDetail}>{populateCoordinates(fenceLocationData?.markerCoordinates)}</p>

              </div>
              <div className={styles.locationCordinatesContent}>
                <p className={styles.locationCordinatesLabel}>Fence Shape</p>
                <span className={styles.inputGroupSpan}>:</span>
                <p className={styles.locationCordinatesDetail}>{fenceLocationData?.layerType || '-'}</p>
              </div>

              <div className={styles.locationCordinatesContent}>
                <p className={styles.locationCordinatesLabel}>Fence Area</p>
                <span className={styles.inputGroupSpan}>:</span>
                <p className={styles.locationCordinatesDetail}>
                  {fenceLocationData?.area ? `${fenceLocationData.area.toFixed(2)} Sq.m.` : '-'}
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Final Submit Button */}
      <div className={`${styles.saveBtnBox} ${styles.submitButtonStyles}`}>
        <button
          className={`${!fenceLocationData || !fenceLocationData.markerCoordinates ? styles.saveBtnLocation : styles.enabledSaveLocation}`}
          disabled={!fenceLocationData || !fenceLocationData.markerCoordinates}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      <InformativeModalLight
        isOpen={apiResponse.isOpen}
        onClose={handleInfoModalClose}
        message={apiResponse.message}
        variant={apiResponse.variant}
      />
    </div>
  );
};

export default GFCreateFenceForm;
