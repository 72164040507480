
import styles from './GFEditAlertVehicles.module.css'

const GFEditGroups = (props) => {
    const { groups, setAlertDetails, handleNextClick } = props

    const handleDeleteClick = (groupData) => {
        setAlertDetails((prevState) => {
            if (groupData.type === 'group') {
                return {
                    ...prevState,
                    groups: prevState.groups.filter(g => g.groupId !== groupData.id)
                }
            }
            
            let groups = [...prevState.groups]
            const groupIndex = groups.findIndex(g => g.groupId === groupData.parentId)
            groups[groupIndex].subGroup = groups[groupIndex].subGroup.filter(sg => sg !== groupData.id)

            if (groups[groupIndex].subGroup.length === 0) {
                groups = groups.filter(g => g.groupId !== groupData.parentId)
            }
            return {
                ...prevState,
                groups
            }
        })
    }

    return (
        <div className={styles.editGroupRoot}>
            <div className={styles.editGroupList}>
                {groups.map((group, groupI) => (
                    <button key={`group_chip_${groupI}`} className={styles.editGroupChip} onClick={() => handleDeleteClick(group)}>
                        {group.name}
                        <span>x</span>
                    </button>
                ))}
            </div>

            <div className={styles.actionButtonRow}>
                <button
                    className={styles.actionButton}
                    onClick={() => handleNextClick('groups')}
                >
                    <img
                        src="/images/geofenceModuleIcon/AddAlertIcon.svg"
                        alt="toggle icon"
                    />
                    Add Group
                </button>
            </div>
        </div>
    )
}

export default GFEditGroups