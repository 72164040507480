import { useState } from 'react'
import cx from 'classnames'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { FaSearch } from 'react-icons/fa'

import styles from './GFPlacesAutocomplete.module.css'

const GFPlacesAutocomplete = (props) => {
    const [address, setAddress] = useState('')

    const handleInputChange = (value) => {
        setAddress(value)
    }

    const handleAddressSelect = async (value) => {
        const results = await geocodeByAddress(value)
        const latLong = await getLatLng(results[0])

        setAddress(value)
        props.onAddressSelect({ address: value, latLong })
    }

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleInputChange}
            onSelect={handleAddressSelect}
            shouldFetchSuggestions={address.length >= 3}
        >
            {({ getInputProps, loading, suggestions, getSuggestionItemProps }) => (
                <div className={styles.searchWrapper}>
                    <FaSearch className={styles.searchIcon} />
                    <input
                        {...getInputProps()}
                        type="text"
                        className={styles.searchInput}
                        placeholder='Search by coordinates'
                        value={address}
                    />

                    <div className={cx(styles.searchResultWrapper, { [styles.noAddress]: !address || !suggestions.length })}>
                        {loading && <span>Loading...</span>}
                        {suggestions.map((suggestion) => {
                            return (
                                <div
                                    key={`search_suggestion_${suggestion.index}`}
                                    className={cx(styles.searchResult, { [styles.active]: suggestion.active })}
                                    {...getSuggestionItemProps(suggestion)}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

export default GFPlacesAutocomplete;