import React from "react";
import { Container, Col, Row} from 'react-bootstrap'
import Trend from "../../Geo-fencing/analytics/child-components/Trend"; 
import Report from "../../Geo-fencing/analytics/child-components/Report"; 
import { useSelector } from "react-redux";
import Loader from "../../helpers/hoc/loader/Loader"; 
import './geofenceAnalytics.css'

const GeofenceAnalytics = (props)=>{

    // Redux States
    const isLoading = useSelector((s) => s?.loader?.isLoading)

    return (
        <div className='col-12'>
              {/* <div className='heading-gt'style={{font: 'normal normal 600 18px/39px Inter', letterSpacing: '0px', color: '#1F2A5D', marginTop:10, marginLeft:12,
            fontSize: 16, fontWeight: 'medium'
            }}>Analytics</div> */}
        <Container fluid className="analytics-container"> 
        <div className="col-12">
              <div className="mt-3 mb-3">
			  </div>
		    </div>
                <Row className="analytics-trends-report">
                <Col xs={12} className="row p-3  analytics-main-container">
                    <Trend masters={props.masters}></Trend>
                    <br></br>
                    <br></br>
                    <Report masters={props.masters}></Report>
                </Col>
                </Row>
                <Loader isLoading={isLoading}></Loader>
        </Container>
        </div>
    )
}

export default GeofenceAnalytics