import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import withGeofenceHOC from "../geofenceHOC";
import GFCreateFenceForm from "./gf-edit-fence-form/GFEditFenceForm";
import GFConfigureAlertForm from "./gf-configure-alert-form/GFConfigureAlertForm";
import GFEditMap from "./gf-edit-map/GFEditMap";
import { Tab, Tabs } from "react-bootstrap";
import styles from "./GFEdit.module.css";
import { fetchGroupList } from "../../../../store/actions/groups";
import geofenceAction from "../../../../store/actions/geofence";

const defaultMapConfig = {
  center: [20.5937, 78.9629],
  zoom: 5,
  attributionControl: false,
  maxZoom: 22,
  maxNativeZoom: 22,
  minZoom: 5,
  zoomControl: false,
  enableMarkerCluster: true,
  zoomControlPosition: "topright",
};

const GFEdit = (props) => {
  const { fenceID } = useParams();
  const searchParams = new URLSearchParams(document.location.search)

  const { mappedOptionsByVehicle, geofences, refreshGeofences } = props;

  const mapRef = useRef(null);
  const [mapConfig, setMapConfig] = useState(defaultMapConfig);
  const [activeTab, setActiveTab] = useState("CREATE_FENCE");
  const [enableMap, setEnableMap] = useState(false);
  const [fenceLocation, setFenceLocation] = useState(null);
  const [userList, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    // Fetch geofences users
    const fetchGeofencesUsers = async () => {
      try {
        const {
          data: { data: users },
        } = await geofenceAction.getUsers();

        setUserList(users);
      } catch (error) {
        console.error("Error fetching geofences:", error);
      }
    };
    fetchGeofencesUsers();

    const fetchGroupsList = async () => {
      const userId = JSON.parse(localStorage.getItem("user"))?._id;
      if (userId) {
        const result = await fetchGroupList(userId);
        setGroupList(result);
      }
    };
    fetchGroupsList();

    if (searchParams.size && ['ASSIGN_VEHICLE', 'CONFIGURE_ALERT'].includes(searchParams.get('action'))) {
      handleTabSelect('ADD_ALERT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const selectedGeofenceData = geofences.find((fence) => fence._id === fenceID);
  const usersMapped = userList.map(u => ({ label: u.name, value: u.email }))

  if (!selectedGeofenceData) {
    return <></>
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.formContainer}>
          <div className={styles.tabsBorder}></div>
          <Tabs
            id="geo-fence-create-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className={styles.alertNavLink}
          >
            <Tab
              eventKey="CREATE_FENCE"
              title={
                <span
                  className={`${styles.tabLink} ${activeTab === "CREATE_FENCE" ? styles.activeTab : ""
                    }`}
                >
                  Edit Fence
                </span>
              }
              className={styles.tabPanel}
            >
              <GFCreateFenceForm
                masterData={props?.masters}
                cityOptions={mappedOptionsByVehicle.location}
                fenceLocationData={fenceLocation}
                setEnableMap={setEnableMap}
                selectedGeofenceData={selectedGeofenceData}
                refreshGeofences={refreshGeofences}
              />
            </Tab>
            <Tab
              eventKey="ADD_ALERT"
              title={
                <span
                  className={`${styles.tabLink} ${activeTab === "ADD_ALERT" ? styles.activeTab : ""
                    }`}
                >
                  Configure Alert
                </span>
              }
              className={styles.tabPanel}
            >
              <GFConfigureAlertForm
                createdFenceId={fenceID}
                selectedGeofenceData={selectedGeofenceData}
                userList={usersMapped}
                groupList={groupList}
                mappedOptionsByVehicle={mappedOptionsByVehicle}
                refreshGeofences={refreshGeofences}
              />
            </Tab>
          </Tabs>
        </div>
      </div>

      <div className={styles.mapWrapper}>
        {!enableMap && <div className={styles.mapWrapperDisabled} />}
        <GFEditMap
          enableMap={enableMap}
          mapConfig={mapConfig}
          setMapConfig={setMapConfig}
          mapRef={mapRef}
          onGeofenceChange={setFenceLocation}
          selectedGeofenceData={selectedGeofenceData}
        />
      </div>
    </div>
  );
};

export default withGeofenceHOC(GFEdit);
