import persistReducer from "redux-persist/es/persistReducer"
import storage from "redux-persist/lib/storage";
import ActionTypes from '../actionTypes'

const initialState = {
    masters: null,
    list: [],
    locationTypes: [],
}

const geofenceMastersReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch(type) {
        case ActionTypes.GEOFENCE.MASTERS_SUCCESS: {
            return {
                ...state,
                masters: payload
            }
        }

        case ActionTypes.GEOFENCE.GEOFENCE_LIST_SUCCESS: {
            return {
                ...state,
                list: payload
            }
        }

        case ActionTypes.GEOFENCE.LOCATION_TYPE_SUCCESS: {
            return {
                ...state,
                locationTypes: payload
            }
        }

        case ActionTypes.GEOFENCE.RESET: {
            return initialState
        }
        
        default: {
            return state
        }
    }
}

export default persistReducer({
    storage,
    key: 'geofence',
    whitelist: []
}, geofenceMastersReducer)