import React from 'react'
import axios from 'axios'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import DarkSidebar from './LayoutSidebar/DarkSidebar'
import DarkTopbar from './LayoutTopbar/DarkTopbar'
import LogoutModal from '../pages/User/LogOut/LogoutModal'

import { clearLocalStorageExceptclientIP } from '../../utils/common-methods'

import '../../styles/globalStyleDark.css'
import { GetSubscribedVehiclesByCIAction } from '../../store/actions/vehicle/vehicle'
import Loader from '../helpers/hoc/loader/Loader'
import geofenceAction from '../../store/actions/geofence'

const DarkLayout = () => {
  const isLoading = useSelector(state => state.loader.isLoading)

  const navigate = useNavigate();

  const location = useLocation();

  // disable scroll for this path
  const isScrollDisable = location.pathname === '/' || location.pathname.includes('/groups/');


  const logoutClicked = useSelector((s) => s?.profileImageReducer?.logoutClicked);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GetSubscribedVehiclesByCIAction());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let _id = '';

  if (localStorage && localStorage.user) {
    _id = JSON.parse(localStorage.user)._id;
  }

  const logout = async () => {
    try {
      const res = await axios({
        url: 'user/logout',
        method: 'POST',
        headers: {
          clientIp: localStorage.clientIP,
          Authorization: `Bearer ${localStorage.token}`,
        },
        data: {
          _id
        },
      });
      if (res) {
        clearLocalStorageExceptclientIP()
        navigate('/login');
        dispatch({ type: 'logoutClicked', payload: false });
        dispatch(geofenceAction.reset())
      }
    } catch (error) {
      console.log('logouttt', error);
    }
  };

  return (
    <div className='dark-dashboard-main-container'>
      <DarkSidebar />
      <DarkTopbar />
      <section className='dark-main-section'>
        <div className={`dark-main-content ${isScrollDisable ? '' : 'user-management-scroll'}`}>
          <LogoutModal
            isVisible={logoutClicked}
            closeModal={() => dispatch({ type: 'logoutClicked', payload: false })}
            logout={logout}
          />
          <Outlet />
          <Loader isLoading={isLoading} />
        </div>
      </section>
    </div>
  )
}

export default DarkLayout