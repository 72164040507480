import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './CheckboxInput.module.css'

import {  FaCheckSquare, FaRegSquare, FaMinusSquare } from "react-icons/fa";

const CheckBoxInput = (props) => {
    const renderCheckboxComponent = () => {
        if (props.isIntermediate) {
            return <FaMinusSquare color={props.checkColor} />
        }
        if (props.value) {
            return <FaCheckSquare color={props.checkColor} />
        }
        return <FaRegSquare color={props.uncheckColor} />
    }

    return (
        <button
            onClick={props.onClick}
            className={cx(styles.checkboxButton, { [styles.checkboxButtonDisabled]: props.disabled, [props.checkboxButtonStyles]: props.checkboxButtonStyles })}
            disabled={props.disabled}
        >
            {renderCheckboxComponent()}
            
            {props.label && <span>{props.label}</span>}
        </button>
    )
}

CheckBoxInput.defaultProps = {
    label: '',
    checkColor: '#42A5F5',
    uncheckColor: '#FFFFFF',
    disabled: false,
    isIntermediate: false,
    checkboxButtonStyles: null
}

CheckBoxInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    checkColor: PropTypes.string,
    uncheckColor: PropTypes.string,
    disabled: PropTypes.bool,
    isIntermediate: PropTypes.bool,
    checkboxButtonStyles: PropTypes.string,
}

export default React.memo(CheckBoxInput)
/*conflict-resolution-will be removed later*/