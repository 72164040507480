
import styles from './GFEditAlertVehicles.module.css'

import { FaSearch } from "react-icons/fa";

import { useEffect,  useState } from 'react'
import Pagination from '../../../../helpers/hoc/paginator/Pagination';
import MultiSelect from '../../../../helpers/hoc/mult-select/MultiSelect';

const ITEMS_PER_PAGE = 8;

const GFEditVehicles = (props) => {
    const { vehicles: vehicleList, oemModel, setAlertDetails, handleNextClick } = props

    const [searchText, setSearchText] = useState("");
    const [selectedOEM, setSelectedOEM] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [selectedCity, setSelectedCity] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectAll, setSelectAll] = useState(false)

    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        setVehicles(
            vehicleList.map((vehicle) => ({
                ...vehicle,
                isSelected: false,
            }))
        )
    }, [vehicleList])

    const filteredData = vehicles.filter((item) => {
        const matchesSearchText = item.registration_number
            ?.toLowerCase()
            .includes(searchText?.toLowerCase());
        const matchesOEM = selectedOEM.length
            ? selectedOEM.some(v => `${item.oem} ${item.model}` === v.value)
            : true;
        const matchesCity = selectedCity
            ? item.location === selectedCity?.value
            : true;
        return matchesSearchText && matchesOEM && matchesCity;
    });

    const isAnyCheckboxSelected = vehicles.some((vehicle) => vehicle.isSelected);

    const manualVehicleData = filteredData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );
    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

    const handlePageChange = (page) => setCurrentPage(page);

    const handleSelectAll = () => {
        const updatedVehicles = vehicles.map((vehicle) => ({
            ...vehicle,
            isSelected: !selectAll,
        }));
        setVehicles(updatedVehicles);
        setSelectAll(!selectAll);
    };

    const handleVehicleSelect = (index) => {
        const updatedVehicles = [...manualVehicleData];
        updatedVehicles[index].isSelected = !updatedVehicles[index].isSelected;
        setVehicles(
            vehicles.map((vehicle) =>
                vehicle.registration_number ===
                    updatedVehicles[index].registration_number
                    ? { ...vehicle, isSelected: updatedVehicles[index].isSelected }
                    : vehicle
            )
        );
        // Reset selectAll if any vehicle is deselected
        if (updatedVehicles[index].isSelected === false) {
            setSelectAll(false);
        }
    };

    const handleDeleteClick = () => {
        const selectedVehicles = vehicles.filter(v => v.isSelected).map(d => d.label)

        setAlertDetails((prevState) => {
            const newVehicle = prevState.vehicle.filter(v => !selectedVehicles.includes(v))
            return {
                ...prevState,
                vehicle: newVehicle,
                vehicleCount: newVehicle.length
            }
        })
    }

    return (
        <div className={styles.manualVehicleContainer}>
            <div className={styles.filterHeaderBox}>
                <div className={styles.searchContainerGeofence}>
                    <FaSearch className={styles.vobSearchIcon} />
                    <input
                        type="text"
                        className={styles.vobSearchInput}
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                <div>
                    <MultiSelect
                        valueContainerWidthInPx="180px"
                        valueContainerMaxWidthInPx="180px"
                        menuListPaddingX="px-2"
                        okButtonPaddingEnd="pe-2"
                        placeholderAlignment='flex-start'
                        optionPosition="ps-2 justify-content-start"
                        placeholder="Select OEM and Model"
                        placeholderColor='#1F2A5D'
                        options={oemModel}
                        selectedOptions={selectedOEM}
                        isMultiSelect={true}
                        indicatorSeparatorDisplay="none"
                        minHeight="45px"
                        valueContainerColor="#1F2A5D"
                        customBackgroundColor='#EFF3F7'
                        setSelectedOptions={(options) => {
                            setSelectedOEM(options)
                            handlePageChange(1)
                        }}
                    />
                </div>
                <div style={{ flex: 1 }}></div>
                <div style={{ display: 'flex', alignItems: 'stretch', gap: '16px' }}>
                    <button
                        className={styles.actionButton}
                        onClick={() => {
                            handleNextClick('manual');
                        }}
                        disabled={isAnyCheckboxSelected}
                    >
                        <img
                            className={styles.AddAlertIcon}
                            src="/images/geofenceModuleIcon/AddAlertIcon.svg"
                            alt="toggle icon"
                        />
                        Add Vehicle
                    </button>
                    <button className={styles.actionIconButton} onClick={handleDeleteClick} disabled={!isAnyCheckboxSelected}>
                        <img
                            className={styles.alertDeleteIcon}
                            src="/images/geofenceModuleIcon/alertDeleteIcon.svg"
                            alt="Delete"
                        />
                    </button>
                </div>
            </div>

            <table className={styles.manualVehicleTable}>
                <thead className={styles.groupDataTable}>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                className={styles.checkedBoxColor}
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th>Vehicle No.</th>
                        <th>City</th>
                        <th>OEM & Model</th>
                    </tr>
                </thead>
                <tbody>
                    {manualVehicleData.length > 0 ? (
                        manualVehicleData.map((item, index) => (
                            <tr key={index} className={styles.AssignVehicltableList}>
                                <td>
                                    <input
                                        className={styles.checkedBox}
                                        type="checkbox"
                                        checked={item.isSelected}
                                        onChange={() => handleVehicleSelect(index)}
                                    />
                                </td>
                                <td>{item.registration_number}</td>
                                <td>{item.location}</td>
                                <td>
                                    {item.oem} {item.model}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className={styles.noData}>
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div>
                <Pagination
                    disabled={false}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setPage={handlePageChange}
                />
            </div>
        </div>
    )
}

export default GFEditVehicles