import React, { useEffect } from "react";
import { Row, Col, Table} from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../../helpers/hoc/paginator/Pagination";
import { setReportsCurrentPage, fetchIngressEgressSessions } from "../../../../store/actions/geo-fence/analytics/analyticsActions";


const Report = ()=> {

    const dispatch = useDispatch()
    const selectedCities = useSelector((s) => s?.analytics?.selectedCities)
    const selectedFenceTypes = useSelector((s) => s?.analytics?.selectedLabels)
    const selectedFenceOwnerTypes = useSelector((s) => s?.analytics?.selectedLabelOwners)
    const selectedFenceOwnerNames = useSelector((s) => s?.analytics?.selectedFenceOwners) 
    const startDate = useSelector((s) => s?.analytics?.startDate)
    const endDate = useSelector((s) => s?.analytics?.endDate)

    const reportCurrentPage = useSelector((s)=> s?.analytics.reportCurrentPage)
    const reportPageSize = useSelector((s)=> s?.analytics.reportPageSize)
    const reportsData = useSelector((s)=> s?.analytics.reportsData)
    const reportsDataCount = useSelector((s)=> s?.analytics.reportsDataCount)

    useEffect(()=>{
      if(startDate !== null && endDate !== null){
        dispatch(fetchIngressEgressSessions({
            selectedCities,
            selectedFenceTypes,
            selectedFenceOwnerTypes,
            selectedFenceOwnerNames,
            startDate,
            endDate,
            reportCurrentPage,
            reportPageSize
        }))
      }
    },[reportCurrentPage,dispatch]) // eslint-disable-line

    return (
        <>
            <div className="report-main-text mt-3 mb-2">Reports</div>

            <Row className="report-row p-3 mb-3 ">
                <Col className="report__table__col" >
                <Table responsive={true} borderless className="mt-4" id="analytics-fence-data">
              <thead className="table-header">
                <th className="ps-3 text-nowrap"><p className='fw500'>S.No</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Vehicle No.</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>OEM & Model</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Fence Name</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>City</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Type</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Ingress <br></br> Date & Time</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Egress <br></br> Date & Time</p></th>
              </thead>
              <tbody>
                {reportsData.map((Obj, index) => (
                  <tr key={"row-" + index.toString()}>
                    <td className="ps-3">
                      {8 * (reportCurrentPage - 1) + index + 1}.
                    </td>
                    <td>{Obj?.registrationNumber}</td>
                    <td>{Obj?.model}</td>
                    <td>{Obj?.fence}</td>
                    <td>{Obj?.city}</td>
                    <td>{Obj?.type}</td>
                    <td>{Obj?.ingressDateTime}</td>
                    <td>{Obj?.egressDateTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              disabled={false}
              totalPages={Math.ceil(reportsDataCount / reportPageSize)}
              currentPage={reportCurrentPage}
              setPage={(page)=> {dispatch(setReportsCurrentPage(page))}}
              data-testid='report-pagination'
            />
                </Col>
            </Row>
        </>
    )
}

export default Report