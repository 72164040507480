import cx from 'classnames'

import styles from './GFDetailsCard.module.css'


const GFDetailsCard = (props) => {
    const { fenceDetails, fenceTypeIconMap, onCardClick, isSelected, handleEditClick, groupList } = props
    const { fence_identifiers } = fenceDetails
    
    const calculateVehicleCount = () => {
        let vehicles = []

        Object.keys(fenceDetails.alert_rules).forEach(alertType => {
            if (fenceDetails.alert_rules[alertType].alerts && fenceDetails.alert_rules[alertType].alerts.length) {
                fenceDetails.alert_rules[alertType].alerts.forEach(alert => {
                    if (alert.vehicle && alert.vehicle.length) {
                        vehicles.push(...alert.vehicle)
                    }

                    if (alert.groups && alert.groups.length && groupList.length) {
                        alert.groups.forEach(alertGroup => {
                            const groupData = groupList.find(group => group._id === alertGroup.groupId)
                            if (groupData) {
                                if (!alertGroup.subGroup.length) {
                                    vehicles.push(...groupData.vehicles)
                                } else {
                                    alertGroup.subGroup.forEach(alertSubGroupId => {
                                        const subGroupData = groupData.subGroup.find(subGroup => subGroup._id === alertSubGroupId)
                                        if (subGroupData) {
                                            vehicles.push(...subGroupData.vehicles)
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
            }
        })

        return [...new Set(vehicles)].length
    }

    return (
        <div
            className={cx(styles.card, {[styles.cardSelected]: isSelected})}
            onClick={() => onCardClick(fenceDetails)}
        >
            <h4 className={styles.fenceName}>{fenceDetails.fence_name}</h4>
            
            <div className={styles.fenceTypeWrapper}>
                <img
                    className={styles.fenceTypeIcon}
                    src={fenceTypeIconMap[fence_identifiers.fence_type]}
                    alt='fence type icon'
                />

                <p className={styles.fenceTypeLabel}>{fence_identifiers.fence_type_label}</p>
            </div>

            <div className={styles.fenceInfoStack}>
                <table className={styles.fenceInfoTable}>
                   <tbody>
                        <tr>
                            <td>Fence Owner Type</td>
                            <td>:</td>
                            <td>{fence_identifiers.owner_type_label}</td>
                        </tr>
                        <tr>
                            <td>Fence Owner Name</td>
                            <td>:</td>
                            <td>{fence_identifiers.owner_name_label}</td>
                        </tr>
                        {fenceDetails.hasAlertConfigured && fenceDetails.hasVehicleMapped && (
                            <tr>
                                <td>Vehicle Count</td>
                                <td>:</td>
                                <td>{calculateVehicleCount()}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <img
                    className={styles.expandIcon}
                    src="/images/geofenceModuleIcon/expandArrow.svg"
                    alt="info icon"
                />
            </div>

            <div className={styles.cardFooter}>
                <div className={cx(styles.statusWrapper, { [styles.configured]: fenceDetails.hasAlertConfigured })}>
                    <button disabled={!fenceDetails.hasAlertConfigured} className={styles.alertButton}>Alert</button>
                    <span>{fenceDetails.hasAlertConfigured ? 'Configured' : 'Not Configured'}</span>
                </div>
                
                {fenceDetails.canUpdate && fenceDetails.hasAlertConfigured && !fenceDetails.hasVehicleMapped && (
                    <button onClick={() => handleEditClick('ASSIGN_VEHICLE')} className={styles.actionButton}>Assign Vehicle</button>
                )}

                {fenceDetails.canUpdate && !fenceDetails.hasAlertConfigured && !fenceDetails.hasVehicleMapped && (
                    <button onClick={() => handleEditClick('CONFIGURE_ALERT')} className={styles.actionButton}>Configure Alert</button>
                )}
            </div>
        </div>
    )
}

export default GFDetailsCard;