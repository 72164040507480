import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import MarkerClusterGroup from 'react-leaflet-cluster'
import { MapContainer, Marker, TileLayer, Tooltip, FeatureGroup, Circle, Rectangle, Polygon, useMap } from 'react-leaflet'
import { DivIcon, Icon } from 'leaflet'

import styles from './GFMap.module.css'

const markerClusterConfig = {
    chunkedLoading: true,
    disableClusteringAtZoom: 9,
    maxClusterRadius: 20,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
}

const markerIcons = {
    CHARGING_DEPOT: new Icon({
        iconUrl: '/images/svgicon/GeofenceTabicon/ChargingDepot.svg',
        iconRetinaUrl: '/images/svgicon/GeofenceTabicon/ChargingDepot.svg',
        iconSize: [28, 28]
    }),

    CLIENT_LOCATION: new Icon({
        iconUrl: '/images/svgicon/GeofenceTabicon/ClientHub.svg',
        iconRetinaUrl: '/images/svgicon/GeofenceTabicon/ClientHub.svg',
        iconSize: [28, 28]
    }),

    RESTRICTED_AREA: new Icon({
        iconUrl: '/images/svgicon/GeofenceTabicon/RestrictedArea.svg',
        iconRetinaUrl: '/images/svgicon/GeofenceTabicon/RestrictedArea.svg',
        iconSize: [28, 28]
    }),

    OFFICE: new Icon({
        iconUrl: '/images/svgicon/GeofenceTabicon/office.svg',
        iconRetinaUrl: '/images/svgicon/GeofenceTabicon/office.svg',
        iconSize: [28, 28]
    }),

    SERVICE_CENTER: new Icon({
        iconUrl: '/images/svgicon/GeofenceTabicon/ServiceCenter.svg',
        iconRetinaUrl: '/images/svgicon/GeofenceTabicon/ServiceCenter.svg',
        iconSize: [28, 28]
    }),

    CITY_BOUNDARY: new Icon({
        iconUrl: '/images/svgicon/GeofenceTabicon/CityBoundary.svg',
        iconRetinaUrl: '/images/svgicon/GeofenceTabicon/CityBoundary.svg',
        iconSize: [28, 28]
    }),
}

const createClusterCustomIcon = function (cluster) {
    return new DivIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
        className: styles.markerClusterGroup,
        iconSize: [40, 40],
    })
}

const RenderMarkers = (props) => {
    const { children, setMapConfig, mapConfig, clusterConfig } = props
    const map = useMap()

    return (
        <MarkerClusterGroup
            onClick={(e) => {
                setTimeout(() => {
                    map.setView([e.latlng.lat, e.latlng.lng], clusterConfig.disableClusteringAtZoom)
                    setMapConfig({ ...mapConfig, zoom: clusterConfig.disableClusteringAtZoom, center: [e.latlng.lat, e.latlng.lng] })
                }, 300)
            }}
            chunkedLoading={clusterConfig.chunkedLoading}
            disableClusteringAtZoom={clusterConfig.disableClusteringAtZoom}
            maxClusterRadius={clusterConfig.maxClusterRadius}
            iconCreateFunction={createClusterCustomIcon}
            {...clusterConfig}
        >
            {children}
        </MarkerClusterGroup>
    )
}

const GeoShapes = (props) => {
    const { data } = props

    if (data.fence_geometry.type === 'circle') {
        return (
            <>
                <Circle
                    center={data.fence_geometry.coordinates[0].center}
                    radius={data.fence_geometry.coordinates[0].radius}
                >
                    <Tooltip>{data.fence_name}</Tooltip>
                </Circle>
            </>
        )
    }

    if (data.fence_geometry.type === 'rectangle') {
        return (
            <>
                <Rectangle
                    bounds={data.fence_geometry.coordinates}
                    radius={data?.fence_geometry?.area?.value}
                >
                    <Tooltip>{data.fence_name}</Tooltip>
                </Rectangle>
            </>
        )
    }

    if (data.fence_geometry.type === 'polygon') {
        return (
            <>
                <Polygon
                    positions={data.fence_geometry.coordinates}
                    radius={data?.fence_geometry?.area?.value}
                >
                    <Tooltip>{data.fence_name}</Tooltip>
                </Polygon>
            </>
        )
    }

    return <></>
}

const ZoomHandler = (props) => {
    const { setMapConfig, mapConfig } = props
    const map = useMap()

    useEffect(() => {
        map.on('zoomend', () => {
            const zoom = map.getZoom()
            setMapConfig({ ...mapConfig, zoom })
        })
        return () => {
            map.off('zoomend')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}

const GFMap = (props) => {
    const { mapConfig, setMapConfig, mapRef, fenceData, onZoomClick, onResetClick } = props

    const componentRef = useRef(null)

    const [refreshCount, setRefreshCount] = useState(0)
    const [isFullscreen, setIsFullscreen] = useState(false)

    useEffect(() => {
        if (document.addEventListener) {
            document.addEventListener("webkitfullscreenchange", exitFullScreenHandler, false)
            document.addEventListener("mozfullscreenchange", exitFullScreenHandler, false)
            document.addEventListener("fullscreenchange", exitFullScreenHandler, false)
            document.addEventListener("MSFullscreenChange", exitFullScreenHandler, false)
        }
        return () => {
            if (document.removeEventListener) {
                document.removeEventListener("webkitfullscreenchange", exitFullScreenHandler, false)
                document.removeEventListener("mozfullscreenchange", exitFullScreenHandler, false)
                document.removeEventListener("fullscreenchange", exitFullScreenHandler, false)
                document.removeEventListener("MSFullscreenChange", exitFullScreenHandler, false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setRefreshCount(refreshCount + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fenceData])

    const exitFullScreenHandler = useCallback(() => {
        if (
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement
        ) {
            setIsFullscreen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsFullscreen, document])

    const handleControlClick = useCallback((control) => {
        if (control === 'ZOOM_IN') {
            if (mapConfig.zoom !== mapConfig.maxZoom) {
                onZoomClick(control);
            }
        }

        if (control === 'ZOOM_OUT') {
            if (mapConfig.zoom !== mapConfig.minZoom) {
                onZoomClick(control);
            }
        }

        if (control === 'RESET') {
            onResetClick()
            if (isFullscreen) {
                if (document.exitFullscreen) {
                    document?.exitFullscreen();
                } else if (document?.mozExitFullscreen) {
                    document?.mozExitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }

        if (control === 'FULLSCREEN') {
            if (!isFullscreen) {
                if (componentRef?.current?.requestFullscreen) {
                    componentRef?.current?.requestFullscreen();
                } else if (componentRef?.current?.mozRequestFullScreen) {
                    componentRef?.current?.mozRequestFullScreen();
                } else if (componentRef?.current?.webkitRequestFullscreen) {
                    componentRef?.current?.webkitRequestFullscreen();
                } else if (componentRef?.current?.msRequestFullscreen) {
                    componentRef?.current?.msRequestFullscreen();
                }

                setIsFullscreen(true)
            } else {
                if (document.exitFullscreen) {
                    document?.exitFullscreen();
                } else if (document?.mozExitFullscreen) {
                    document?.mozExitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullscreen, mapConfig, componentRef, document])

    return (
        <div ref={componentRef} className={styles.gf__map}>
            <MapContainer
                style={{ height: '100%', width: '100%' }}
                attributionControl={mapConfig.attributionControl}
                maxZoom={mapConfig.maxZoom}
                minZoom={mapConfig.minZoom}
                maxNativeZoom={mapConfig.maxZoom}
                zoomControl={mapConfig.zoomControl}
                ref={mapRef}
                {...mapConfig}
            >
                <ZoomHandler mapConfig={mapConfig} setMapConfig={setMapConfig} />

                <TileLayer
                    attribution='&copy; <a href="http://openstreetmap.org/">OpenStreetMap</a> contributors'
                    url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    maxZoom={mapConfig.maxZoom}
                    maxNativeZoom={mapConfig.maxZoom}
                />

                <RenderMarkers clusterConfig={markerClusterConfig} mapConfig={mapConfig} setMapConfig={setMapConfig}>
                    {fenceData.map((fence, fenceI) => (
                        <Marker
                            position={fence.center_coordinates}
                            icon={markerIcons[fence.fence_identifiers.fence_type]}
                            key={`fence_marker_${fenceI}`}
                            eventHandlers={{
                                'click': (e) => {
                                    mapRef.current.setView([e.latlng.lat, e.latlng.lng], markerClusterConfig.disableClusteringAtZoom)
                                    setMapConfig({ ...mapConfig, center: [e.latlng.lat, e.latlng.lng], zoom: markerClusterConfig.disableClusteringAtZoom })
                                }
                            }}
                        />
                    ))}
                </RenderMarkers>

                {fenceData.length && mapConfig.zoom >= markerClusterConfig.disableClusteringAtZoom && (
                    <FeatureGroup>
                        {fenceData.map((fence, index) => (
                            <GeoShapes key={`fence_${index}`} data={fence} />
                        ))}
                    </FeatureGroup>
                )}

                <div className={styles.mapControls}>
                    <div className={styles.controlsGroup}>
                        <button
                            onClick={() => handleControlClick('RESET')}
                            title='Reset'
                        >
                            <img src='/images/svgicon/map-reset.svg' alt='reset icon' />
                        </button>
                    </div>

                    <div className={styles.controlsGroup}>
                        <button
                            style={{ cursor: mapConfig.zoom !== mapConfig.maxZoom ? 'pointer' : 'not-allowed' }}
                            onClick={() => handleControlClick('ZOOM_IN')}
                            title='Zoom In'
                        >
                            <img src='/images/svgicon/map_zoom_in.svg' alt='zoom in icon' />
                        </button>
                        <button
                            style={{ cursor: mapConfig.zoom !== mapConfig.minZoom ? 'pointer' : 'not-allowed' }}
                            onClick={() => handleControlClick('ZOOM_OUT')}
                            title='Zoom Out'
                        >
                            <img src='/images/svgicon/map_zoom_out.svg' alt='zoom out icon' />
                        </button>
                    </div>

                    <div className={styles.controlsGroup}>
                        <button
                            onClick={() => handleControlClick('FULLSCREEN')}
                            title='Full Screen'
                        >
                            <img
                                src={isFullscreen ? '/images/svgicon/full-screen-exit.svg' : '/images/svgicon/map-fullscreen.svg'}
                                alt='fullscreen icon'
                            />
                        </button>
                    </div>
                </div>
            </MapContainer>
        </div>
    )
}

GFMap.propTypes = {
    mapConfig: PropTypes.shape({
        attributionControl: PropTypes.bool,
        maxZoom: PropTypes.number,
        minZoom: PropTypes.number,
        maxNativeZoom: PropTypes.number,
        zoomControl: PropTypes.bool,
        enableMarkerCluster: PropTypes.bool,
    }).isRequired,
    mapRef: PropTypes.shape({
        current: PropTypes.object
    }).isRequired
}

export default GFMap