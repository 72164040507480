import React from "react";
import { Row, Col } from "react-bootstrap";
import Filter from "./Filter";
import ChartViewFilters from "./ChartViewFilters";
import OperationsTrendChart from "./OperationsTrendChart";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIngressEgressSessions,
  fetchOperationsData,
} from "../../../../store/actions/geo-fence/analytics/analyticsActions";

const Trend = (props) => {
  const dispatch = useDispatch();

  const selectedCities = useSelector((s) => s?.analytics?.selectedCities);
  const selectedFenceTypes = useSelector((s) => s?.analytics?.selectedLabels);
  const selectedFenceOwnerTypes = useSelector(
    (s) => s?.analytics?.selectedLabelOwners
  );
  const selectedFenceOwnerNames = useSelector(
    (s) => s?.analytics?.selectedFenceOwners
  );
  const selectedDataInterval = useSelector(
    (s) => s?.analytics.selectedDataInterval
  );
  const ingressSelected = useSelector((s) => s?.analytics.ingressSelected);
  const egressSelected = useSelector((s) => s?.analytics.egressSelected);
  const startDate = useSelector((s) => s?.analytics?.startDate);
  const endDate = useSelector((s) => s?.analytics?.endDate);
  const reportCurrentPage = useSelector((s) => s?.analytics.reportCurrentPage);
  const reportPageSize = useSelector((s) => s?.analytics.reportPageSize);
  const operationsTrendData = useSelector(
    (s) => s?.analytics.operationsTrendData
  );
//get all data of geofence analytics
  const getData = () => {
    if(startDate !== null && endDate !== null){
      dispatch(
        fetchOperationsData({
          selectedCities,
          selectedFenceTypes,
          selectedFenceOwnerTypes,
          selectedFenceOwnerNames,
          selectedDataInterval,
          ingressSelected,
          egressSelected,
          startDate,
          endDate,
        })
      );
  
      dispatch(
        fetchIngressEgressSessions({
          selectedCities,
          selectedFenceTypes,
          selectedFenceOwnerTypes,
          selectedFenceOwnerNames,
          startDate,
          endDate,
          reportCurrentPage,
          reportPageSize,
        })
      );
    }
    
  };

  return (
    <>
      <div className="trend-main-text mb-4">Trend</div>
      <Row className="trend-row">
        <Col className="">
          <Row>
            <Col>
              <Filter masters={props.masters} data-testid='trend-filters'></Filter>
            </Col>
          </Row>

          <Row>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              left: 100,
              width: "20vw",
              justifyContent: "space-around",
              marginTop:25
            }}
          >
            <span
              style={{
                backgroundColor: "#1F2A5D",
                height: 20,
                width: 35,
                borderRadius: 5,
                display: "inline-block",
              }}
            ></span>
            <span className="text-color-analytics">Ingress</span>
            <span
              style={{
                backgroundColor: "#D17FAE",
                height: 20,
                width: 35,
                borderRadius: 5,
                display: "inline-block",
              }}
            ></span>
            <span className="text-color-analytics" >Egress</span>
          </div>
            {operationsTrendData?.length ? (
              <Col xs="10">
                <OperationsTrendChart ></OperationsTrendChart>
              </Col>
            ) : (
              <></>
            )}
            {!operationsTrendData?.length ? (
              <Col xs="10"
              className="no-data-map-analytics"
              >  
                <Col xs="9" className="custom-map-hr">
                <div className="no-data-analytics"> No Data</div>
                     <hr className="hr-line-no-data"></hr>
                     <div className="vertical-line-no-data"></div>
                     <div className="custom-map-time">Time</div>
                     <div className="custom-map-count">Count</div>                
                </Col>          
              </Col>
            ) : (
              <></>
            )}
            <Col xs="2" style={{ height: "444px" }}>
              <ChartViewFilters onFiltersChanged={getData} data-testid='trend-chartfilters'></ChartViewFilters>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Trend;
