import React from "react";
import styles from "./GFAddAlertModel.module.css";

const GFAddVehicleModel = ({
  onClose,
  selectedAlerts,
  selectedAssignVehicle,
  handleSelectVehicleChange,
  handleNextClick,
  handleAlertSelect,
}) => {
  
  return (
    <div className={styles.addAlertModel}>
      <div className={styles.addVehicleContent}>
        <div className={styles.alertModelclose}>
          <img
            className={`${styles.AddAlertcloseBtn} ${styles.ModelButton}`}
            src="/images/geofenceModuleIcon/closeBtn.svg"
            alt="close icon"
            onClick={onClose}
          />
        </div>
        <div className={styles.assignVehiclecontainer}>
          {selectedAlerts.length > 0 ? (
            <ul className={styles.assignVehicleList}>
              {selectedAlerts.map((alert, index) => (
                <div key={index}>
                  {alert.isSelected && (
                    <li className={styles.assignVehicleChip}>
                      {`${alert?.selectedIngressData.label}: `}

                 
                      {alert.time
                        ? `${alert.time} Hrs`
                        : `${alert.timeStart} to ${alert.timeEnd}`}

                      <img
                        src="/images/geofenceModuleIcon/closeMark.svg"
                        alt="close"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAlertSelect(alert.index)}
                      />
                    </li>
                  )}
                </div>
              ))}
            </ul>
          ) : (
            <p>No alerts selected.</p>
          )}
        </div>

        <div className={styles.assignVehicleBox}>
          <p>Assign Vehicles</p>
          <div className={styles.assignVehicleSelect}>
            <div className={styles.assignVehicleRadio}>
              <input
                type="radio"
                value="groups"
                checked={selectedAssignVehicle === "groups"}
                onChange={handleSelectVehicleChange}
                className={styles.selectGroupRadio}
              />
              <span>Select Groups</span>
            </div>
            <div className={styles.assignVehicleRadio}>
              <input
                type="radio"
                value="manual"
                checked={selectedAssignVehicle === "manual"}
                onChange={handleSelectVehicleChange}
                className={styles.selectGroupRadio}
              />
              <span>Select Manually</span>
            </div>
          </div>
        </div>

        <button
          className={styles.assignVehicleSaveButton}
          onClick={handleNextClick}
          disabled={!selectedAssignVehicle}
          style={{
            backgroundColor: selectedAssignVehicle ? "#22046B" : "#9E9E9E",
            cursor: selectedAssignVehicle ? "pointer" : "not-allowed",
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default GFAddVehicleModel;
