import ActionTypes from '../../actionTypes'
const nonCommLogsActionTypes = ActionTypes.REPORTS.NON_COMM_LOGS
const nonCommAnalyticsActionTpes = ActionTypes.REPORTS.NON_COMM_ANALYTICS_DAY_WISE
const nonCommAnalyticsSourceActionTpes = ActionTypes.REPORTS.NON_COMM_ANALYTICS_SOURCE_WISE;

const initialState = {
  allNonCommLogsData: [],
  allNonCommReportData: [],
  allNonCommAnalyticsDayWiseData:[],
  allNonCommAnalyticsSourceWiseData:[],

  logsDataCount: 0,
  reportDataCount: 0,
}

const nonCommLogsReducer = (state = initialState, action) => {
  const { type, payload } = action
  
  switch (type) {
    case nonCommLogsActionTypes.GET_ALL_NON_COMM_LOGS_SUCCESS:
      return {
        ...state,
        allNonCommLogsData: payload.rows,
        logsDataCount: payload.count,
      }
    case nonCommLogsActionTypes.GET_ALL_NON_COMM_REPORT_SUCCESS:
      return {
        ...state,
        allNonCommReportData: payload.rows,
        reportDataCount: payload.count,
      }
      case nonCommAnalyticsActionTpes.GET_ALL_NON_COMM_ANALYTICS_DAY_WISE_SUCCEESS:
        return {
          ...state,
          allNonCommAnalyticsDayWiseData: payload,
          // logsDataCount: payload.count,
        }
        case nonCommAnalyticsSourceActionTpes.GET_ALL_NON_COMM_ANALYTICS_SOURCE_WISE_SUCCEESS:
          return {
            ...state,
            allNonCommAnalyticsSourceWiseData: payload,
            // logsDataCount: payload.count,
          }

          
    default:
      return state
  }
}

export default nonCommLogsReducer
