import { useEffect, useState } from 'react'
import styles from './GFEditAlertVehicles.module.css'
import GFEditVehicles from './GFEditVehicles'
import GFEditGroups from './GFEditGroups'

const GFEditAlertVehicles = (props) => {
    const { alertDetails, setAlertDetails, mappedOptionsByVehicle, groupList, selectedAssignVehicle, handleSelectVehicleChange, handleNextClick, handleSaveClick } = props
    const [activeView, setActiveView] = useState('')

    useEffect(() => {
        if (alertDetails?.groups && alertDetails?.groups.length) {
            setActiveView('GROUPS')
        } else if (alertDetails?.vehicle && alertDetails?.vehicle.length) {
            setActiveView('VEHICLES')
        } else {
            setActiveView('')
        }
    }, [alertDetails])

    if (activeView === 'VEHICLES') {
        const vehicles = mappedOptionsByVehicle.registrationNumber.filter(v => alertDetails.vehicle.includes(v.label))
        const oemModel = mappedOptionsByVehicle.oemModel
        return (
            <>
                <GFEditVehicles setAlertDetails={setAlertDetails} vehicles={vehicles} oemModel={oemModel} handleNextClick={handleNextClick} />
                <div className={styles.modalFooter}>
                    <button className={styles.modalSaveButton} onClick={handleSaveClick}>Save</button>
                </div>
            </>
        )
    }

    if (activeView === 'GROUPS') {
        const groups = []
        alertDetails.groups.forEach(group => {
            const groupData = groupList.find(gl => gl._id === group.groupId)
            if (group.subGroup.length) {
                group.subGroup.forEach(subGroup => {
                    const subGroupData = groupData.subGroup.find(sgl => sgl._id === subGroup)
                    groups.push({
                        id: subGroup,
                        type: 'subgroup',
                        parentId: groupData._id,
                        name: subGroupData.name,
                    })
                })
            } else {
                groups.push({
                    id: groupData._id,
                    type: 'group',
                    parentId: null,
                    name: groupData.name,
                })
            }
        })
        return (
            <>
                <GFEditGroups setAlertDetails={setAlertDetails} groups={groups} handleNextClick={handleNextClick} />
                <div className={styles.modalFooter}>
                    <button className={styles.modalSaveButton} onClick={handleSaveClick}>Save</button>
                </div>
            </>
        )
    }

    return (
        <div>
            <div className={styles.assignVehicleBox}>
                <p>Assign Vehicles</p>
                <div className={styles.assignVehicleSelect}>
                    <div className={styles.assignVehicleRadio}>
                        <input
                            type="radio"
                            value="groups"
                            checked={selectedAssignVehicle === "groups"}
                            onChange={handleSelectVehicleChange}
                            className={styles.selectGroupRadio}
                        />
                        <span>Select Groups</span>
                    </div>
                    <div className={styles.assignVehicleRadio}>
                        <input
                            type="radio"
                            value="manual"
                            checked={selectedAssignVehicle === "manual"}
                            onChange={handleSelectVehicleChange}
                            className={styles.selectGroupRadio}
                        />
                        <span>Select Manually</span>
                    </div>
                </div>
            </div>

            <div className={styles.editButtonRow} style={{ justifyContent: 'flex-start' }}>
                <button
                    className={styles.actionButton}
                    onClick={() => handleNextClick('refresh', alertDetails)}
                    disabled={!selectedAssignVehicle}
                    style={{
                        backgroundColor: selectedAssignVehicle ? "#22046B" : "#9E9E9E",
                        cursor: selectedAssignVehicle ? "pointer" : "not-allowed",
                    }}
                >
                    Next
                </button>
            </div>

        </div>
    )
}

export default GFEditAlertVehicles;