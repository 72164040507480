import React, { Component, createRef } from "react";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { divIcon } from "leaflet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import { fileDownloader , filterVehicleDataBasedOnVehicleCategory , filterVehicleDataBasedOnDataSource , filterVehicleDataBasedOnSoc, filterVehicleDataBasedOnLocation , filterVehicleDataBasedOnOemAndModel , getVehicleNosByGroupNames } from "../../../../utils/common-methods";
import _ from "lodash";
import moment from "moment";
import dashboardAction from "../../../../store/actions/dashboard/dashboard";
import "leaflet/dist/leaflet.css";
import "./NdMap_Dark.css";
import SidebarFilter from  "./SidebarFilter";
import { closeModal, openModal, } from "../../../../store/actions/notificationPanel";
import { VehicleOemAndModels } from "../../../../utils/constants";
import { Close } from "@rsuite/icons";
import styles from './SidebarFilter.module.css'


// Note: Use below object to set location wise cluster config

const locationWiseClusterGroupConfig = {
  "Delhi NCR": {
    maxClusterRadius: 25,
    disableClusteringAtZoom: 9,
  },
  Ahmedabad: {
    maxClusterRadius: 25,
    disableClusteringAtZoom: 9,
  },
  Chennai: {
    maxClusterRadius: 25,
    disableClusteringAtZoom: 9,
  },
};

const keyFilterNames = {
  soc: 'SoC',
  location: 'Location',
  oemModel: 'OEM & Model',
  dataSource: 'Data Source',
  vehicleCategory: 'Vehicle Category',
  groupName: 'Groups'
};

const defaultSocData = [
  {
    value: "range1",
    label: "0% - 25%",
    count: "0",
    color: "#fff",
    valueColor: "#33E8FF",
    fontWeight: "700",
    bgColor: "linear-gradient(45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24))",
  },
  {
    value: "range2",
    label: "25% - 50%",
    count: "0",
    color: "#fff",
    valueColor: "#33E8FF",
    fontWeight: "700",
    bgColor: "linear-gradient(45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24))",
  },
  {
    value: "range3",
    label: "50% - 75%",
    count: "0",
    color: "#fff",
    valueColor: "#33E8FF",
    fontWeight: "700",
    bgColor: "linear-gradient(45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24))",
  },
  {
    value: "range4",
    label: "75% - 100%",
    count: "0",
    color: "#fff",
    valueColor: "#33E8FF",
    fontWeight: "700",
    bgColor: "linear-gradient(45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24))",
  },
];

const RenderMarkers = ({
  enableMarkerCluster,
  children,
  clusterConfig = {},
  mapRef,
  onClusterClick,
}) => {
  if (enableMarkerCluster) {
    return (
      <MarkerClusterGroup
        onClick={(e) =>
          onClusterClick
            ? onClusterClick(e, clusterConfig.disableClusteringAtZoom)
            : mapRef?.current?.setZoom(11)
        }
        chunkedLoading={clusterConfig.chunkedLoading}
        disableClusteringAtZoom={clusterConfig.disableClusteringAtZoom}
        maxClusterRadius={clusterConfig.maxClusterRadius}
        {...clusterConfig}
      >
        {children}
      </MarkerClusterGroup>
    );
  }
  return children;
};


class NdMap_Dark extends Component {
  constructor(props) {
    super(props);
    this.componentRef = createRef(null);
    this.state = {
      isFullScreen: false,
      socFilterName: "",
      selectedSocRange: props.filterVehicleDataObj.soc,
      showSOCTable: 0,
      socData: defaultSocData,
      locationData: [],
      oemModalCountData: [],
      dataSourceData: [
        { value: "TFT100", count: 0 },
        { value: "OEM API", count: 0 },
      ],
      vehicleCategoryData: [
        { value: "Four Wheeler", count: 0 },
        { value: "Three Wheeler", count: 0 },
        { value: "Two Wheeler", count: 0 },
      ],
      openFilterMenu:"location",
      filterPriority :[],
      filteredVehicleData:{}
    };
  }



  setStateCallback = (state) => {
    this.setState(state);
  };

  componentDidMount() {
    if (document.addEventListener) {
      document.addEventListener("webkitfullscreenchange",() => {
          this.exitFullScreenHandler(this.setStateCallback, {
            isFullScreen: false,
          });
        },
        false
      );
      document.addEventListener( "mozfullscreenchange", () => {
          this.exitFullScreenHandler(this.setStateCallback, {
            isFullScreen: false,
          });
        },
        false
      );
      document.addEventListener( "fullscreenchange", () => {
          this.exitFullScreenHandler(this.setStateCallback, {
            isFullScreen: false,
          });
        },
        false
      );
      document.addEventListener( "MSFullscreenChange", () => {
          this.exitFullScreenHandler(this.setStateCallback, {
            isFullScreen: false,
          });
        },
        false
      );
      this.setState({
        oemModalCountData: VehicleOemAndModels.map((data) => ({
          value: data.label,
          count: 0,
        })),
      });
    
    }

    if (
      this.props.masterLocationData &&
      this.props.masterLocationData.length > 0
    ) {
      this.setState(
        {
          locationData: this.props.masterLocationData.map((location) => ({
            ...location,
            count: 0,
          })),
        },
        () => {
          this.handleFilterClick('location');
        }
      );
    }
    
  }

  exitFullScreenHandler(fn, state) {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      fn(state);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.filterVehicleDataObj.topPanelQuery !==
      this.props.filterVehicleDataObj.topPanelQuery
    ) {
      this.setState({
        socFilterName: 0,
        selectedSocRange: this.props.filterVehicleDataObj.soc,
      });
    }
    if (prevProps.masterLocationData !== this.props.masterLocationData) {
      if (
        this.props.masterLocationData &&
        this.props.masterLocationData.length > 0
      ) {
        this.setState({
          locationData: this.props.masterLocationData.map((location) => ({
            ...location,
            count: 0,
          })),
        });
      }
    }

     // Update filterPriority based on filter changes
    const { filters } = this.props;
  
    // Check if a filter has changed
    const filterChanged = (filterKey) => {
      return JSON.stringify(prevProps?.filters[filterKey]) !== JSON.stringify(filters[filterKey]);
    };
  
    // Function to update filterPriority
    const updateFilterPriority = (filterKey) => {
      // Check if the filter is active (i.e., has non-empty values)
      const isActive = filters[filterKey]?.length > 0;
  
      this.setState((prevState) => {
        let updatedPriority = [...prevState.filterPriority];
  
        // If the filter is active and not already in the priority list, add it
        if (isActive && !updatedPriority.includes(filterKey)) {
          updatedPriority?.push(filterKey);
        }
  
        // If the filter is inactive (unselected) and is in the priority list, remove it
        if (!isActive && updatedPriority.includes(filterKey)) {
          updatedPriority = updatedPriority?.filter((key) => key !== filterKey);
        }
  
        return { filterPriority: updatedPriority };
      });
    };
  
    // Check each filter and update filterPriority accordingly
    if (filterChanged('location')) {
      updateFilterPriority('location');
    }
    if (filterChanged('vehicleCategory')) {
      updateFilterPriority('vehicleCategory');
    }
    if (filterChanged('oemModel')) {
      updateFilterPriority('oemModel');
    }
    if (filterChanged('dataSource')) {
      updateFilterPriority('dataSource');
    }
    if (filterChanged('groups')) {
      updateFilterPriority('groups');
    }
    if (filterChanged('soc')) {
      updateFilterPriority('soc');
    }
  
  }
  

  handleSOCSidebarToogle = () => {
    this.props.closeModalAction();
  };

  handleFilterClick = (name) => {
      this.setState({ openFilterMenu: name });
    
      const { filters } = this.props;
      let filteredVehicleData = [...this.props.allDashboardVehicleData];
     
      // Apply Location filter
      if (filters.location?.length > 0) {
        filteredVehicleData = filters.location.reduce((acc, loc) => {
          return acc.concat(filterVehicleDataBasedOnLocation(filteredVehicleData, loc));
        }, []);
      }

        // Apply Vehicle Category filter
        if (filters.vehicleCategory?.length > 0) {
          filteredVehicleData = filters.vehicleCategory.reduce((acc, vehicleCategory) => {
            return acc.concat(filterVehicleDataBasedOnVehicleCategory(vehicleCategory,filteredVehicleData));
          }, []);
        }
    
      // Apply OEM Model filter
      if (filters.oemModel?.length > 0) {
        filteredVehicleData = filters.oemModel.reduce((acc, oemModel) => {
          return acc.concat(filterVehicleDataBasedOnOemAndModel(oemModel,filteredVehicleData));
        }, []);
      }
    
      // Apply DataSource filter
      if (filters.dataSource?.length > 0) {
        filteredVehicleData = filters.dataSource.reduce((acc, dataSource) => {
          return acc.concat(filterVehicleDataBasedOnDataSource(dataSource,filteredVehicleData));
        }, []);
      }
    
    // Apply Groups filter
    if (filters.groups?.length > 0) {
      filteredVehicleData = this.filterVehicleDataBasedOnGroups(filters.groups,filteredVehicleData);
    }

      // Apply SOC filter
      if (filters.soc?.length > 0) {
        filteredVehicleData = filterVehicleDataBasedOnSoc(filters.soc,filteredVehicleData);
      }

      this.setState({filteredVehicleData:filteredVehicleData})
     
    this.updateFilterCounts(filteredVehicleData);

  };

  filterVehicleDataBasedOnGroups = (groupNames,allDashboardVehicleData) => {
  
    let vehicles =  getVehicleNosByGroupNames(groupNames,this.props.groupsListData);
      const filteredVehicleData = allDashboardVehicleData?.filter(vehicle => 
        vehicles?.includes(vehicle.rn)
      );
      return filteredVehicleData;
  };
  
  updateFilterCounts = (filteredVehicleData) => {
      const { filterPriority } = this.state;
     // Check and update SOC count if not in filterPriority
     if (!filterPriority?.includes('soc')) {
     

     this.setSOCDataCount(filteredVehicleData);
      }
    
    // Check and update Location count if not in filterPriority
    if (!filterPriority?.includes('location')) {
      
     this.setLocationDataCount(filteredVehicleData);
     }
    
     // Check and update OEM Model count if not in filterPriority
     if (!filterPriority?.includes('oemModel')) {
       this.setOemModelDataCount(filteredVehicleData);
    }
    
     // Check and update DataSource count if not in filterPriority
    if (!filterPriority?.includes('dataSource')) {
    this.setSourceDataCount(filteredVehicleData);
     }
    
     // Check and update Vehicle Category count if not in filterPriority
    if (!filterPriority?.includes('vehicleCategory')) {
    this.setVechileCategoryDataCount(filteredVehicleData);
    }

    };
    
    
  setSOCDataCount = (filteredVehicleData) => {
    const socData = [...this.state.socData];
    socData[0].count = filteredVehicleData?.filter(fd => fd.canStatus.soc <= 25).length;
    socData[1].count = filteredVehicleData?.filter(fd => fd.canStatus.soc <= 50 && fd.canStatus.soc > 25).length;
    socData[2].count = filteredVehicleData?.filter(fd => fd.canStatus.soc <= 75 && fd.canStatus.soc > 50).length;
    socData[3].count = filteredVehicleData?.filter(fd => fd.canStatus.soc <= 100 && fd.canStatus.soc > 75).length;
    this.setState({ socData });
  };
  
  setLocationDataCount = (filteredVehicleData) => {
    let masterLocationData = this.state.locationData.map(location => ({
      ...location,
      count: 0,
    }));
  
    filteredVehicleData?.forEach(vehicle => {
      const index = masterLocationData.findIndex(location => location.value === vehicle.loc);
      if (index !== -1) {
        masterLocationData[index].count += 1;
      }
    });
  
    this.setState({ locationData: masterLocationData });
  };
  
  setOemModelDataCount = (filteredVehicleData) => {
    let masterOemModelData = this.state.oemModalCountData.map(oemModel => ({
      ...oemModel,
      count: 0,
    }));
  
    filteredVehicleData?.forEach(vehicle => {
      let oemModal = `${vehicle.canStatus.oem} ${vehicle.canStatus.mdl}`;
      const index = masterOemModelData.findIndex(oem => oem.value === oemModal);
      if (index !== -1) {
        masterOemModelData[index].count += 1;
      }
    });
  
    this.setState({ oemModalCountData: masterOemModelData });
  };
  
  setSourceDataCount = (filteredVehicleData) => {
    let masterSourceData = [...this.state.dataSourceData];
  
    const tft100Count = filteredVehicleData?.filter(vehicle => vehicle.canStatus.src === "TFT100").length;
    const oemApiCount = filteredVehicleData?.length - tft100Count;
  
    masterSourceData = masterSourceData.map(item => {
      if (item.value === "TFT100") {
        return { ...item, count: tft100Count };
      } else if (item.value === "OEM API") {
        return { ...item, count: oemApiCount };
      }
      return item;
    });
  
    this.setState({ dataSourceData: masterSourceData });
  };
  
  setVechileCategoryDataCount = (filteredVehicleData) => {
    let masterVechileCategoryData = this.state.vehicleCategoryData.map(category => ({
      ...category,
      count: 0,
    }));
  
    const fourWheelCount = filteredVehicleData?.filter(vehicle => vehicle.type === "4 Wheeler").length;
    const threeWheelCount = filteredVehicleData?.filter(vehicle => vehicle.type === "3 Wheeler").length;
    const twoWheelCount = filteredVehicleData?.filter(vehicle => vehicle.type === "2 Wheeler").length;
  
    masterVechileCategoryData = masterVechileCategoryData.map(item => {
      if (item.value === "Four Wheeler") {
        return { ...item, count: fourWheelCount };
      } else if (item.value === "Three Wheeler") {
        return { ...item, count: threeWheelCount };
      } else if (item.value === "Two Wheeler") {
        return { ...item, count: twoWheelCount };
      }
      return item;
    });
  
    this.setState({ vehicleCategoryData: masterVechileCategoryData });
  };
  
 

  
  openMapFullScreen = (ref) => {
    if (!this.state.isFullScreen) {
      const elem = ref?.current;
      if (elem?.requestFullscreen) {
        elem?.requestFullscreen();
      } else if (elem?.mozRequestFullScreen) {
        elem?.mozRequestFullScreen();
      } else if (elem?.webkitRequestFullscreen) {
        elem?.webkitRequestFullscreen();
      } else if (elem?.msRequestFullscreen) {
        elem?.msRequestFullscreen();
      }
      this.setState({ isFullScreen: true });
    } else {
      if (document.exitFullscreen) {
        document?.exitFullscreen();
      } else if (document?.mozExitFullscreen) {
        document?.mozExitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };


   // Toggle sidebar function
   toggleSidebar = () => {
    this.props.toggleSidebar();
    this.handleFilterClick('location');
   };

    // Function to clear all filters
    handleClearAll =  async ()  => {
     

      this.props.handleClearAllFilters();

     await this.setState({filterPriority:[]});
     
      this.props.handleClearAll({
        filters:{
          location:[],
          vehicleCategory:[],
          oemModel:[],
          dataSource:[],
          groups:[],
          soc:[]
        }
      })
      this.handleFilterClick('location');
    };
    
    // Function to apply filters
    handleApplyFilters = () => {
        this.props.onApplyFilterClick(this.props.filters)
        this.setState({filterPriority:[]});
    };

    exportToExcel = () => {
      const {
        vehicleData = [],
        topPanelQuery,
        filterVehicleDataObj: { soc, location },
      } = this.props;

    // Create a new workbook and worksheet using ExcelJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("vehiclestatus");
    let downloadName = topPanelQuery;
    if (soc.length) {
      downloadName = soc;
    } else if (location.length) {
      downloadName = location;
    }

    // Add the column names to the worksheet
    worksheet.addRow([
      "S.No",
      "Date & Time",
      "Vehicle No.",
      "OEM & Model",
      "Chasis No",
      "Motor No",
      "GPS Sync Time",
      "Can Sync Time",
      "State Of Charge",
      "Vehicle Status",
      "Speed",
      "Odometer",
      "DTE",
      "Battery Voltage",
      "Battery Temperature",
      "Communication Status",
      "GPS",
    ]);

    // Add the data rows to the worksheet
    for (let i = 0; i < vehicleData.length; i++) {
      let communicationStatus = vehicleData[i]?.ncom ? "No Network Connection" : "Connection Established"
      if (vehicleData[i]?.ncom && !vehicleData[i]?.ncomCon) {
        communicationStatus = 'No Network Connection'
      }
      const rowData = [
        i + 1,
        moment(vehicleData[i]?.lrtime).format("LLLL"),
        vehicleData[i]?.rn,
        vehicleData[i]?.canStatus?.oem + " " + vehicleData[i]?.canStatus?.mdl,
        vehicleData[i]?.cn,
        vehicleData[i]?.mn,
        moment(vehicleData[i]?.ltime).format("LLLL"),
        moment(vehicleData[i]?.canStatus?.ctime).format("LLLL"),
        vehicleData[i]?.canStatus?.soc,
        // Note: Merge Idle status to Live status
        vehicleData[i]?.canStatus?.vs === "Idle"
          ? "Live"
          : vehicleData[i]?.canStatus?.vs,
        vehicleData[i]?.canStatus?.sp,
        vehicleData[i]?.canStatus?.odo,
        vehicleData[i]?.canStatus?.dte ?? "NA",
        vehicleData[i]?.canStatus?.vbv ?? "NA",
        vehicleData[i]?.canStatus?.vbt ?? "NA",
        communicationStatus,
        vehicleData[i]?.lt + "," + vehicleData[i].lng,
      ];
      worksheet.addRow(rowData);
    }
    fileDownloader({
      excelWorkbook: workbook,
      fileName: `${downloadName}-vehicle-status.xlsx`,
    });
  };
  handleControllerClick = (key) => {
    const { mapConfig = {}, onUpdateMapConfigForZoom } = this.props;
    if (key === "download") {
      this.exportToExcel();
    }
    if (key === "zoomIn") {
      if (mapConfig.zoom !== mapConfig.maxZoom) {
        onUpdateMapConfigForZoom("zoomIn");
      }
    }
    if (key === "zoomOut") {
      if (mapConfig.zoom !== mapConfig.minZoom) {
        onUpdateMapConfigForZoom("zoomOut");
      }
    }
  };

  calculateFilterButtonPosition = () => {
    const { filterVehicleDataObj, listPanelOpen, isSidebarOpen } = this.props;
  

    const hasActiveFilters = Object.entries(filterVehicleDataObj).some(
      ([key, value]) =>
        ['location', 'soc', 'oemModel', 'vehicleCategory', 'dataSource', 'groupName'].includes(key) &&
        value?.length > 0
    );

    if (isSidebarOpen) {
      return hasActiveFilters ? '39%' : '38%';
    }
  
    if (listPanelOpen) {
      return hasActiveFilters ? '10%' : '10%';
    }
  
    return hasActiveFilters ? '10%' : '7%';
  };

  onFilterButtonClick = () => {
    this.props.onFilterButtonClick();
  };
  
  
  
  render() {
    const {
      variant,
      mapConfig = {},
      clusterConfig = {},
      mapRef,
      vehicleData = [],
      filterVehicleDataObj,
      disableControls,
      onClusterClick,
      groupsListData,
      listPanelOpen
    } = this.props;


   

    const renderColor = (e) => {
      const status = e?.canStatus?.vs;
      if (!e?.ncom) {
        switch (status) {
          // Note: Merge Idle status to Live status
          case "Live":
          case "Idle":
            return "live";
          case "Parked":
            return "immobile";
          case "Charging":
            return "charging";
          default:
            break;
        }
      } else {
        return "non-communication";
      }
    };

    const locationWiseVehicleData = _.groupBy(vehicleData, "loc");

    return (
      <div
        className={`nd_Dark  nd__dark-map-wrapper nd__map-wrapper nd__soc-sidebar-open ${
          ["DARK_VEHICLE_INFO", "GROUPS_VEHICLE_INFO"].includes(variant)
            ? "nd__vehicle_info_map"
            : ""
        }`}
        ref={this.componentRef}
      >
        <MapContainer
          style={{ height: "100%", width: "100%" }}
          attributionControl={mapConfig.attributionControl}
          maxZoom={mapConfig.maxZoom}
          minZoom={mapConfig.minZoom}
          maxNativeZoom={mapConfig.maxZoom}
          zoomControl={mapConfig.zoomControl}
          ref={mapRef}
          {...mapConfig}
        >
          <TileLayer
            attribution='&copy; <a href="http://openstreetmap.org/">OpenStreetMap</a> contributors'
            url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            maxZoom={mapConfig.maxZoom}
            maxNativeZoom={mapConfig.maxZoom}
          />

          {Object.keys(locationWiseVehicleData).map((location) => (
            <RenderMarkers
              key={`marker_group_${location}`}
              enableMarkerCluster={mapConfig.enableMarkerCluster}
              clusterConfig={{
                ...clusterConfig,
                ..._.get(locationWiseClusterGroupConfig, location, {}),
              }}
              mapRef={mapRef}
              onClusterClick={onClusterClick}
            >
              {locationWiseVehicleData[location].map((e) => {
                const markerIcon = divIcon({
                  html: `<div data-testid='dashboard-map-marker-icon-${e?.rn}'>
                  <span class="nd__map-marker nd__map-marker__${renderColor(e)}" style="transform: rotate(${e?.dir || 0 }deg)">
                  <span class="nd__map-marker__tip"></span>
                  </span>
                  </div>`,
                  iconSize: e?.markerConfig?.iconSize,
                  iconRetinaUrl: e?.markerConfig?.iconRetinaUrl,
                });
                return (
                  <Marker
                    position={[e?.lt, e?.lng]}
                    icon={markerIcon}
                    eventHandlers={{
                      click: () => {
                        if (this.props.onVehicleMarkerClick) {
                          this.props.onVehicleMarkerClick({
                            registrationNumber: e?.rn,
                            lat: e?.lt,
                            long: e?.lng,
                          });
                        }
                      },
                    }}
                    key={e?._id}
                  >
                    <Tooltip direction="right" offset={[16, 6]}>
                      {e?.rn}
                    </Tooltip>
                  </Marker>
                );
              })}
            </RenderMarkers>
          ))}

   {(disableControls || listPanelOpen) && (
     <>

     <div className="selectedOptionMainContainer"
          style={{ position: 'relative', right: this.props.isSidebarOpen ? '38%' : '9%' }}
        >
         {filterVehicleDataObj && Object?.keys(filterVehicleDataObj).length > 0 ? 
          Object?.entries(filterVehicleDataObj)
            .filter(([key, value]) => ['location', 'soc', 'oemModel', 'vehicleCategory', 'dataSource', 'groupName']?.includes(key) && value?.length > 0)
            .map(([key, value], index) => (
              <div key={index} className="optionSelectedContainer">
                <span style={{ color: '#fff', fontSize: '14px' }}>
                  {keyFilterNames[key] || key}
                </span>
                <Close className={`${styles.removeIcon}`}
                  onClick={() => this.props.handleRemoveOption(key)}
                />
              </div>
            )) 
          : null
        }
        </div>

        <div className="dashbord-filter-container" style={{position:'relative'}}>
          <button 
          id="uniqueFilterButton" 
          onClick={() => {
            if (listPanelOpen) {
            
              this.onFilterButtonClick();
            } else {
              this.toggleSidebar();
            }
          }}
          style={{ position: 'relative', right: this.calculateFilterButtonPosition() }}
        >
          <img  
            src="/images/rightSideBar/filter-sidebar.svg"
            alt="filter-icon" 
            style={{ marginRight: '10px' }}
          />
          Filter 
          {/* Show count if listPanelOpen is true */}
          {listPanelOpen ? (() => {
            const activeFiltersCount = Object.entries(filterVehicleDataObj)
              .filter(([key, value]) => ['location', 'soc', 'oemModel', 'vehicleCategory', 'dataSource', 'groupName'].includes(key) && value?.length > 0)
              .length;

            return activeFiltersCount > 0 ? (
              <span className={styles.filterCountStyle}>
                {activeFiltersCount}
              </span>
            ) : null;
          })() : (
            /* Else, show the count based on this.state.filters */
            Object.values(this.props.filters).filter(value => value.length > 0).length > 0 && (
              <span className={styles.filterCountStyle}>
                {Object.values(this.props.filters).filter(value => value.length > 0).length}
              </span>
            )
          )}
        </button>
          

        <div className={`nd__map_sidebar_controls map_sidebar_controls-open`} id="resetBtn">
           <img
                alt="resetBtn"
                src="/images/rightSideBar/resetImg.svg"
                title="Reset"
                id="resetBtn"
                style={
                  ["DARK_VEHICLE_INFO", "GROUPS_VEHICLE_INFO"].includes(variant)
                    ? {
                        display: "none",
                      }
                    : {
                        width: "36px",
                        height: "auto",
                      }
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleSOCSidebarToogle();
                  this.props.reset(this.componentRef);
                  this.props.handleClearAllFilters();
                }}
                data-testid="dashboard-map-reset-button"
              />
              <div className="nd__msc__zoom-controls">
                <img
                  alt="Zoom In"
                  src="/images/rightSideBar/addImg.png"
                  title="Zoom In"
                  style={{
                    cursor:
                      mapConfig.zoom !== mapConfig.maxZoom
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={() => this.handleControllerClick("zoomIn")}
                  data-testid="dashboard-map-zoomin-button"
                />
                <img
                  alt="Zoom Out"
                  src="/images/rightSideBar/removeImg.png"
                  title="Zoom Out"
                  style={{
                    cursor:
                      mapConfig.zoom !== mapConfig.minZoom
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={() => this.handleControllerClick("zoomOut")}
                  data-testid="dashboard-map-zoomout-button"
                />

                <img
                  alt="Full Screen"
                  src={
                    "/images/" +
                    (this.state.isFullScreen
                      ? "svgicon/trackingScreen/full_screen_exit.svg"
                      : "rightSideBar/fullMapImg.png")
                  }
                  title={
                    this.state.isFullScreen ? "Exit Full Screen" : "Full Screen"
                  }
                  style={{ height: "24px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.openMapFullScreen(this.componentRef);
                  }}
                  data-testid="dashboard-map-fullscreen-button"
                />
              </div>
            </div>
         </div>
            </>
     )}
        </MapContainer>
        { disableControls && (
        <SidebarFilter
          isSidebarOpen={this.props.isSidebarOpen}
          openFilterMenu={this.state.openFilterMenu}
          filters={this.props.filters}
          socData={this.state.socData}
          locationData={this.state.locationData}
          oemModalCountData={this.state.oemModalCountData}
          dataSourceData={this.state.dataSourceData}
          vehicleCategoryData={this.state.vehicleCategoryData}
          groupsListData={groupsListData}
          handleFilterClick={this.handleFilterClick}
          handleChangeFilter={this.props.handleChangeFilter}
          handleClearAll={this.handleClearAll}
          handleApplyFilters={this.handleApplyFilters}
          toggleSidebar={this.toggleSidebar}
          filteredVehicleData={this.state.filteredVehicleData}
        />)}
      </div>
    );
  }
}

NdMap_Dark.propTypes = {
  filterVehicleDataObj: PropTypes.object.isRequired,
  allDashboardVehicleData: PropTypes.shape([]).isRequired,
  topPanelQuery: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filterVehicleDataObj: state.dashboardReducer.filterVehicleData,
  allDashboardVehicleData: state.dashboardReducer.allDashboardVehicleData,
  topPanelQuery: state.dashboardReducer.filterVehicleData.topPanelQuery,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeFilter: dashboardAction.removeFilter,
      setAllFilters: dashboardAction.setFilter,
      OpenModalAction: openModal,
      closeModalAction: closeModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NdMap_Dark);
