import React from "react";
import cx from 'classnames'
import { Modal } from "react-bootstrap";
import styles from "./UploadFileModel.module.css";

const UploadErrorModel = ({ showUploadModal, setShowUploadModal, errorVehicles }) => {
  const [expansionId, setExpansionId] = React.useState({
    invalid: false,
    accessRequired: false,
  })

  const closeUploadErrorModal = () => {
    setShowUploadModal(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log(`Copied to clipboard: ${text}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const copyDuplicateVehicles = () => {
    const textToCopy = errorVehicles.duplicateVehicles.join(", ");
    copyToClipboard(textToCopy);
  }

  const copyAccessRequiredVehicles = () => {
    const textToCopy = errorVehicles.accessRequired.join(", ");
    copyToClipboard(textToCopy);
  };

  const copyInvalidVehicles = () => {
    const textToCopy = errorVehicles.inValid.join(", ");
    copyToClipboard(textToCopy);
  };

  const handleExpansion = (expansionId) => {
    setExpansionId(prevState => {
      return {
        ...prevState,
        [expansionId]: !prevState[expansionId]
      }
    })
  }

  return (
    <Modal
      show={showUploadModal}
      onHide={closeUploadErrorModal}
      className={styles.errorModal}
    >
      <div className={styles.closeButtonError}>
        <img
          className={styles.closeButton}
          src="/images/geofenceModuleIcon/closeBtn.svg"
          alt="close"
          onClick={closeUploadErrorModal}
        />
      </div>
      <Modal.Body className={styles.errorModalBody}>
        
        {!!errorVehicles.validVehicles.length && (
          <div className={styles.messageBox}>
            <div className={styles.messageHeader}>
              <img src="/images/geofenceModuleIcon/greenCheck.svg" alt="check" />
              <span>
                {errorVehicles.validVehicles.length > 1 ? `${errorVehicles.validVehicles.length} vehicles are successfully uploaded.` : `1 vehicle is successfully uploaded.`}
              </span>
            </div>
          </div>
        )}

        {!!errorVehicles.duplicateVehicles.length && (
          <div className={cx(styles.messageBox, styles.messageErrorBox, { [styles.messageBoxExpanded]: expansionId.duplicateVehicles })}>
            <div className={styles.messageHeader} onClick={() => handleExpansion('duplicateVehicles')}>
              <img src="/images/geofenceModuleIcon/redCheck.svg" alt="error" />
              <span>
                {errorVehicles.duplicateVehicles.length > 1 ? `${errorVehicles.duplicateVehicles.length} vehicles are already assigned to the alert.` : `1 vehicle is already assigned to the alert.`}
              </span>
              <button className={styles.copyButton}>
                <img src="/images/svgicon/arrow-up.png" alt="arrow icon" />
              </button>
            </div>
            <div className={styles.messageBody}>
              {errorVehicles.duplicateVehicles.map((vehicle, vehicleI) => (
                <span key={`vehicle_${vehicleI}`}>{vehicle}</span>
              ))}
            </div>
            <div className={styles.messageFooter}>
              <button
                onClick={copyDuplicateVehicles}
                className={styles.copyButton}
              >
                <img src="/images/geofenceModuleIcon/copyIcon.svg" alt="copy" />
                Copy
              </button>
            </div>
          </div>
        )}

        {!!errorVehicles.accessRequired.length && (
          <div className={cx(styles.messageBox, styles.messageErrorBox, { [styles.messageBoxExpanded]: expansionId.accessRequired })}>
            <div className={styles.messageHeader} onClick={() => handleExpansion('accessRequired')}>
              <img src="/images/geofenceModuleIcon/redCheck.svg" alt="error" />
              <span>
                {errorVehicles.duplicateVehicles.length > 1 ? `There are ${errorVehicles.duplicateVehicles.length} vehicles, those require access.` : `There is 1 vehicle, that requires access.`}
              </span>
              <button className={styles.copyButton}>
                <img src="/images/svgicon/arrow-up.png" alt="arrow icon" />
              </button>
            </div>
            <div className={styles.messageBody}>
              {errorVehicles.accessRequired.map((vehicle, vehicleI) => (
                <span key={`vehicle_${vehicleI}`}>{vehicle}</span>
              ))}
            </div>
            <div className={styles.messageFooter}>
              <button
                onClick={copyAccessRequiredVehicles}
                className={styles.copyButton}
              >
                <img src="/images/geofenceModuleIcon/copyIcon.svg" alt="copy" />
                Copy
              </button>
            </div>
          </div>
        )}

        {!!errorVehicles.inValid.length && (
          <div className={cx(styles.messageBox, styles.messageErrorBox, { [styles.messageBoxExpanded]: expansionId.invalid })}>
            <div className={styles.messageHeader} onClick={() => handleExpansion('invalid')}>
              <img src="/images/geofenceModuleIcon/redCheck.svg" alt="error" />
              <span>
                {errorVehicles.inValid.length > 1 ? `${errorVehicles.inValid.length} vehicles don't exist on Norminc.` : `1 vehicle doesn't exist on Norminc.`}
              </span>
              <button className={styles.copyButton}>
                <img src="/images/svgicon/arrow-up.png" alt="arrow icon" />
              </button>
            </div>
            <div className={styles.messageBody}>
              {errorVehicles.inValid.map((vehicle, vehicleI) => (
                <span key={`vehicle_${vehicleI}`}>{vehicle}</span>
              ))}
            </div>
            <div className={styles.messageFooter}>
              <button
                onClick={copyInvalidVehicles}
                className={styles.copyButton}
              >
                <img src="/images/geofenceModuleIcon/copyIcon.svg" alt="copy" />
                Copy
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UploadErrorModel;
