import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NDVehicleListing from './nd-vehicleListing/NDVehicleListing';
import NDVehicleInfoDark from './nd-vehicleInfoDark/NDVehicleInfoDark';
import NDMap from './nd-map/NdMap_Dark';
import { FetchAllDashboardVehicles } from '../../../store/actions/vehicle/vehicle';
import { GetUserRoleList } from "../../../store/actions/roleAndMenuMapping";
import dashboardAction from '../../../store/actions/dashboard/dashboard'
import { UserProfile } from '../../../store/actions/user';
import { NewDashboard_AlertTypes, itAdminUserId, nonCommStatusMapping } from '../../../utils/constants';
import {  filterVehicleDataBasedOnVehicleCategory , filterVehicleDataBasedOnDataSource , filterVehicleDataBasedOnSoc, filterVehicleDataBasedOnLocation , filterVehicleDataBasedOnOemAndModel ,getVehicleNosByGroupNames } from "../../../utils/common-methods";
import { AddFavoriteVehicle, DeleteFavoriteVehicle, GetAllFavoriteVehicle } from '../../../store/actions/favoriteVehicle'
import { fetchGroupList , getGroupslist } from '../../../store/actions/groups';
import './NewDashboard.css'
import 'leaflet/dist/leaflet.css'
import '../newLeafletDark.css'
import '../soctable.css'
import '../locationtable.css'

const defaultMapConfig = {
  center: [
    20.5937,
    78.9629,
  ],
  zoom: 5,
  attributionControl: false,
  maxZoom: 18,
  minZoom: 5,
  zoomControl: false,
  enableMarkerCluster: true,
  zoomControlPosition: 'topright'
}

const defaultMarkerClusterConfig = {
  chunkedLoading: true,
  disableClusteringAtZoom: 11,
  maxClusterRadius: 15
}

const vehicleIgnitionData = [{ value: 100, color: '#C9C9C9' }, { value: 100, color: '#C9C9C9' }, { value: 100, color: '#C9C9C9' }]

const vehicleStatusData = [
  {
    status: 100,
    color: '#029B88'
  },
  {
    status: 100,
    color: '#E06000'
  },
  {
    status: 100,
    color: '#22046B'
  },
  {
    status: 100,
    color: '#632008'
  },
  {
    status: 100,
    color: '#B31E85'
  }
]

const dashboardCamUrl = "https://static.videezy.com/system/resources/previews/000/014/052/original/loading_circle_bars.mp4"

class NewDashboard extends Component {
  constructor(props) {
    super(props);
    this.updatedVehicleDetails = createRef(null);
    this.mapRef = createRef(null);
    this.closeButtonRef = createRef(null)
    this.state = {
      listPanelOpen: 0,
      detailsPanelOpen: 0,
      mapConfig: defaultMapConfig,
      clusterConfig: defaultMarkerClusterConfig,
      isVehicleLoading: 0,
      activeAlert: NewDashboard_AlertTypes[0].key,
      selectedVehicleNumber: '',
      activeVehicleStatusBar: null,
      isDetailLoaded: 0,
      hoverCloseIcon: false,
      isSidebarOpen: false,
      filters:{
        location:[],
        vehicleCategory:[],
        oemModel:[],
        dataSource:[],
        groups:[],
        soc:[]
      },
    }

    this.onFavoriteFlagClick = this.onFavoriteFlagClick.bind(this)
  }

  componentDidMount() {
   
    this.fetchGroupsList();
    this.props.getUserProfile()
    this.props.GetUserRoleList()
    this.getAllDashboardVehicle()
    this.props.GetAllFavoriteVehicle();
    this.updatedVehicleDetails.current = setInterval(() => this.getAllDashboardVehicle(), (process.env.REACT_APP_DASHBOARD_REFRESH_INTERVAL_IN_SEC * 1000 ) || (2 * 60 * 1000))
    
    // on refresh if there is filter stored then load list view with applied filter
    if ((this.props.filterVehicleDataObj.topPanelQuery && this.props.filterVehicleDataObj.topPanelQuery !== 'all')) {
      this.onTopPanelQueryChange();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { 
      userId, 
      allDashboardVehicleData, 
      filterVehicleDataObj, 
      searchQuery, 
      homeClickCounter 
    } = this.props;
  
    // Handle admin user and no selected vehicle case
    if (itAdminUserId.includes(userId) && allDashboardVehicleData.length && !this.state.selectedVehicleNumber && filterVehicleDataObj.topPanelQuery === 'all') {
      this.setState({
        selectedVehicleNumber: allDashboardVehicleData[0]?.rn,
        detailsPanelOpen: 1
      });
    }
  
    // Handle top panel query change
    if (prevProps.filterVehicleDataObj.topPanelQuery !== filterVehicleDataObj.topPanelQuery) {
      this.resetState();
      if (filterVehicleDataObj.topPanelQuery && filterVehicleDataObj.topPanelQuery !== 'all') {
        setTimeout(this.onTopPanelQueryChange, 100);
      }
    }
  
    // Handle search query or home click counter change
    if (prevProps.searchQuery !== searchQuery || (!itAdminUserId.includes(userId) && prevProps.homeClickCounter !== homeClickCounter)) {
      this.resetState();
    }
  
  
    // Check if any relevant filter (SoC, location, OEM model, dataSource, groupName, vehicleCategory) has changed
    const filtersToCheck = ['soc', 'location', 'oemModel', 'dataSource', 'groupName', 'vehicleCategory'];
    filtersToCheck.forEach((filter) => {
      if (prevProps.filterVehicleDataObj[filter] !== filterVehicleDataObj[filter] && filterVehicleDataObj[filter]?.length > 0) {
        this.resetState();
        this.onTopPanelQueryChange();
      }
    });
  
    // Attach zoom change listener to map
    if (this.mapRef.current) {
      this.mapRef.current.on('zoom', this.handleZoomChange);
    }
  }
  

  handleZoomChange = () => {
    const mapConfigObj = JSON.parse(JSON.stringify(this.state.mapConfig))
      const zoom = this.mapRef.current.getZoom();
      const center = this.mapRef.current.getCenter();
      this.mapRef.current.setView([center.lat, center.lng],zoom)
      mapConfigObj.zoom = zoom;
      mapConfigObj.center = [center.lat, center.lng]
      this.setState({ mapConfig: mapConfigObj })
  }
  

  // Get all vehicle details
  getAllDashboardVehicle = async (withLoader = true) => {
    if (withLoader) {
      this.setState({ isVehicleLoading: 1 })
    }
    try {
      const response = await FetchAllDashboardVehicles()
      if (response.status === 200 && response.data.data) {
        this.props.setAllDashboardVehicleData(response.data.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      if (withLoader) {
        this.setState({ isVehicleLoading: 0 })
      }
    }
  }

  
	fetchGroupsList = async () => {
		const userId = JSON.parse(localStorage.getItem('user'))?._id;
		if (userId) {
			
			const result = await fetchGroupList(userId);
			this.props.setGroupsListAction(result);
		}
	}

  // close vehicle info list panel and details panel
  handleOnCloseSidebar = (e, view) => {
    // NOTE:
    // Default consider user is redirecting from details panel, and default redirection to List Panel
    // If user was not redirected from List Panel then don't show list panel
    // Instead open Main Map view

    const {
      prevTopPanelQuery,
      previousNonComType,
      prevOemModel,
      prevLocation,
      prevSoc,
      prevVehicleCategory,
      prevDataSource,
      prevGroupName,
      prevFilterVehicleNoByGroup
    } = this.props.filterVehicleDataObj;
  
    const stateUpdate = {
      listPanelOpen: 1,
      hoverCloseIcon: false,
      detailsPanelOpen: 0,
      mapConfig: defaultMapConfig,
      isDetailLoaded: 0,
      selectedVehicleNumber: '',
    };
  
    if (view === 'DETAIL') {
      if (prevTopPanelQuery) {
        const topPanelVehicleStatus = {
          topPanelQuery: prevTopPanelQuery,
          nonComType: previousNonComType,
          oemModel: prevOemModel,
          location: prevLocation,
          soc: prevSoc,
          vehicleCategory: prevVehicleCategory,
          dataSource: prevDataSource,
          groupName: prevGroupName,
          filterVehicleNoByGroup: prevFilterVehicleNoByGroup,
        };
  
        this.props.setTopPanelVehicleStatus(topPanelVehicleStatus);
  
        this.props.setPrevTopPanelVehicleStatus({
          topPanelQuery: '',
          nonComType: '',
          oemModel: [],
          location: [],
          soc: [],
          vehicleCategory: [],
          dataSource: [],
          groupName: [],
          filterVehicleNoByGroup: [],
        });
      } else {
        stateUpdate.listPanelOpen = 0;
        this.mapReset();
      }
    } else {
      stateUpdate.listPanelOpen = 0;
      this.mapReset();
    }
  
    this.setState(stateUpdate);
  };
  

  // handle active alert based on alert status
  handleActiveAlert = (alert) => {
    this.setState({ activeAlert: alert })
  }

   // handle location select
  onTopPanelQueryChange = () => {
    this.setState({ listPanelOpen: 1, mapConfig: defaultMapConfig, detailsPanelOpen: 0 })
  }


  filterVehicleDataByNumber = ({ searchQuery, vehicleData }) => {
    if ([null, undefined, '', false].includes(searchQuery)) {
      return vehicleData;
    }

    searchQuery = `${searchQuery}`.toLowerCase()
  
    // searchkeys: validate if it includes search term
    const searchKeys = []
    // matchkeys: validate if it exactly matches search term
    const matchKeys = []

    if (searchQuery.length === 4 && !isNaN(searchQuery)) {
      searchKeys.push('rn')
    }
    if (searchQuery.length === 10) {
      matchKeys.push('rn')
    }
    if (searchQuery.length === 17) {
      matchKeys.push('cn')
    }

    return vehicleData.filter((vehicle) => {
     if (searchKeys.length) {
        let matchFound = false;
        searchKeys.forEach((key) => {
          if (
            vehicle[key] &&
            `${vehicle[key]}`.toLowerCase().includes(searchQuery)
          ) {
            matchFound = true
          }
        })
        return matchFound
      }

      if (matchKeys.length) {
        let matchFound = false;
        matchKeys.forEach((key) => {
          if (vehicle[key] && `${vehicle[key]}`.toLowerCase() === searchQuery) {
            matchFound = true
          }
        })
        return matchFound
      }

      return true
    })
  }

  onCardClick = ({ registrationNumber, lat, long }) => {
    const mapConfig = {
      ...defaultMapConfig,
      zoom: defaultMapConfig.maxZoom,
      center: [lat, long]
    };
  
    this.setState({
      selectedVehicleNumber: registrationNumber,
      detailsPanelOpen: 1,
      listPanelOpen: 0,
      hoverCloseIcon: false,
      mapConfig
    }, () => {
      const { filterVehicleDataObj } = this.props;
  
      this.props.setPrevTopPanelVehicleStatus({
        topPanelQuery: filterVehicleDataObj.topPanelQuery,
        nonComType: filterVehicleDataObj.nonComType,
        soc: filterVehicleDataObj.soc,
        location: filterVehicleDataObj.location,
        oemModel: filterVehicleDataObj.oemModel,
        dataSource: filterVehicleDataObj.dataSource,
        vehicleCategory: filterVehicleDataObj.vehicleCategory,
        groupName: filterVehicleDataObj.groupName,
      });
  
      this.props.setTopPanelVehicleStatus({
        topPanelQuery: 'all',
        nonComType: '',
        oemModel: [],
        location: [],
        soc: [],
        vehicleCategory: [],
        dataSource: [],
        groupName: [],
        filterVehicleNoByGroup: []
      });
  
      this.props.setSearchQueryAction({ searchQuery: registrationNumber });
    });
  
    setTimeout(() => {
      this.mapRef.current.setView(mapConfig.center, mapConfig.zoom);
    }, 100);
  }
  

  async onFavoriteFlagClick(registration_number, isFavorite) {
    if (isFavorite) {
      this.props.DeleteFavoriteVehicle({ registration_number });
    } else {
      this.props.AddFavoriteVehicle({ registration_number });
    }
    this.props.GetAllFavoriteVehicle();
  }

  filterByLocation = () => {
    return this.props.allDashboardVehicleData.filter(vd => vd.loc === this.props.filterVehicleDataObj.location)
  }

  filterVehicleDataBasedOnVehicleStatus = (status) => {
    const nonCommType = this.props.filterVehicleDataObj.nonComType
    const { allDashboardVehicleData, favouriteVehicles } = this.props;
    let vehicleDataBasedOnStatus = allDashboardVehicleData;
    
    // communication status filters
    if (status === 'live') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => (!fd?.ncom || !fd?.ncomCon) && ['Idle', 'Live'].includes(fd?.canStatus?.vs))
    }
    if (status === 'parking') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => (!fd?.ncom || !fd?.ncomCon) && fd?.canStatus?.vs === 'Parked')
    }
    if (status === 'charging') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => (!fd?.ncom || !fd?.ncomCon) && fd?.canStatus?.vs === 'Charging')
    }
    
    if(status === 'nonCommunication') {
      if (['poorNetwork', 'pluggedOut', 'deviceFault', 'zeroData', 'inactiveGps'].includes(nonCommType)) {
        vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => fd?.ncom && fd?.ncomCon && fd?.ncomT === nonCommStatusMapping[nonCommType])
      }
    }
    // non-communication status filters

    if (status === 'favourite') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => favouriteVehicles.includes(fd?.rn));
    }
    if (status === 'ignitionOn') {
      vehicleDataBasedOnStatus = []
    }
    if (status === 'ignitionOff') {
      vehicleDataBasedOnStatus = []
    }
    return vehicleDataBasedOnStatus
  }


 filterVehicleDataBasedOnGroups = (groupNames,allDashboardVehicleData) => {

 let vehicles =  getVehicleNosByGroupNames(groupNames,this.props.groupsListData);
 const filteredVehicleData = allDashboardVehicleData?.filter(vehicle => 
   vehicles?.includes(vehicle.rn)
  );
 return filteredVehicleData;
 };

 
onApplyFilterClick = (filters) => {
  this.props.setAllFilters({
    soc: filters.soc,
    location: filters.location,
    oemModel: filters.oemModel,
    dataSource: filters.dataSource,
    vehicleCategory: filters.vehicleCategory,
    groupName: filters.groups,
    filterVehicleNoByGroup: getVehicleNosByGroupNames(filters.groups, this.props.groupsListData),
  });
  this.onTopPanelQueryChange();
  this.toggleSidebar();
  if (filters?.location?.length === 1) {
    const location = filters?.location[0]; // Get the single location
    const filterLocation = this.props?.masterLocationData?.filter(
      x => x.value.toLowerCase() === location.toLowerCase()
    );

    if (filterLocation && filterLocation.length === 1) {
      const lat = filterLocation[0].meta?.lat;
      const long = filterLocation[0].meta?.long;

      if (lat && long && this.mapRef.current) {
        // Use setTimeout to delay the map view update
        setTimeout(() => {
          this.mapRef.current.setView([lat, long], 10);
        }, 500);
      } else {
        console.warn('Map reference is not available or lat/long is missing.');
      }
    }
  }
};




  
  handleClearAll = ()  =>{
    this.props.setAllFilters({
      soc: [],
      location: [],
      oemModel: [],
      dataSource: [],
      vehicleCategory:[],
      groupName: [],
      filterVehicleNoByGroup: [],
    });   
  }


  
  

  // filter vehicle data based on selected filters
  filterVehicleDataBasedOnFilterVehicleDataObj = () => {
    const { filterVehicleDataObj = {}, allDashboardVehicleData = [], searchQuery } = this.props;
    let vehicleData = allDashboardVehicleData

    if (searchQuery) {
      vehicleData = this.filterVehicleDataByNumber({ searchQuery, vehicleData })
      if (vehicleData.length === 1) {
        const stateUpdate = {}
        stateUpdate.mapConfig = { ...defaultMapConfig }
        stateUpdate.mapConfig.center = [vehicleData[0].lt, vehicleData[0].lng]
        stateUpdate.mapConfig.zoom = defaultMapConfig.maxZoom
        stateUpdate.selectedVehicleNumber = vehicleData[0].rn
        
        if (!this.state.isDetailLoaded) {
          stateUpdate.isDetailLoaded = 1
          stateUpdate.detailsPanelOpen = 1
          stateUpdate.listPanelOpen = 1
        }
        
        setTimeout(() => {
          if (this.state.selectedVehicleNumber !== stateUpdate.selectedVehicleNumber) {
            this.setState({...stateUpdate})
            this.mapRef.current.setView(stateUpdate.mapConfig.center, stateUpdate.mapConfig.zoom)
          }
        }, 500);
        
      }
    } else {
		if (filterVehicleDataObj.topPanelQuery) {
			vehicleData = this.filterVehicleDataBasedOnVehicleStatus(filterVehicleDataObj.topPanelQuery);
		}


    if (filterVehicleDataObj?.vehicleCategory?.length > 0) {
      vehicleData = filterVehicleDataObj?.vehicleCategory.reduce((acc, vehicleCategory) => {
        return acc.concat(filterVehicleDataBasedOnVehicleCategory(vehicleCategory, vehicleData));
      }, []);
    }

     // Apply Location filter

      if (filterVehicleDataObj?.location?.length > 0) {
        vehicleData = filterVehicleDataObj?.location.reduce((acc, loc) => {
          return acc.concat(filterVehicleDataBasedOnLocation(vehicleData, loc));
        }, []);
      }
    // Apply OEM Model filter
    if (filterVehicleDataObj?.oemModel?.length > 0) {
      vehicleData = filterVehicleDataObj?.oemModel.reduce((acc, oemModel) => {
        return acc.concat(filterVehicleDataBasedOnOemAndModel(oemModel,vehicleData));
      }, []);
    }

    // Apply DataSource filter
    if (filterVehicleDataObj?.dataSource?.length > 0) {
      vehicleData = filterVehicleDataObj?.dataSource.reduce((acc, dataSource) => {
        return acc.concat(filterVehicleDataBasedOnDataSource(dataSource,vehicleData));
      }, []);
    }
  
    // Apply Soc Filter 

    if (filterVehicleDataObj?.soc?.length > 0) {
      vehicleData = filterVehicleDataBasedOnSoc(filterVehicleDataObj?.soc,vehicleData);
    }

    if (filterVehicleDataObj?.groupName?.length > 0) {
      vehicleData = this.filterVehicleDataBasedOnGroups(filterVehicleDataObj?.groupName,vehicleData);
    }

    }
    return vehicleData
  }





  // reset state
  resetState = () => {
    this.setState({
      listPanelOpen: 0,
      hoverCloseIcon: false,
      detailsPanelOpen: 0,
      mapConfig: { ...defaultMapConfig },
      clusterConfig: { ...defaultMarkerClusterConfig },
      isVehicleLoading: 0,
      activeAlert: 'Overspeed',
      selectedVehicleNumber: '',
    },() =>
      this.mapRef?.current?.setView(this.state.mapConfig.center,this.state.mapConfig.zoom)
    )
  }

  mapReset = () => {
    this.props.setTopPanelVehicleStatus({ topPanelQuery: 'all', nonComType: '' })
    this.props.setSearchQueryAction({ searchQuery: '' })
    this.resetState();
  }

  handleUpdateMapConfigForZoom = (key) => {
    const mapConfigObj = JSON.parse(JSON.stringify(this.state.mapConfig))
    if(key === 'zoomIn') {
      this.mapRef.current.setZoom(this.mapRef.current.getZoom() + 1)
      mapConfigObj.zoom = this.state.mapConfig.zoom + 1
    }
    if(key === 'zoomOut') {
      this.mapRef.current.setZoom(this.mapRef.current.getZoom() - 1)
      mapConfigObj.zoom = this.state.mapConfig.zoom - 1
    }
    this.setState({ mapConfig: { ...mapConfigObj } })
  }

  handleClusterClick = (e, disableClusteringAtZoom) => {
    setTimeout(() => {
      const center = [e.latlng.lat,e.latlng.lng]
      this.mapRef.current.setView(center, disableClusteringAtZoom || 10)
    },500)
  }

  componentWillUnmount() {
    clearInterval(this.updatedVehicleDetails.current);
    this.updatedVehicleDetails.current = null;
  }

  handleChangeFilter = (value, type) => {
    // Update local state
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [type]: value
      }
    }));

  };

  handleClearAllFilters  = () => {
    this.setState({
      filters:{
        location:[],
        vehicleCategory:[],
        oemModel:[],
        dataSource:[],
        groups:[],
        soc:[]
      }
    });
  };

  handleRemoveOption = (filterKey) => {
    this.setState(prevState => {
      const updatedFilters = { ...prevState.filters };
      delete updatedFilters[filterKey]; 
      return { filters: updatedFilters };
    });
  
    if (filterKey === 'location') {
      this.setState({ mapConfig: defaultMapConfig }, () => {
        this.mapRef?.current?.setView(this.state.mapConfig.center, this.state.mapConfig.zoom);
      });
    }
    this.props.setAllFilters({ [filterKey]: [] });
  };
  


  toggleSidebar = () => {
    this.setState((prevState) => ({
        isSidebarOpen: !prevState.isSidebarOpen,
    }));
   };

   
  onFilterButtonClick  = () => {
    this.setState({ listPanelOpen: 0 , detailsPanelOpen: 0 })
    this.setState({ isSidebarOpen: true });
  }



  render() {
    const { masterLocationData, favouriteVehicles, filterVehicleDataObj, searchQuery , groupsListData } = this.props;
    const { listPanelOpen, detailsPanelOpen, mapConfig, clusterConfig, activeAlert, selectedVehicleNumber } = this.state;

    const vehicleData = this.filterVehicleDataBasedOnFilterVehicleDataObj()
    let selectedVehicleData = {}
    if (searchQuery && vehicleData?.length === 1) {
      selectedVehicleData = vehicleData[0];
    } else {
      selectedVehicleData = this.props.allDashboardVehicleData.find(vd => vd.rn === selectedVehicleNumber)
    }

    if ((detailsPanelOpen || searchQuery) && selectedVehicleData) {
      let detailsMapConfig = mapConfig
      if(this.mapRef.current) {
        this.mapRef.current.setView([selectedVehicleData.lt, selectedVehicleData.lng], mapConfig.zoom)
      }
      return (
        <NDVehicleInfoDark
          selectedVehicleData={selectedVehicleData}
          activeAlert={activeAlert}
          handleActiveAlert={this.handleActiveAlert}
          dashboardCamUrl={dashboardCamUrl}
          detailsMapConfig={detailsMapConfig}
          favouriteVehicles={favouriteVehicles}
          handleOnCloseSidebar={(e) => this.handleOnCloseSidebar(e, 'DETAIL')}
          mapRef={this.mapRef}
          mapReset={this.mapReset}
          onFavoriteFlagClick={this.onFavoriteFlagClick}
          vehicleIgnitionData={vehicleIgnitionData}
          vehicleStatusData={vehicleStatusData}
          onHeaderClose={(e) => this.handleOnCloseSidebar(e, 'DETAIL')}
          isFavourite={favouriteVehicles?.includes(selectedVehicleData?.rn)}
          onUpdateMapConfigForZoom={this.handleUpdateMapConfigForZoom}
          onFilterButtonClick={this.onFilterButtonClick}
          filters={this.state.filters}
          handleChangeFilter={this.handleChangeFilter}
          handleClearAllFilters={this.handleClearAllFilters}
          handleRemoveOption={this.handleRemoveOption}
          toggleSidebar={this.toggleSidebar}
        />
      )
    }

    if (listPanelOpen) {
      return (
        <div className='new-dark-dashboard new-dashboard new_dark_dashboard_header'>
          <div className='nd__sidebar py-4 px-3'>
            {/* <div className='nd__sidebar-header'> */}
              <img  style={{ width:'35px', cursor:"pointer", position:'absolute', top:'0px', right:'0px'}} onClick={(e) => this.handleOnCloseSidebar(e, 'LIST')} src={this.state.hoverCloseIcon ? '/images/svgicon/trackingScreen/close_active.svg' : "/images/svgicon/trackingScreen/Close_fad.svg"}  alt='close-btn'  onMouseOver={() => this.setState({hoverCloseIcon : true})} onMouseOut={() => this.setState({hoverCloseIcon : false})}/>
            {/* <img src='/images/svgicon/deleteIconDark.svg' alt='vehicle' onClick={this.handleOnCloseSidebar} style={{ width:'35px', cursor:"pointer"}}></img> */}
            {/* </div> */}
            <div className='nd__sidebar-body-list'>
              <NDVehicleListing
                onCardClick={this.onCardClick}
                onFavoriteFlagClick={this.onFavoriteFlagClick}
                allVehiclesTrackingData={vehicleData}
                favouriteVehicles={favouriteVehicles}
              />
            </div>
          </div>
          <div className='nd__map-container'>
            <NDMap
              mapConfig={mapConfig}
              mapRef={this.mapRef}
              clusterConfig={clusterConfig}
              vehicleData={vehicleData}
              masterLocationData={masterLocationData}
              showSocSidebar={true}
              isSocSidebarOpen={false}
              selectedCity={filterVehicleDataObj.location}
              onVehicleMarkerClick={this.onCardClick}
              reset={this.mapReset}
              onUpdateMapConfigForZoom={this.handleUpdateMapConfigForZoom}
              onClusterClick={this.handleClusterClick}
              groupsListData={groupsListData}
              onApplyFilterClick={(filters) => this.onApplyFilterClick(filters)}
              handleClearAll={this.handleClearAll}
              disableControls={false}
              listPanelOpen={listPanelOpen}
              filters={this.state.filters}
              onFilterButtonClick={this.onFilterButtonClick}
              handleChangeFilter={this.handleChangeFilter}
              handleClearAllFilters={this.handleClearAllFilters}
              handleRemoveOption={this.handleRemoveOption}
              isSidebarOpen={this.state.isSidebarOpen}
              toggleSidebar={this.toggleSidebar}
            />
          </div>
        </div>
      )
    }

    return (
      <>
        <div className='new-dark-dashboard new-dashboard-map new-dashboard-map_height'>
          <NDMap
            mapConfig={mapConfig}
            mapRef={this.mapRef}
            clusterConfig={clusterConfig}
            vehicleData={vehicleData}
            masterLocationData={masterLocationData}
            showSocSidebar={true}
            selectedCity={filterVehicleDataObj.location}
            reset={this.mapReset}
            onVehicleMarkerClick={this.onCardClick}
            onUpdateMapConfigForZoom={this.handleUpdateMapConfigForZoom}
            onClusterClick={this.handleClusterClick}
            groupsListData={groupsListData}
            onApplyFilterClick={(filters) => this.onApplyFilterClick(filters)}
            handleClearAll={this.handleClearAll}
            disableControls={true}
            listPanelOpen={listPanelOpen}
            onFilterButtonClick={this.onFilterButtonClick}
            filters={this.state.filters}
            handleChangeFilter={this.handleChangeFilter}
            handleClearAllFilters={this.handleClearAllFilters}
            handleRemoveOption={this.handleRemoveOption}
            isSidebarOpen={this.state.isSidebarOpen}
            toggleSidebar={this.toggleSidebar}

          />
        </div>
      </>
    )
  }
}

NewDashboard.propTypes = {
  location: PropTypes.string.isRequired,
  topPaneStatus: PropTypes.string.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  filterVehicleDataObj: PropTypes.object.isRequired,
  vehicleChartData: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
  location: state.user.location,
  topPaneStatus: state.vehicle.topPaneStatus,
  masterLocationData: state.getAllUserRoleReducer.masterLocationData,
  searchQuery: state.dashboardReducer.topPanelSearchQuery,
  allDashboardVehicleData: state.dashboardReducer.allDashboardVehicleData,
  filterVehicleDataObj: state.dashboardReducer.filterVehicleData,
  favouriteVehicles: state.favoriteVehicle.favouriteVehicles,
  vehicleChartData: state.dashboardReducer.vehicleChartData,
  userId: state.user.userId,
  homeClickCounter: state.dashboardReducer.homeClickCounter,
  groupsListData: state.groupsReducer.groupsListData
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getUserProfile: UserProfile,
    setAllDashboardVehicleData: dashboardAction.setAllDashboardVehicleData,
    AddFavoriteVehicle,
    DeleteFavoriteVehicle,
    GetAllFavoriteVehicle,
    GetUserRoleList,
    setTopPanelVehicleStatus: dashboardAction.setTopPanelVehicleStatus,
    setSearchQueryAction: dashboardAction.setTopPanelSearchQuery,
    setPrevTopPanelVehicleStatus: dashboardAction.setPrevTopPanelVehicleStatus,
    setGroupsListAction: getGroupslist,
    setAllFilters: dashboardAction.setFilter,
  },
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(NewDashboard)
/*conflict-resolution-will be removed later*/