import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import moment from 'moment'
import { connect } from "react-redux";

import ActionTypes from '../../../../store/actionTypes'
import { clearLocalStorageExceptclientIP, fileDownloader } from '../../../../utils/common-methods'
import { GetAllNotificationData, closeModal, openModal } from '../../../../store/actions/notificationPanel'
import { ShowNotificationPanel } from '../../../../store/actions/notificationPanel'
import { nonCommStatusLabelMapping, nonCommStatusMapping, notificationStatus} from "../../../../utils/constants";
import {  filterVehicleDataBasedOnVehicleCategory , filterVehicleDataBasedOnDataSource , filterVehicleDataBasedOnSoc, filterVehicleDataBasedOnLocation , filterVehicleDataBasedOnOemAndModel , getVehicleNosByGroupNames } from "../../../../utils/common-methods";
import './DarkVehicleFilters.css'
import LogoutModalDark from '../../../pages/User/LogOutDark/LogoutModalDark';
import geofenceAction from '../../../../store/actions/geofence';

const getInitials = function (name = '') {
  const parts = name.split(' ')
  let initials = ''

  if (parts.length) {
    if (parts.length === 1) {
      initials = parts[0][0]
    } else {
      for (let i = 0; i < 2; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
          initials += parts[i][0]
        }
      }
    }
  }
  
  return initials
}

const RenderVehicleAndCount = ({ isDashboard, vehicleCount, activeVehicleStatus, onActiveVechileStatusChange, filterVehicleData ,setTopPanelStatusForNonCommVehicles}) => {
  
  const navigate = useNavigate();
  const handleOnClick = (status ) => {
    if (!isDashboard) {
      navigate("/")
      setTimeout(() => {
        if (onActiveVechileStatusChange) {
          onActiveVechileStatusChange(status)
          if(status === 'poorNetwork'|| status ==='pluggedOut' || status ==='deviceFault'||status ==="zeroData"||status ==="inactiveGps"){
            
            if (nonCommStatusMapping.hasOwnProperty(status)) {
              setTopPanelStatusForNonCommVehicles(status, nonCommStatusMapping[status]);
            }
          }
          else {
            onActiveVechileStatusChange(status)
          }}
      }, 300)
     
    }
  }
  const lableMap = {
    all: "All Vehicles",
    // ignitionOn: "",
    // ignitionOff: "",
    live: "Live Vehicles",
    // Note: Merge Idle status to Live status
    // idle: "Idle Vehicles",
    parking: "Parked Vehicles",
    charging: "Charging Vehicles",
    nonCommunication: "Non Communicating Vehicles",
    // poorNetwork:'Poor Network',
    // pluggedOut:'Plugged Out',
    // deviceFault:"Device Fault",
    // zeroData:"Zero Data",
    // inactiveGps:'Inactive GPS',
    favourite: "Favourite Vehicles",
  };
  return (
    Object.keys(vehicleCount).map(status => (
      <button  
        id={`vehicle_count_btn_${status}`}
        key={`vehicle_count_${status}`}
        className={`dvs__vehicle-container ${status}-vehicle`}
        style={{ backgroundColor: activeVehicleStatus === status && !filterVehicleData.soc && !filterVehicleData.location ? 'transparent' : 'transparent' }}
        onClick={() => handleOnClick(status)}
        title={lableMap[status]}
        data-testid={`header-vehicle-filter-button-${status}`}
      >
        <img
          className='dvs__vehicle-img'
          src={`/images/darkSVGIcon/vehicleStatus/${status}${activeVehicleStatus === status ? '_active' : ''}.svg`}
          alt={status}
          height={36}
          width={36}
        />

        <div className='d-flex justify-content-center align-items-center gap-1' style={{ minHeight: '24px' }}>
          {['live', 'parking', 'charging', 'nonCommunication'].includes(status) && (
            <img className={`dvs__vehicle-indicator-${status}`} src={`/images/darkSVGIcon/vehicleStatus/${status}_indicator.svg`} alt={`${status}_indicator`} />
          )}
          <p className='dvs__vehicle-count'>{vehicleCount[`${status}`]}</p>
        </div>
      </button>
    ))
  )
}

const DarkVehicleFilters = ({ route, isAuthenticated, UserProfile, showNotificationPanel, searchQuery, notificationData, getNotificationData, modalConfiguration ,OpenModalAction, closeModalAction , groupsListData , filterVehicleDataObj}) => {
  const dispatch = useDispatch()
  const vehicleData = useSelector(state => state.dashboardReducer.allDashboardVehicleData)
  const topPanelQuery = useSelector(state => state.dashboardReducer.filterVehicleData.topPanelQuery)
  const nonComType = useSelector(state => state.dashboardReducer.filterVehicleData.nonComType)
  const filterVehicleData = useSelector(state => state.dashboardReducer.filterVehicleData)
  const favouriteVehicle = useSelector(state => state.favoriteVehicle.favouriteVehicles)
  const [vehicleCount, setVehicleCount] = useState({
    all: 0,
    // ignitionOn: 0,
    // ignitionOff: 0,
    live: 0,
    // Note: Merge Idle status to Live status
    // idle: 0,
    parking: 0,
    charging: 0,
    nonCommunication: 0,
    // poorNetwork:0,
    // pluggedOut:0,
    // deviceFault:0,
    // zeroData:0,
    // inactiveGps:0,

    favourite: 0,
  })
  const[nonComVehicleCount, setNonComVehicleCount ] =useState({
    poorNetwork:0,
    pluggedOut:0,
    deviceFault:0
   
  })
  const notificationIntervalRef = useRef(null);
  const darklogoutClicked = useSelector((s) => s?.profileImageReducer?.darklogoutClicked);
  const [openLogoutModel, setOpenLogoutMode] = useState(darklogoutClicked);
 

  let _id = '';

  if (localStorage && localStorage.user) {
    _id = JSON.parse(localStorage.user)._id;
  }
  const logout = async () => {
    try {
      const res = await axios({
        url: 'user/logout',
        method: 'POST',
        headers: {
          clientIp: localStorage.clientIP,
          Authorization: `Bearer ${localStorage.token}`,
        },
        data: {
          _id
        },
      });
      if (res) {
        clearLocalStorageExceptclientIP()
        navigate('/login');
        dispatch({ type: 'logoutClicked', payload: false });
        dispatch(geofenceAction.reset())
      }
    } catch (error) {
      console.log('logouttt', error);
    }
  };

  const handleLogoutopen = () => {
    setOpenLogoutMode(!darklogoutClicked)
 
  };
  const closeLogoutModel = () => {
    setOpenLogoutMode(darklogoutClicked)
  }

  const navigate = useNavigate();

  useEffect(() => {

    filterVehicleCountBasedOnStatus();
  
    // eslint-disable-next-line
  }, [
    vehicleData, 
    favouriteVehicle, 
    filterVehicleData.dataSource, 
    filterVehicleData.groupName,
    filterVehicleData.location,
    filterVehicleData.oemModel,
    filterVehicleData.soc,
    filterVehicleData.vehicleCategory
  ]);
  

  useEffect(() => {
    document.addEventListener('click', (e) => {
        let iconId = e?.target?.id;
        if (iconId !== 'profileIcon' && iconId !== 'soc'&& iconId !== 'location') {
            closeModalAction();
        }
    });
    return () => {
        document.removeEventListener('click', () => {});
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const nameInitials = getInitials(localStorage.user ? JSON.parse(localStorage.user)?.name : '');

  const filterVehicleCountBasedOnStatus = () => {
    const vehicleCountObj = JSON.parse(JSON.stringify(vehicleCount));
    const nonComCountObj = JSON.parse(JSON.stringify(nonComVehicleCount));

    let filteredData = filterVehicleDataBasedOnFilterVehicleDataObj('count')
    const communicatingVehicle = filteredData?.filter(fd => (!fd?.ncom || !fd?.ncomCon))
    const nonComVehicle = filteredData?.filter(v => v?.ncom === true && v?.ncomCon === true)
    vehicleCountObj.all = filteredData.length
    // vehicleCountObj.nonCommunication = vehicleData?.filter(fd => fd.ncom).length
    nonComCountObj.poorNetwork = nonComVehicle?.filter(fd => fd?.ncomT === "Poor Network").length;
    nonComCountObj.pluggedOut = nonComVehicle?.filter(fd => fd?.ncomT === "Plugged Out").length;
    nonComCountObj.deviceFault = nonComVehicle?.filter(fd => fd?.ncomT === "Device Fault").length;
    nonComCountObj.zeroData = nonComVehicle?.filter(fd => fd?.ncomT === "Zero Data").length;
    nonComCountObj.inactiveGps = nonComVehicle?.filter(fd => fd?.ncomT === "Inactive GPS").length;

    vehicleCountObj.live = communicatingVehicle?.filter(fd => ["Live", "Idle"].includes(fd?.canStatus?.vs)).length;
    // Note: Merge Idle status to Live status
    // vehicleCountObj.idle = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Idle").length;
    vehicleCountObj.parking = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Parked").length;
    vehicleCountObj.charging = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Charging").length;

    const { poorNetwork, pluggedOut, inactiveGps } = nonComCountObj
    vehicleCountObj.nonCommunication = poorNetwork + pluggedOut + inactiveGps

    vehicleCountObj.favourite = favouriteVehicle?.length 
    setVehicleCount({ ...vehicleCountObj })
    setNonComVehicleCount({...nonComCountObj})
  }

  const handleActiveVehicleStatus = (status) => {
    if (status === 'nonCommunication') {
      OpenModalAction(status )
    } else {
      const payload = { topPanelQuery: status, nonComType: '',
        soc: filterVehicleDataObj.soc,
        location: filterVehicleDataObj.location,
        oemModel: filterVehicleDataObj.oemModel,
        dataSource: filterVehicleDataObj.dataSource,
        vehicleCategory: filterVehicleDataObj.vehicleCategory,
        groupName: filterVehicleDataObj.groupName,
       }
    dispatch({ type: ActionTypes.DASHBOARD.SET_TOP_PANEL_VEHICLE_STATUS, payload })
    }
  }

  const setTopPanelStatusForNonCommVehicles = (status, nonComType) => {
    dispatch({ type: ActionTypes.DASHBOARD.SET_TOP_PANEL_VEHICLE_NON_COM_STATUS, payload: { status, nonComType } })
  }


  const handleProfileClick = () => {
    UserProfile();
    navigate('/userProfile/profile');
  };

  const handleProfileOpen = (name) => {
    OpenModalAction(name )
}



  useEffect(() => {
    if (notificationIntervalRef.current) {
      clearInterval(notificationIntervalRef.current);
    }
    if ((isAuthenticated && notificationData && notificationData.length > 0 && notificationData?.some(x => x.status === notificationStatus.inProgress || x.status === notificationStatus.queued))) {
      notificationIntervalRef.current = setInterval(() => {
        getNotificationData()
      }, 60000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData, getNotificationData])

  useEffect(() => {
    if (isAuthenticated) {
      getNotificationData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNotificationData])

  // NOTE: Logic below is taken from dashboard
  // In-case logic update is required do update on dashboard and topbar filter both components
  // filter vehicle data based on Search Query
  const filterVehicleDataByNumber = ({ searchQuery, vehicleData }) => {
    if ([null, undefined, '', false].includes(searchQuery)) {
      return vehicleData;
    }

    searchQuery = `${searchQuery}`.toLowerCase()

    // searchkeys: validate if it includes search term
    const searchKeys = []
    // matchkeys: validate if it exactly matches search term
    const matchKeys = []

    if (searchQuery.length === 4 && !isNaN(searchQuery)) {
      searchKeys.push('rn')
    }
    if (searchQuery.length === 10) {
      matchKeys.push('rn')
    }
    if (searchQuery.length === 17) {
      matchKeys.push('cn')
    }

    return vehicleData.filter((vehicle) => {
      if (searchKeys.length) {
        let matchFound = false;
        searchKeys.forEach((key) => {
          if (
            vehicle[key] &&
            `${vehicle[key]}`.toLowerCase().includes(searchQuery)
          ) {
            matchFound = true
          }
        })
        return matchFound
      }

      if (matchKeys.length) {
        let matchFound = false;
        matchKeys.forEach((key) => {
          if (vehicle[key] && `${vehicle[key]}`.toLowerCase() === searchQuery) {
            matchFound = true
          }
        })
        return matchFound
      }

      return true
    })
  }

  const filterVehicleDataBasedOnVehicleStatus = (status) => {
    const allDashboardVehicleData = [...vehicleData]
    let vehicleDataBasedOnStatus = allDashboardVehicleData;
    
    // communication status filters
    if (status === 'live') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => (!fd?.ncom || !fd?.ncomCon) && ['Idle', 'Live'].includes(fd?.canStatus?.vs))
    }
    if (status === 'parking') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => (!fd?.ncom || !fd?.ncomCon) && fd?.canStatus?.vs === 'Parked')
    }
    if (status === 'charging') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => (!fd?.ncom || !fd?.ncomCon) && fd?.canStatus?.vs === 'Charging')
    }
    
    // non-communication status filters
    if (['poorNetwork', 'pluggedOut', 'deviceFault', 'zeroData', 'inactiveGps'].includes(status)) {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => fd?.ncom && fd?.ncomCon && fd?.ncomT === nonCommStatusMapping[status])
    }
    if (status === 'nonCommunication') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => fd?.ncom && fd?.ncomCon)
    }

    if (status === 'favourite') {
      vehicleDataBasedOnStatus = allDashboardVehicleData?.filter(fd => favouriteVehicle.includes(fd?.rn));
    }
    if (status === 'ignitionOn') {
      vehicleDataBasedOnStatus = []
    }
    if (status === 'ignitionOff') {
      vehicleDataBasedOnStatus = []
    }
    return vehicleDataBasedOnStatus
  }


 const filterVehicleDataBasedOnGroups = (groupNames,allDashboardVehicleData) => {
    let vehicles =  getVehicleNosByGroupNames(groupNames,groupsListData);
    const filteredVehicleData = allDashboardVehicleData?.filter(vehicle => 
      vehicles?.includes(vehicle.rn)
     );
    return filteredVehicleData;
    };

  const filterVehicleDataBasedOnFilterVehicleDataObj = (param) => {
    let filteredData = [...vehicleData]
    if (searchQuery) {
      filteredData = filterVehicleDataByNumber({ searchQuery, vehicleData: filteredData })
    } else {
      if (filterVehicleData.topPanelQuery && param !== "count" ) {
        if(topPanelQuery === 'nonCommunication') {
          filteredData = filterVehicleDataBasedOnVehicleStatus(nonComType)
        }
        else {
          filteredData = filterVehicleDataBasedOnVehicleStatus(filterVehicleData.topPanelQuery)
        }
      }
    

      if (filterVehicleData?.vehicleCategory?.length > 0) {
        filteredData = filterVehicleData?.vehicleCategory.reduce((acc, vehicleCategory) => {
          return acc.concat(filterVehicleDataBasedOnVehicleCategory(vehicleCategory, vehicleData));
        }, []);
      }


  
       // Apply Location filter
  
        if (filterVehicleData?.location?.length > 0) {
          filteredData = filterVehicleData.location.reduce((acc, loc) => {
            return acc.concat(filterVehicleDataBasedOnLocation(filteredData, loc));
          }, []);
    
        }
        
 
    
      // Apply OEM Model filter
      if (filterVehicleData?.oemModel?.length > 0) {
        filteredData = filterVehicleData.oemModel.reduce((acc, oemModel) => {
          return acc.concat(filterVehicleDataBasedOnOemAndModel(oemModel,filteredData));
        }, []);
      }
  
   
      // Apply DataSource filter
      if (filterVehicleData?.dataSource?.length > 0) {
        filteredData = filterVehicleData.dataSource.reduce((acc, dataSource) => {
          return acc.concat(filterVehicleDataBasedOnDataSource(dataSource,filteredData));
        }, []);
      }
    
   
  
      // Apply Soc Filter 
  
      if (filterVehicleData.soc?.length > 0) {
        filteredData = filterVehicleDataBasedOnSoc(filterVehicleData.soc,filteredData);
      }
  
      // Apply Group Filter

      if (filterVehicleData.groupName?.length > 0) {
        filteredData = filterVehicleDataBasedOnGroups(filterVehicleData.groupName,filteredData);
      }
    }
    return filteredData
  }


 

  const exportToExcel = () => {
    const filteredVehicleData = filterVehicleDataBasedOnFilterVehicleDataObj();
    const { soc, location , oemModel , dataSource , groupName , vehicleCategory} = filterVehicleData

    // Create a new workbook and worksheet using ExcelJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('vehiclestatus');
    let downloadName = topPanelQuery

    let filterCount = 0;

    if (soc?.length > 0) {
        downloadName += soc + " ";
        filterCount++;
    }
    if (location?.length > 0) {
        downloadName += location + " ";
        filterCount++;
    }
    if (oemModel?.length > 0) {
        downloadName += oemModel + " ";
        filterCount++;
    }
    if (dataSource?.length > 0) {
        downloadName += dataSource + " ";
        filterCount++;
    }

    if (groupName?.length > 0) {
        downloadName += `${groupName} group `;
        filterCount++;
    }
    if (vehicleCategory?.length > 0) {
        downloadName += vehicleCategory + " ";
        filterCount++;
    }

    if (filterCount > 1) {
        downloadName = "vehicle-status";
    }

    if (filterCount === 1) {
      downloadName += "-vehicle-status";
    }
    

    // Add the column names to the worksheet
    worksheet.addRow(['S.No', 'Date & Time', 'Vehicle No.', 'OEM & Model', 'Chasis No', 'Motor No', 'City', 'GPS Sync Time', 'Can Sync Time', 'State of Charge(SoC)', 'Vehicle Status', 'Speed', 'Odometer', 'DTE', 'Battery Voltage','Aux Battery Voltage', 'Battery Temperature', 'Communication Status', 'GPS', 'State of Health', 'Motor Temperature', 'Battery Current', 'Current Battery Capacity', 'BMS Temperature']);

    // Add the data rows to the worksheet
    for (let i = 0; i < filteredVehicleData.length; i++) {
      const rowVehicleData = filteredVehicleData[i]
      let communicationStatus = nonCommStatusLabelMapping[rowVehicleData?.ncomT || ''] || 'Connection Established'
      if (rowVehicleData?.ncom && !rowVehicleData?.ncomCon) {
        communicationStatus = 'No Network Connection'
      }

      const rowData = [
        i + 1,
        moment(rowVehicleData?.lrtime).format('LLLL'),
        rowVehicleData?.rn,
        rowVehicleData?.canStatus?.oem + ' ' + rowVehicleData?.canStatus?.mdl,
        rowVehicleData?.cn,
        rowVehicleData?.mn,
        rowVehicleData?.loc,
        moment(rowVehicleData?.ltime).format('LLLL'),
        moment(rowVehicleData?.canStatus?.ctime).format('LLLL'),
        rowVehicleData?.canStatus?.soc,
        rowVehicleData?.canStatus?.vs,
        rowVehicleData?.canStatus?.sp,
        rowVehicleData?.canStatus?.odo,
        rowVehicleData?.canStatus?.dte ?? 'NA',
        rowVehicleData?.canStatus?.vbv ?? 'NA',
        rowVehicleData?.canStatus?.dev ?? 'NA',
        rowVehicleData?.canStatus?.vbt ?? 'NA',
        communicationStatus,
        rowVehicleData?.lt + ',' + rowVehicleData.lng,
        rowVehicleData?.canStatus?.soh ?? 'NA',
        rowVehicleData?.canStatus?.vmt ?? 'NA',
        rowVehicleData?.canStatus?.vbc ?? 'NA',
        rowVehicleData?.canStatus?.vbe ?? 'NA',
        rowVehicleData?.canStatus?.vct ?? 'NA',
      ];
      worksheet.addRow(rowData);
    }
    fileDownloader({ excelWorkbook: workbook, fileName: `${downloadName}.xlsx` })
  };

  return (
    <div className='nd__dark-dashboard-vehicle-status mt-4'>
      {["/"].includes(route.pathname) && (<>
        <RenderVehicleAndCount vehicleCount={vehicleCount} activeVehicleStatus={filterVehicleData.topPanelQuery} filterVehicleData={filterVehicleData} onActiveVechileStatusChange={handleActiveVehicleStatus} setTopPanelStatusForNonCommVehicles={setTopPanelStatusForNonCommVehicles} />

        <button
          key={`notification`}
          className={`notification`}
          onClick={() => showNotificationPanel()}
          title='Notification'
          data-testid='header-notification-button'
        >
          <img className='dvs__vehicle-img' src={`/images/darkSVGIcon/notification.svg`} alt='notification' height={36} width={36} />
          <div className='dark-notification-count' style={{ flex: 1 }}>{notificationData && notificationData?.length > 0 && notificationData?.filter(x => !x.isNotified)?.length ? notificationData?.filter(x => !x.isNotified)?.length : ' '}</div>
        </button>

        <button
          key={`download`}
          className='topbar-download'
          onClick={exportToExcel}
          title="Export to Excel"
          data-testid='header-download-button'
        >
          <img className='dvs__download-img' src={`/images/darkSVGIcon/topbar_download.svg`} alt='download' height={36} width={36} />
          <div style={{ flex: 1 }}></div>
        </button>
        </>
      )}

      <button
        id='profileIcon'
        key={`profile`}
        className={`profile`}
        onClick={() => handleProfileOpen('profile')}
        title='Profile'
        data-testid='header-profile-button'
      >
        {nameInitials}
      </button>

      <div className={"avatar-text-container"}>
        <div className="dropdown">
          {modalConfiguration?.modalType === 'profile' && (
            <div
            className="dark-dropdown-content dropdown-custom-style"
            style={{
              width: 166,
            }}
            >
            <div className='nd__dark-custom-tooltip-arrow-dark-profile'></div>

              <li
                onClick={() => {
                  handleProfileClick();
                }}
              >
                Profile
              </li>
              <li
                onClick={() => {
                  navigate("/userProfile/activityLog");
                }}
              >
                Activity log
              </li>
              <li
                onClick={() => {
                  handleLogoutopen();
                }}
              >
                Logout
              </li>
            </div>
          )}
             {modalConfiguration?.modalType === 'nonCommunication' && (
            <div
            className="dark-dropdown-content dropdown-custom-style non-comm-style">

            <div className='nd__dark-custom-tooltip-arrow-dark-non-comm'></div>

              <li
                onClick={() => {
                  setTopPanelStatusForNonCommVehicles('nonCommunication', 'poorNetwork')
                }}
                style={{
                  marginLeft:5
                }}
              >
                <div className='listItemStyle' >
                <p style={{margin: 0}}> {nonCommStatusLabelMapping['Poor Network']} </p>
                <p style={{ margin: 0}}>{nonComVehicleCount?.poorNetwork}</p>
                </div>
              </li>
              <li
              style={{
                marginLeft:5
              }}
                onClick={() => {
                  setTopPanelStatusForNonCommVehicles('nonCommunication', 'pluggedOut')
                }}
              >
                <div className='listItemStyle'
                >
              <p style={{margin: 0}} > {nonCommStatusLabelMapping['Plugged Out']} </p>
              <p style={{margin: 0}} >{nonComVehicleCount?.pluggedOut}</p>
                </div>
              </li>
              <li
                onClick={() => {
                  setTopPanelStatusForNonCommVehicles('nonCommunication', 'inactiveGps')
                }}
                style={{
                  marginLeft:5
                }}
              >
                <div className='listItemStyle' >
                <p style={{margin: 0}} > {nonCommStatusLabelMapping['Inactive GPS']} </p>
                 <p style={{margin: 0}} >{nonComVehicleCount?.inactiveGps}</p>
                </div>
              </li>
            
            </div>
          )}

          {openLogoutModel && (
            <LogoutModalDark
              isVisible={openLogoutModel}
              closeModal={closeLogoutModel}
              logout={logout}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  notificationData: state.notificationPanelReducer.notificationData,
  checkForNotification: state.notificationPanelReducer.checkForNotification,
  isAuthenticated: state.auth.isAuthenticated,
  modalConfiguration:state.notificationPanelReducer.modalConfiguration,
  groupsListData: state.groupsReducer.groupsListData,
  filterVehicleDataObj: state.dashboardReducer.filterVehicleData,
  // Logout,
});


export default connect(mapStateToProps, {
  getNotificationData: GetAllNotificationData,
  showNotificationPanel: ShowNotificationPanel,
  OpenModalAction:openModal,
  closeModalAction:closeModal
  //  Logout
})(DarkVehicleFilters);

/*conflict-resolution-will be removed later*/