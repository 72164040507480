import Pagination from '../../../../../helpers/hoc/paginator/Pagination';
import '../../../../../pages/soctable.css';
import { BsX, BsDownload } from 'react-icons/bs';
import LocationSvg from '../AlarmAnalyticsUtilities/location.svg';
import MapAddressByLocation from '../../../../../helpers/hoc/latlong-to-address-map/MapAddressByLocation';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getFormattedDate } from '../../../../../../utils/common-methods';
import { BiInfoCircle } from 'react-icons/bi';
import './AlarmAnalyticsTable.css';
import { useDispatch } from 'react-redux';
import {useEffect, useState } from 'react';
import ActionTypes from '../../../../../../store/actionTypes.js';
import { exportParkedVehiclesForDuration, exportNonCommLogsForDuration, exportAlarmsReportByType } from '../../../../../../store/actions/reports/alarm.js'
import moment from 'moment';
import { Alarm_AlertTypes } from '../../../../../../utils/constants.js';


const  { SET_ALERT_SCREEN_DRIVING_TABLE_PAGE, SET_ALERT_SCREEN_ALARM_TABLE_PAGE, SET_ALERT_SCREEN_IMMOBILE_TABLE_PAGE, SET_ALERT_SCREEN_NON_COMM_TABLE_PAGE } = ActionTypes.REPORTS.ALARM

const AlarmAnalyticsTable = ({
	isOpen,
	name,
	setParked,
	setNonCommunication,
	parkedData,
	nonCommData,
	alarmData,
	setAlarms,
	setBattery,
	batteryData,
	showCurrentData,
	selectedDates,
}) => {
	const handleCloseButtonClick = () => {
		if (name === 'Parked Vehicles') {
			setParked(false);
		} else if (name === 'Non Communication Vehicles') {
			setNonCommunication(false);
		} else if (name === 'Driving Alert Data') {
			setAlarms(false);
		} else if (name === 'Battery Alert Data') {
			setBattery(false);
		}
	};

	function renderTooltipLog(props) {
		if (props.showCurrentData){
			return (
				<Tooltip className='tooltip' id='button-tooltip' {...props}>
				{props.parameter1}: {props.start}
				{props?.unit ? props.unit : ''} <br />
			</Tooltip>
			)
		}
		return (
			<Tooltip className='tooltip' id='button-tooltip' {...props}>
				{props.parameter1}: {props.start}
				{props?.unit ? props.unit : ''} <br />
				{props.parameter2}: {props.end}
				{props?.unit ? props.unit : ''}
			</Tooltip>
		);
	}
	const handleTableClick = (e) => {
		e.stopPropagation();
	};

	const [coordinates, setCoordinates] = useState({
		lat: null,
		lng: null,
	});
	const [isMapOpen, setIsMapOpen] = useState(false);

	const ITEMS_PER_PAGE = 8;

	const dispatch = useDispatch();

	const handleDrivingDataPageChange = (page) => {
		dispatch({type:SET_ALERT_SCREEN_DRIVING_TABLE_PAGE,payload:page-1})
	}

	const handleBatteryDataPageChange = (page) => {
		dispatch({type:SET_ALERT_SCREEN_ALARM_TABLE_PAGE,payload:page-1})
	}

	const handleImmobilePageChange = (page) => {
		dispatch({type:SET_ALERT_SCREEN_IMMOBILE_TABLE_PAGE,payload:page-1})
	}

	const handleNonCommPageChange = (page) => {
		dispatch({type:SET_ALERT_SCREEN_NON_COMM_TABLE_PAGE,payload:page-1})
	}

	const togglePopup = () => {
		setIsMapOpen(false);
	};
	const openMap = (val) => {
		const coords = val.split(',').map(parseFloat);

		setCoordinates({
			lat: coords[0],
			lng: coords[1],
		});
		setIsMapOpen(true);
	};
	function secondsToDHMS(seconds) {
		const days = Math.floor(seconds / (24 * 3600));
		const hours = Math.floor((seconds % (24 * 3600)) / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const remainingSeconds = Math.floor(seconds % 60);

		const formattedDays = days < 10 ? `0${days}` : `${days}`;
		const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

		return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	}

	useEffect(() => {
		const removeListener = addEscapeKeyListener(() => {
		  console.log('Escape key pressed globally!');
		});
	
		return () => {
		  removeListener();
		};
	  }, []);

	const exportParkedData = () => {
		dispatch(exportParkedVehiclesForDuration())
	};

	const exportNonCommData = () => {
		dispatch(exportNonCommLogsForDuration())
	};
	const exportAlarmsData = () => {
		dispatch(exportAlarmsReportByType())
	};
	const exportBatteryAlarmsData = () => {
		dispatch(exportAlarmsReportByType())
	};

	// const getGps = async ({ startLat, startLong }) => {
	// 	const gps = `${startLat},${startLong}`
	// 	openMap(gps);
	// }
     const addEscapeKeyListener = (callback) => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            callback();
          }
        };
      
        window.addEventListener('keydown', handleKeyDown);
      
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      };

        useEffect(() => {
            // Function to handle escape key press and close the popup/modal
            const closePopup = () => {
                if(isMapOpen) {
                    setIsMapOpen(false)
                }
            else {
                if (name === 'Parked Vehicles') {
                    setParked(false);
                } else if (name === 'Non Communication Vehicles') {
                    setNonCommunication(false);
                } else if (name === 'Driving Alert Data') {
                    setAlarms(false);
                } else if (name === 'Battery Alert Data') {
                    setBattery(false);
                }
            }
            };
        
            // Adding escape key listener
            const removeEscapeListener = addEscapeKeyListener(closePopup);
        
            // Cleanup function to remove the escape key listener
            return () => {
            removeEscapeListener();
            };
        }, [name, setParked, setNonCommunication, setAlarms, setBattery, isMapOpen]);
    
	const calculatedSeconds = (startDateTime) => {
		const currentDateTime = moment();
		const differenceInSeconds = currentDateTime.diff(moment(startDateTime), 'seconds')
		return differenceInSeconds
	}

	const CloseContainer = () => {
		return (
					<div
						style={{
							display:'flex',
							flexDirection:'row-reverse',
						}}
						onClick={handleCloseButtonClick}
						>
							<div 
							className='popup-close'
						 id='parkedPopup'
						>
							<BsX size={24}
							color='black'
							/>
						</div>

						</div>
		)
	}

	return (
		<>
			{name === 'Parked Vehicles' && (
				<div id='popup1' className={`popup ${isOpen ? 'open' : ''}`} onClick={handleTableClick}>
					<div className='popup-overlay'></div>
					<div className='popup-content al__pop-pup-table-content'>
						<CloseContainer />		
						{isOpen && (
							<div className='filters-data'>
								<div className='filters-header'>
									<div
										className='col-12'
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<h5 className='' style={{ marginTop: '15px' }}>
											{name}
										</h5>
										<div>
											<button
												className='dwn-btn al__dwn-btn'
												onClick={exportParkedData}
												disabled={parkedData?.records?.length === 0}
											>
												<BsDownload style={{width:'22px',height:'22px'}} />
											</button>
										</div>
									</div>
								</div>

								{selectedDates && (
									<p className='filters-header-date'>
										<span>From {getFormattedDate(selectedDates.selectedFromDate, false, true)}</span>
										<span>To {getFormattedDate(selectedDates.selectedToDate, false, true)}</span>
									</p>
								)}

								<div className='table-body-container'>
									<table
										className='alarmAnalytics-table'
									>
										<thead
											style={{
												position: 'sticky',
												top: 0,
												textAlign: 'center',
												zIndex: 10,
											}}
										>
											<th>S.No.</th>
											<th>Vehicle No.</th>
											<th>OEM & Model</th>
											<th>City</th>
											<th>Duration</th>
											<th>GPS</th>
										</thead>

										<tbody
											style={{
												zIndex: -1,
											}}
										>
											{parkedData.records && parkedData.records.length > 0 ? (
												parkedData.records.map((e, index) => (
													<tr key={e?._id}>
														<td> {(parkedData.page * ITEMS_PER_PAGE) + index +1 }. </td>
														<td> {e?.registration_number} </td>
														<td> {`${e?.oem} ${e?.model}`} </td>
														{e?.city ? <td> {e?.city} </td> : <td> N.A </td>}

														<td>
															<div className='d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center'>
																{showCurrentData ? secondsToDHMS(calculatedSeconds(e?.startDateTime)) : e?.duration}

																<OverlayTrigger
																	placement='auto-end'
																	delay={{ show: 0, hide: 0 }}
																	overlay={renderTooltipLog({
																		parameter1: 'Start',
																		parameter2: 'End',
																		start: getFormattedDate(e.startDateTime),
																		end: getFormattedDate(e.endDateTime),
																		showCurrentData: showCurrentData,
																	})}
																>
																	<button className='row-btn'>
																		<BiInfoCircle className='row-i-icon' />
																	</button>
																</OverlayTrigger>
															</div>
														</td>
														<td
															onClick={() => openMap(e.startLat+","+e.startLong)}
															style={{
																cursor: 'pointer',
															}}
														>
															<img src={LocationSvg} alt='location'></img>
														</td>
													</tr>
												))
											) : (
												<tr>
													<td colSpan='6' style={{ textAlign: 'center' }}>
														<div className='no-record-found-label d-flex flex-row justify-content-center align-items-center'>
															<span className='no-data-text'>No data found</span>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>

									<Pagination
										disabled={false}
										totalPages={Math.ceil(parkedData.count / ITEMS_PER_PAGE)}
										currentPage={parkedData.page+1}
										setPage={handleImmobilePageChange}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{name === 'Non Communication Vehicles' && (
				<div id='popup1' className={`popup ${isOpen ? 'open' : ''}`} onClick={handleTableClick}>
					<div
						className='popup-overlay'
					></div>
					<div className='popup-content al__pop-pup-table-content'>
					<CloseContainer />
						{isOpen && (
							<div className='filters-data'>
								<div className='filters-header'>
									<div
										className='col-12'
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<h5 className='' style={{ marginTop: '15px' }}>
											{name}
										</h5>
										<div>
											<button
												className='dwn-btn al__dwn-btn'
												onClick={exportNonCommData}
												disabled={nonCommData?.records?.length === 0}
											>
												<BsDownload style={{width:'22px',height:'22px'}} />
											</button>
										</div>
									</div>
								</div>

								<div className='table-body-container'>
									<table className='alarmAnalytics-table'>
										<thead
											style={{
												position: 'sticky',
												top: 0,
												textAlign: 'center',
												zIndex: 10,
											}}
										>
											<th>S.No.</th>
											<th>Vehicle No.</th>
											<th>OEM & Model</th>
											<th> City </th>
											<th>Duration</th>
											<th>GPS</th>
										</thead>

										<tbody
											style={{
												zIndex: -1,
											}}
										>
											{nonCommData.records && nonCommData.records.length > 0 ? (
												nonCommData.records.map((e, index) => (
													<tr key={index}>
														<td> {(nonCommData.page * ITEMS_PER_PAGE) + index +1 }. </td>
														<td> {e?.registration_number} </td>
														<td> {`${e?.oem} ${e?.model}`} </td>
														{e?.city ? <td> {e?.city} </td> : <td> N.A </td>}
														<td>
															<div className='d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center'>
															{secondsToDHMS(e?.duration)}

																<OverlayTrigger
																	placement='auto-end'
																	delay={{ show: 0, hide: 0 }}
																	overlay={renderTooltipLog({
																		parameter1: 'Start',
																		parameter2: 'End',
																		start: getFormattedDate(e.startDateTime),
																		end: getFormattedDate(e.endDateTime),
																		showCurrentData: showCurrentData,
																	})}
																>
																	<button className='row-btn'>
																		<BiInfoCircle className='row-i-icon' />
																	</button>
																</OverlayTrigger>
															</div>
														</td>
														<td
															onClick={() => openMap(e?.startCordinates)}
															style={{
																cursor: 'pointer',
															}}
														>
															<img src={LocationSvg} alt='location'></img>
														</td>
													</tr>
												))
											) : (
												<tr>
													<td colSpan='6' style={{ textAlign: 'center' }}>
														<div className='no-record-found-label d-flex flex-row justify-content-center align-items-center'>
															<span className='no-data-text'>No data found</span>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>

									<Pagination
										className='p-0'
										disabled={false}
										totalPages={Math.ceil(nonCommData.count / ITEMS_PER_PAGE)}
										currentPage={nonCommData.page+1}
										setPage={handleNonCommPageChange}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{name === 'Driving Alert Data' && (
				<div id='popup1' className={`popup ${isOpen ? 'open' : ''}`} onClick={handleTableClick}>
					<div className='popup-overlay'></div>
					<div className='popup-content al__pop-pup-table-content'>
					<CloseContainer />
						{isOpen && (
							<div className='filters-data'>
								<div className='filters-header'>
									<div
										className='col-12'
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<h5 className='' style={{ marginTop: '15px' }}>
											{name}
										</h5>
										<div>
											<button
												className='dwn-btn al__dwn-btn'
												onClick={exportAlarmsData}
												disabled={alarmData?.records?.length === 0}
											>
												<BsDownload style={{width:'22px',height:'22px'}} />
											</button>
										</div>
									</div>
								</div>

								<div className='table-body-container'>
									<table
										className='alarmAnalytics-table'
									>
										<thead
											style={{
												position: 'sticky',
												top: 0,
												textAlign: 'center',
												zIndex: 10,
											}}
										>
											<th>S.No.</th>
											<th>Vehicle No.</th>
											<th>OEM & Model</th>
											<th>Date & Time</th>
											<th>Alert Type</th>
											<th>Alert Value</th>
											<th>GPS</th>
										</thead>

										<tbody
											style={{
												zIndex: -1,
											}}
										>
											{alarmData.records && alarmData.records.length > 0 ? (
												alarmData.records.map((e, index) => (
													<tr key={e?._id}>
														<td> {(alarmData.page * ITEMS_PER_PAGE) + index + 1}. </td>
														<td style={{ textAlign: 'left' }}>
															{' '}
															{e?.registration_number}{' '}
														</td>
														<td> {`${e?.oem} ${e?.model}`} </td>
														<td>
															{e?.data_timestamp
																? getFormattedDate(e.data_timestamp)
																: e?.data_timestamp}
														</td>
														<td>{e.alarmType}</td>
														<td>
															{(e.alarmValue &&  e.alarmValue !== "NA" ? Math.round(e.alarmValue) : (e.alarmValue !== "NA") ? 0 : e.alarmValue) +
																'' +
																e.alarmValueUnit}
														</td>
														<td
															onClick={() => openMap(e?.gps)}
															style={{
																cursor: 'pointer',
															}}
														>
															<img src={LocationSvg} alt='location'></img>
														</td>
													</tr>
												))
											) : (
												<tr>
													<td colSpan='7' style={{ textAlign: 'center' }}>
														<div className='no-record-found-label d-flex flex-row justify-content-center align-items-center'>
															<span className='no-data-text'>No data found</span>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>

									<Pagination
										disabled={false}
										totalPages={Math.ceil(alarmData.count / ITEMS_PER_PAGE)}
										currentPage={alarmData.page+1}
										setPage={handleDrivingDataPageChange}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{name === 'Battery Alert Data' && (
				<div id='popup1' className={`popup ${isOpen ? 'open' : ''}`} onClick={handleTableClick}>
					<div className='popup-overlay'></div>
					<div className='popup-content al__pop-pup-table-content'>
					<CloseContainer />
						{isOpen && (
							<div className='filters-data'>
								<div className='filters-header'>
									<div
										className='col-12'
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<h5 className='' style={{ marginTop: '20px' }}>
											{name}
										</h5>
										<div>
											<button
												className='dwn-btn al__dwn-btn'
												onClick={exportBatteryAlarmsData}
												disabled={batteryData?.records?.length === 0}
											>
												<BsDownload style={{width:'22px',height:'22px'}} />
											</button>
										</div>
									</div>
								</div>

								<div className='table-body-container'>
									<table
										className='alarmAnalytics-table'
									>
										<thead
											style={{
												position: 'sticky',
												top: 0,
												textAlign: 'center',
												zIndex: 10,
											}}
										>
											<th>S.No.</th>
											<th>Vehicle No.</th>
											<th>OEM & Model</th>
											<th>Date & Time</th>
											<th>Alert Type</th>
											<th>Alert Value</th>
											<th>GPS</th>
										</thead>

										<tbody
											style={{
												zIndex: -1,
											}}
										>
											{batteryData.records?.map((e, index) => {
												return (
													<tr key={e?._id}>
														<td> {(batteryData.page*ITEMS_PER_PAGE) + index + 1}. </td>
														<td> {e?.registration_number} </td>
														<td> {`${e?.oem} ${e?.model}`} </td>
														<td>
															{e?.data_timestamp
																? getFormattedDate(e.data_timestamp)
																: e?.data_timestamp}
														</td>
														<td>{e.alarmType}</td>
														<td>
															{(e.alarmValue &&  e.alarmValue !== "NA" ? e.alarmType === Alarm_AlertTypes.AuxBatteryUnderVoltage.key ? parseFloat(e.alarmValue)?.toFixed(2) : Math.round(e.alarmValue) : (e.alarmValue !== "NA") ? 0 : e.alarmValue) +
																'' +
																e.alarmValueUnit}
														</td>
														<td
															onClick={() => openMap(e?.gps)}
															style={{
																cursor: 'pointer',
															}}
														>
															<img src={LocationSvg} alt='location'></img>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>

									<Pagination
										disabled={false}
										totalPages={Math.ceil(batteryData.count / ITEMS_PER_PAGE)}
										currentPage={batteryData.page+1}
										setPage={handleBatteryDataPageChange}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{isMapOpen && (
				<div onClick={handleTableClick}>
					<MapAddressByLocation
						isOpen={isMapOpen}
						toggleModal={togglePopup}
						CoOrdinates={coordinates}
					/>
				</div>
			)}
		</>
	);
};

export default AlarmAnalyticsTable;
