import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './sidebar/sidebar';
import '../styles/globalStyles.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LogoutModal from "./pages/User/LogOut/LogoutModal";
import { clearLocalStorageExceptclientIP } from '../utils/common-methods';
import { GetSubscribedVehiclesByCIAction } from '../store/actions/vehicle/vehicle';
import geofenceAction from '../store/actions/geofence';

const Layout = () => {
	const navigate = useNavigate();

	const logoutClicked = useSelector((s) => s?.profileImageReducer?.logoutClicked);
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(GetSubscribedVehiclesByCIAction());
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
   
	let _id = '';

	if (localStorage && localStorage.user) {
		_id = JSON.parse(localStorage.user)._id;
	}
	const logout = async () => {
		try {
			const res = await axios({
				url: 'user/logout',
				method: 'POST',
				headers: {
					clientIp: localStorage.clientIP,
					Authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					_id
				},
			});
			if (res) {
        clearLocalStorageExceptclientIP()
				navigate('/login');
				dispatch({ type: 'logoutClicked', payload: false });
				dispatch(geofenceAction.reset())
			}
		} catch (error) {
			console.log('logouttt', error);
		}
	};
	return (
		<div className='dashboard-main-container'>
			<Sidebar />
			<section className='main-section'>
				<div className='main-content'>
					<LogoutModal
						isVisible={logoutClicked}
						closeModal={() => dispatch({ type: 'logoutClicked', payload: false })}
						logout={logout}
					/>
					<Outlet />
				</div>
			</section>
		</div>
	);
};

export default Layout;
