import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './css/AlarmReport.css'
import { BsDownload } from 'react-icons/bs'
import { Container, Table, Button } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { getFormattedDate, getActiveAlarmAlertTypes } from '../../../utils/common-methods'
import PropTypes from 'prop-types'
import Pagination from '../../helpers/hoc/paginator/Pagination'
import MultiSelect from '../../helpers/hoc/mult-select/MultiSelect'
import Loader from '../../helpers/hoc/loader/Loader'
import {
  getAllAlarms,
  ExportAlarmsReport,
} from '../../../store/actions/reports/alarm'
import { useLocation } from "react-router-dom";
import NoRecordFound from '../../helpers/hoc/noRecordFound'
import { BiInfoCircle } from 'react-icons/bi'
import MapAdddressByLocation from '../../helpers/hoc/latlong-to-address-map/MapAddressByLocation'
import { GetUserRoleList } from '../../../store/actions/roleAndMenuMapping'
import { Alarm_AlertTypes, Report_Filter_Type } from '../../../utils/constants'
import DatePicker from '../../helpers/hoc/date-picker/DatePicker'
import { mapper } from '../../../utils/mapper'

const AlarmReport = ({
  //actions
  getAllAlarms,
  ExportAlarmsReport,
  GetUserRoleList,

  //states
  allAlarmsData,
  dataCount,
  isLoading,
  vehicleNumber,
  alarmReportDate,
  subscribedByCI,
}) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [intervalDuration, setIntervalDuration] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredVehicle, setFilteredVehicle] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [coordinate, setCoordinate] = useState();
  const [filterType, setFilterType] = useState(Report_Filter_Type.VEHICLE)
  const [selectedOemModel, setSelectedOemModel] = useState([])
  const [selectedCity, setSelectedCity] = useState([])
  const [selectedAlarmTypes, setSelectedAlarmTypes] = useState([])
  const [clearDateSelection, setClearDateSelection] = useState(false)
  const ITEMS_PER_PAGE = 8
  const MaxVehicleLimit = 51

  const location = useLocation()

  const mappedOptions = mapper.mapVehicleDataOptions(subscribedByCI);
  const alarmsTypes = getActiveAlarmAlertTypes()
  
  useEffect(() => {
    if(vehicleNumber && mappedOptions.registrationNumber.length) {
      const filtered = mappedOptions.registrationNumber?.filter(e => e?.label === vehicleNumber)     
      if(filtered.length > 0) {      
        setFilteredVehicle([{...filtered[0]}])
        setStartDate(alarmReportDate.fromDate)
        setEndDate(alarmReportDate.toDate)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleNumber, subscribedByCI])

  useEffect(() => {
    setSelectedOptions(filteredVehicle)
  }, [filteredVehicle])

  useEffect(() => {
    GetUserRoleList()
  }, [GetUserRoleList])

  useEffect(() => {
    if(mappedOptions.registrationNumber.length){
      const queryParams = new URLSearchParams(location.search);
      let selectedVehicle = mappedOptions.registrationNumber.filter(x=> x.label === queryParams.get('vehicleNumber'))
      setSelectedOptions(selectedVehicle)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedByCI, location.search])

  useEffect(() => {
    if (dataCount > 0) {
      getAllAlarms({
        registrationNumbers: selectedOptions,
        fromDate: startDate,
        toDate: endDate,
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        intervalDuration:intervalDuration,
        oemModels: selectedOemModel.map((item)=>item?.label),
        cities: selectedCity.map(item => item?.value),
        alarmTypes: selectedAlarmTypes.map((item)=>item?.value)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const calculateIntervalDuration = (fromDate, toDate) => {
    const from = new Date(fromDate)
    const to = new Date(toDate)
    const diff = Math.abs(to - from)
    const minutes = Math.floor((diff / 1000) / 60)

    //for less than 1 day return 1 hr
    if (minutes < 1440) return 120
    //for less than 3 days return 4 hrs
    else if (minutes < 4320) return 240
    //for less than 7 days return 12 hrs
    else if (minutes < 10080) return 720
    //for less than 16 days return 1 day
    else if (minutes < 23040) return 1440
    //for greater than 16 days return 2 day
    return 2880
  }

  const onDateChange = (date) => {
    setStartDate(date[0])
    setEndDate(date[1])
    setIntervalDuration(calculateIntervalDuration(startDate, endDate))
  }

  function onSelectionChange(){
    allAlarmsData.length = 0;
  }

  const onSubmitHandler = () => {
    setCurrentPage(1)
    getAllAlarms({
      registrationNumbers: selectedOptions,
      fromDate: startDate,
      toDate: endDate,
      pageIndex: 0,
      pageSize: ITEMS_PER_PAGE,
      intervalDuration:intervalDuration,
      oemModels: selectedOemModel?.map((item)=>item?.label),
      cities: selectedCity.map(item => item?.value),
      alarmTypes: selectedAlarmTypes.map((item)=>item?.value)
    })
    setTimeout(() => {
      setIsSubmitClicked(true)
    }, 1000)
  }

  function exportToExcel() {
    ExportAlarmsReport({
      registrationNumbers: selectedOptions,
      fromDate: startDate,
      toDate: endDate,
      oemModels: selectedOemModel.map((item)=>item?.label),
      cities: selectedCity.map(item => item?.value),
      alarmTypes: selectedAlarmTypes.map((item)=>item?.value)
    })
  }

  useEffect(() => {
    if(Report_Filter_Type.VEHICLE === filterType) {
      setSelectedOemModel([])
      setSelectedCity([])
    } else {
      setSelectedOptions([])
    }
    setSelectedAlarmTypes([])
    setStartDate(null)
    setEndDate(null)
    setClearDateSelection(true);
		setTimeout(() => {
			setClearDateSelection(false);
		}, 500);

  }, [filterType])

  function renderTooltipForSingleParameter(props) {
    return (
      <Tooltip className="tooltip" id="button-tooltip" {...props}>
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding:'10px' }}>
        {props.parameter1}: {props.start}
      </div>
      </Tooltip>
    )
  }

  const handleMapCoOrdinateClick = (event) => {
    setShowPopup(true);
    setCoordinate(event);
  };

  const togglePopup = () => {
    setShowPopup((prevState) => !prevState);
  }; 

  const isDisabled = () => {
    if(Report_Filter_Type.VEHICLE === filterType) {
      return !(selectedOptions !== null && selectedOptions?.length > 0 && startDate !== null && endDate !== null && selectedAlarmTypes !== null && selectedAlarmTypes?.length > 0) || selectedOptions?.length >= MaxVehicleLimit
    } else {
      return !(selectedOemModel !== null && selectedOemModel?.length > 0 && selectedCity !== null && selectedCity?.length > 0 && startDate !== null && endDate !== null && selectedAlarmTypes !== null && selectedAlarmTypes?.length > 0)
    }
  }

  return (
    <>
      <Container fluid>
      <div className='col-12'>
              <span>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"alarmFilterType"}
                  id={"alarmFilterType"}
                  checked={filterType === Report_Filter_Type.VEHICLE}
                  onChange={() => { setFilterType(Report_Filter_Type.VEHICLE); onSelectionChange(); }}
                  data-testid={`vehicle-type-radio-button`}
                />
                <text className="radio-checkbox-text-heading">
                    {Report_Filter_Type.VEHICLE}
                </text>
              </span>
              &nbsp;&nbsp;
              <span>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"alarmFilterType"}
                  id={"alarmFilterType"}
                  checked={filterType === Report_Filter_Type.OEM_MODEL_CITY}
                  onChange={() => { setFilterType(Report_Filter_Type.OEM_MODEL_CITY); onSelectionChange(); }}
                  data-testid={`oem-model-radio-button`}
                />
                <text className="radio-checkbox-text-heading">
                    {Report_Filter_Type.OEM_MODEL_CITY}
                </text>
              </span>
      </div>
        <div className="row d-flex alarmLogsFilter">
          
        <div className={`col-2 multi-select-width ${!selectedOptions[0] ? 'no-value-selected' : ''}`}
          style={{display: filterType === Report_Filter_Type.VEHICLE ? 'block': 'none'}}>
            <MultiSelect
               options={mappedOptions.registrationNumber}
               selectedOptions={selectedOptions}
               setSelectedOptions={setSelectedOptions}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               optionPosition='justify-content-start'
               menuListPaddingX='px-2'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694" 
               data-testid='vehicle-filter'
               maxOptionSelectionLimit={MaxVehicleLimit}
              />
          </div>

          <div className={`col-2 multi-select-width ${!selectedOemModel[0] ? 'no-value-selected' : ''}`}
          style={{display: filterType === Report_Filter_Type.OEM_MODEL_CITY ? 'block': 'none'}}>
             <MultiSelect
               options={mappedOptions.oemModel}
               selectedOptions={selectedOemModel}
               placeholder='OEM & Model'
               setSelectedOptions={setSelectedOemModel}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694"  
							 optionPosition='justify-content-start'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               data-testid='oem-model-filter'
               />
          </div>

          <div className={`col-2 multi-select-width ${!selectedCity[0] ? 'no-value-selected' : ''}`}
                style={{display: filterType === Report_Filter_Type.OEM_MODEL_CITY ? 'block': 'none'}}>
                  <MultiSelect
                    options={mappedOptions.location}
                    selectedOptions={selectedCity}
                    setSelectedOptions={setSelectedCity}
                    isMultiSelect={false}
                    onChange = {onSelectionChange}
                    customBackgroundColor='#EFF3F7'
                    minHeight='45px'
                    placeholderAlignment='flex-start'
                    indicatorSeparatorDisplay='none'
                    optionPosition="ps-2 justify-content-start"
                    placeholderColor='#C6C6C6'
                    valueContainerColor="#797694" 
                    data-testid='city-filter'
                    placeholder='City'
                    menuListPaddingX='px-2'
                    okButtonPaddingEnd='pe-2'
                    webkitScrollbarWidth='5px'
                    webkitScrollbarHeight='5px'
                  />
           </div>

           <div className={`col-2 multi-select-width ${!selectedOptions[0] ? 'no-value-selected' : ''}`}>
            <MultiSelect
               options={alarmsTypes}
               selectedOptions={selectedAlarmTypes}
               setSelectedOptions={setSelectedAlarmTypes}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               optionPosition="ps-2 justify-content-start"
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694" 
               data-testid='alarm-filter'
               placeholder='Alert Types'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               menuWidth='calc(100% + 50px)'
              />
          </div>

           <div className={`col-2 multi-select-width ${!startDate? 'no-value-selected' : ''}`}>
           <DatePicker
              onDateChange={(dates) => {onDateChange(dates); onSelectionChange();}}
              allowedMaxDaysInSelection={31}
              paddingLeft='0px'
              clearDateSelectionOnToggle={clearDateSelection}
              data-testid='daterange-filter'
              customBackgroundColor='#EFF3F7'
          />
           </div>

            <div className="col-1">
              <Button
                className="bttn height"
                id="getReport"
                type="submit"
                onClick={onSubmitHandler}
                disabled={isDisabled()}
                data-testid='get-report-button'
              >
                Get Report
              </Button>
            </div>

            <div className="col-1 export-btn-box">
              <button
                data-testid='export-button'
                disabled={
                  !allAlarmsData?.length
                }
                className={
                  !allAlarmsData?.length
                    ? 'dwn-btn dwn-btn-disabled'
                    : 'dwn-btn'
                }
                onClick={() => {
                  exportToExcel()
                }}
              >
                <BsDownload style={{width:'22px',height:'22px'}} />
              </button>
            </div>

          
        </div>
        {allAlarmsData?.length ? (
          <div className="bg-white mb-2 mt-4 position-relative trip__log__table">
            <Table responsive={true} borderless className="mb-0">
              <thead>
                <tr className="th-border-bottom">
                  <th className="ps-2 text-nowrap"><span className='fw500'>S.No</span></th>
                  <th className="ps-2 text-nowrap"><span className='fw500'>Vehicle No.</span></th>
                  <th className="ps-2 text-nowrap"><span className='fw500'>OEM & Model</span></th>
                  <th className="ps-2 text-nowrap"><span className='fw500'>Date & Time</span></th>
                  <th className="ps-2 text-nowrap"><span className='fw500'>Alert Type</span></th>
                  <th className="ps-2 text-nowrap"><span className='fw500'>Alert Value</span></th>
                  <th className="ps-2 text-nowrap"><span className='fw500'>GPS</span></th>
                </tr>
              </thead>
              <tbody>
                {allAlarmsData?.map((n, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {index +
                          1 +
                          ((currentPage - 1) * 10 +
                            (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                          '.'}
                      </td>
                      <td>{n?.registration_number}</td>
                      <td>{n?.oem} {n?.model}</td>
                      <td>
                        {n?.data_timestamp
                          ? getFormattedDate(n.data_timestamp)
                          : n?.data_timestamp}
                      </td>
                      <td>{n.alarmType}</td>
 


                          <td>
                        <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                        {(n.alarmValue &&  n.alarmValue !== "NA" ? n.alarmType === Alarm_AlertTypes.AuxBatteryUnderVoltage.key ? parseFloat(n.alarmValue)?.toFixed(2)  :  Math.round(n.alarmValue) : (n.alarmValue !== "NA") ? 0 : n.alarmValue) + '' + n.alarmValueUnit} 
                          
                          <OverlayTrigger        
                            placement="auto-end"
                            delay={{ show: 0, hide: 0 }}
                            overlay={renderTooltipForSingleParameter({
                              parameter1: 'Threshold Value',
                              start: n?.thresholdValue + n.alarmValueUnit ,
                            })}
                          >
                              <button className="row-btn">
                              <BiInfoCircle className="row-i-icon" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                         
                            <img
                              src="/images/svgicon/location.svg"
                              alt="location"
                              style={{
                                width: 18,
                                cursor:"pointer"
                              }}
                              onClick={()=>handleMapCoOrdinateClick({
                                lat : parseFloat(n?.gps?.split(',')[0]),
                                lng : parseFloat(n?.gps?.split(',')[1])
                              })}
                              data-testid={`gps-button-${n?.registration_number}-${index}`}
                            ></img>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Pagination
              disabled={false}
              totalPages={Math.ceil((dataCount || 0) / ITEMS_PER_PAGE)}
              currentPage={currentPage}
              setPage={setCurrentPage}
              data-testid='alert-pagination'
            />
          </div>
        ) : (
          <></>
        )}
        {!allAlarmsData?.length && isSubmitClicked && !isLoading ? (
          <NoRecordFound></NoRecordFound>
        ) : (
          <></>
        )}
      </Container>
      <Loader isLoading={isLoading} />
      {
        showPopup
        &&
        <div id="popup1" className={`popup ${showPopup ? "open" : ""}`}>
          <div className="popup-overlay" data-testid='gps-dialog-toggle' onClick={togglePopup}></div>
          <div className="popup-content">
            <MapAdddressByLocation isOpen={showPopup} toggleModal={togglePopup} CoOrdinates={coordinate} data-testid='alert-gps-dialog' />
          </div>
        </div>
      }
    </>
  )
}
AlarmReport.propTypes = {
  allAlarmsData: PropTypes.array.isRequired,
  dataCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,

  // Action props
  getAllAlarms: PropTypes.func.isRequired,
  ExportAlarmsReport: PropTypes.func.isRequired,
  subscribedByCI: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  allAlarmsData: state.alarm.allAlarmsData,
  dataCount: state.alarm.dataCount,
  isLoading: state.loader.isLoading,
  masterLocationData: state.getAllUserRoleReducer.masterLocationData,
  subscribedByCI: state.vehicle.subscribedVehiclesByCI,
})
export default connect(mapStateToProps, {
  getAllAlarms,
  ExportAlarmsReport,
  GetUserRoleList
})(AlarmReport)
