/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { MapContainer, TileLayer, FeatureGroup, useMap, Marker } from 'react-leaflet'
import L, { Icon } from 'leaflet'
import 'leaflet-draw'
import 'leaflet-geometryutil'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'

import GFPlacesAutocomplete from '../gf-places-autocomplete/GFPlacesAutocomplete'

import styles from './GFEditMap.module.css'

// Set default icon for Leaflet markers
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: require('../../../../../assets/GeoFencingIcons/shapes/pinPoint.png'),
    iconRetinaUrl: require('../../../../../assets/GeoFencingIcons/shapes/pinPoint.png'),
    iconSize: [30, 30]
});

const locationPinIcon = new Icon({
    iconUrl: require('../../../../../assets/GeoFencingIcons/shapes/locationPin.png'),
    iconRetinaUrl: require('../../../../../assets/GeoFencingIcons/shapes/locationPin.png'),
    iconSize: [35, 35]
})

const logger = (message, data) => {
    console.log(`[GFEditMap] ${message}`, data)
}

const DrawControl = ({ featureGroupRef, drawControlRef, editToolRef, controlRefs, onLayerCreation, onLayerEdition }) => {
    const map = useMap()

    useEffect(() => {
        if (!drawControlRef.current) {
            logger('features created', featureGroupRef.current, map)
            drawControlRef.current = new L.Control.Draw({
                draw: {
                    polygon: true,
                    circle: true,
                    rectangle: true,
                    marker: true,
                    polyline: false,
                    circlemarker: false,
                },
                edit: {
                    featureGroup: featureGroupRef.current,
                    edit: false,
                    remove: false,
                    poly: {
                        allowIntersection: false
                    }
                },
            })

            editToolRef.current = new L.EditToolbar.Edit(map, { featureGroup: featureGroupRef.current })

            map.addControl(drawControlRef.current)

            map.on(L.Draw.Event.CREATED, (e) => {
                featureGroupRef.current.addLayer(e.layer)
                onLayerCreation(e)
            })

            map.on(L.Draw.Event.EDITED, (e) => {
                onLayerEdition(e)
            })

            controlRefs.marker.current = new L.Draw.Marker(map)
            controlRefs.rectangle.current = new L.Draw.Rectangle(map)
            controlRefs.circle.current = new L.Draw.Circle(map)
            controlRefs.polygon.current = new L.Draw.Polygon(map)
        }

        return () => {
            if (map && drawControlRef.current) {
                map.removeControl(drawControlRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, featureGroupRef.current])

    return null
}

const GFEditMap = (props) => {
    const { mapConfig, setMapConfig, enableMap, onGeofenceChange, selectedGeofenceData } = props
    const [initialRefresh, setInitialRefresh] = useState(0)
    const [selectedShape, setSelectedShape] = useState(null)
    const [geofenceData, setGeofenceData] = useState(null)
    const [searchPlaceData, setSearchPlaceData] = useState({ address: '', latLong: {} })
    const [actionState, setActionState] = useState({
        shapeSelected: false,
        shapeCreated: false,
        markerSelected: false,
        markerPlaced: false,
        editEnabled: false,
        editSaved: false,
    })

    const mapRef = useRef(null)
    const featureGroupRef = useRef()
    const drawControlRef = useRef(null)
    const editToolRef = useRef(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const controlRefs = {
        marker: useRef(null),
        rectangle: useRef(null),
        circle: useRef(null),
        polygon: useRef(null)
    }

    useEffect(() => {
        updateGeofenceData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geofenceData])

    useEffect(() => {
        if (Object.keys(selectedGeofenceData).length && !mapRef.current) {
            setInitialRefresh((prevValue) => prevValue + 1)
        }

        console.log({ selectedGeofenceData, mapRef: mapRef.current, initialRefresh })
        if (Object.keys(selectedGeofenceData).length && mapRef.current) {
            const { type: layerType, coordinates } = selectedGeofenceData.fence_geometry
            const markerCoordinates = selectedGeofenceData.center_coordinates
            setMapConfig({ ...mapConfig, center: markerCoordinates, zoom: 12 })
            mapRef.current.setView(markerCoordinates, 12)

            featureGroupRef.current.clearLayers()

            let layer
            if (layerType === 'rectangle') {
                layer = L.rectangle(coordinates)
            } else if (layerType === 'polygon') {
                layer = L.polygon(coordinates)
            } else if (layerType === 'circle') {
                coordinates.forEach(c => {
                    layer = L.circle(c.center, { radius: c.radius })
                })   
            }

            if (layer) {
                featureGroupRef.current.addLayer(layer)
                // editToolRef.current.addLayer(layer)
                const bounds = layer.getBounds();
                mapRef.current.fitBounds(bounds); // Adjust the map view to fit the bounds
            }

            if (markerCoordinates) {
                const marker = L.marker(markerCoordinates)
                featureGroupRef.current.addLayer(marker)
                // editToolRef.current.addLayer(marker)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGeofenceData, mapRef, initialRefresh])

    const handleShapeSelect = useCallback((shape) => {
        logger('shape change', { shape, selectedShape, actionState })
        if (shape === selectedShape) {
            if (!actionState.shapeCreated) {
                controlRefs[shape.toLowerCase()].current.disable()
                setActionState({ ...actionState, shapeSelected: false })
                setSelectedShape(null)
            }
        } else {
            if (selectedShape) {
                controlRefs[selectedShape.toLowerCase()].current.disable()
            }
            if (featureGroupRef.current) {
                featureGroupRef.current.clearLayers()
            }
            controlRefs[shape.toLowerCase()].current.enable()

            setGeofenceData(null)
            setActionState({ ...actionState, shapeSelected: true })
            setSelectedShape(shape)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureGroupRef, selectedShape, actionState])

    const handleLayerCreation = useCallback((e) => {
        const { layer, layerType } = e

        if (['rectangle', 'circle', 'polygon'].includes(layerType)) {
            setGeofenceData({ layer, layerType })
            setActionState({ ...actionState, shapeCreated: true })
        }

        if (layerType === 'marker') {
            const coordinates = layer.getLatLng()
            setGeofenceData((prevState) => ({ ...prevState, markerCoordinates: coordinates }))
            setActionState({ ...actionState, markerPlaced: true, markerSelected: false })
        }

        controlRefs[layerType].current.disable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShape, controlRefs, actionState, geofenceData])

    const handleLayerEdition = useCallback((e) => {
        logger('edit saved', e)
    }, [])

    const handleMarkerClick = useCallback(() => {
        if (featureGroupRef.current && geofenceData) {
            if (actionState.markerPlaced) {
                return
            }

            if (actionState.markerSelected) {
                controlRefs.marker.current.disable()
                setActionState({ ...actionState, markerSelected: false })
                return
            }

            controlRefs.marker.current.enable()
            setActionState({ ...actionState, markerSelected: true, markerPlaced: false })
        } else {
            logger('No geofence shape created, cannot add marker');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureGroupRef, geofenceData, controlRefs])

    const handleEditClick = useCallback(() => {
        if (featureGroupRef.current && geofenceData && !actionState.editEnabled) {
            setActionState({ ...actionState, editEnabled: true })
            editToolRef.current.enable()
        } else {
            logger('No geofence shape created, cannot edit');
        }
    }, [featureGroupRef, geofenceData, editToolRef, actionState]);

    const handleEditCancelClick = useCallback(() => {
        setActionState({ ...actionState, editEnabled: false })
        editToolRef.current.revertLayers()
        editToolRef.current.disable()
    }, [actionState, editToolRef])

    const handleEditSaveClick = useCallback(() => {
        setActionState({ ...actionState, editEnabled: false, editSaved: true })
        editToolRef.current.save()
        editToolRef.current.disable()
    }, [actionState, editToolRef])

    const handleDeleteClick = useCallback(() => {
        controlRefs[selectedShape.toLowerCase()].current.disable()
        controlRefs.marker.current.disable()
        editToolRef.current.disable()

        featureGroupRef.current.clearLayers()

        setSelectedShape(null)
        setGeofenceData(null)
        setSearchPlaceData({ address: '', latLong: {} })
        setActionState({
            shapeSelected: false,
            shapeCreated: false,
            markerSelected: false,
            markerPlaced: false,
            editEnabled: false,
            editSaved: false,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureGroupRef, selectedShape, controlRefs, editToolRef])

    const handleAddressSelect = useCallback(({ address, latLong }) => {
        mapRef.current.setView([latLong.lat, latLong.lng], 18)
        setMapConfig({ ...mapConfig, zoom: 18, center: [latLong.lat, latLong.lng] })
        setSearchPlaceData({ address, latLong })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef, mapConfig])

    const calculateArea = useCallback((layer) => {
        logger('Calculating area for layer', layer)
        if (layer instanceof L.Circle) {
            const radius = layer.getRadius()
            if (typeof radius === 'number') {
                return Math.PI * radius * radius
            }
            logger('Invalid radius for circle', radius)
        } else if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
            return L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
        }
        return 0
    }, [])

    const getCoordinates = useCallback((layer) => {
        logger('Getting coordinates for layer', layer)
        if (layer instanceof L.Circle) {
            const center = layer.getLatLng()
            const radius = layer.getRadius()
            return { center: [center.lat, center.lng], radius }
        } else if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
            return layer.getLatLngs()[0].map(({ lat, lng }) => [lat, lng])
        }
        return []
    }, [])

    const updateGeofenceData = useCallback(() => {
        if (!geofenceData) {
            return onGeofenceChange(null)
        }

        const { layer, layerType, markerCoordinates } = geofenceData
        const geoJSON = layer.toGeoJSON()
        const area = calculateArea(layer)
        const coordinates = getCoordinates(layer)

        onGeofenceChange({
            layerType,
            area,
            geoJSON,
            coordinates,
            markerCoordinates,
            address: searchPlaceData.address,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculateArea, getCoordinates, onGeofenceChange, geofenceData])

    return (
        <div className={styles.createMapWrapper}>
            <MapContainer
                style={{ height: '100%', width: '100%' }}
                center={[51.505, -0.09]}
                zoom={13}
                ref={mapRef}
                {...mapConfig}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                    maxZoom={mapConfig.maxZoom}
                    maxNativeZoom={mapConfig.maxNativeZoom}
                />
                {searchPlaceData.address && (
                    <Marker
                        position={[searchPlaceData.latLong.lat, searchPlaceData.latLong.lng]}
                        icon={locationPinIcon}
                    />
                )}
                <FeatureGroup ref={featureGroupRef}>
                    <DrawControl
                        drawControlRef={drawControlRef}
                        editToolRef={editToolRef}
                        controlRefs={controlRefs}
                        featureGroupRef={featureGroupRef}
                        onLayerCreation={handleLayerCreation}
                        onLayerEdition={handleLayerEdition}
                    />
                </FeatureGroup>
            </MapContainer>

            {enableMap && (
                <div className={styles.controlsRow}>
                    <div className={styles.shapeControlsWrapper}>
                        <p>Draw fencing shape</p>
                        <div className={styles.shapeControls}>
                            <button
                                onClick={() => handleShapeSelect('Rectangle')}
                                className={cx(styles.shapeControl, { [styles.shapeControlSelected]: selectedShape === 'Rectangle' })}
                            >
                                <img src='/images/svgicon/GeofenceTabicon/fenceRectangle.svg' alt='fence rectangle' title='Rectangle Shape' />
                            </button>

                            <button
                                onClick={() => handleShapeSelect('Circle')}
                                className={cx(styles.shapeControl, { [styles.shapeControlSelected]: selectedShape === 'Circle' })}
                            >
                                <img src='/images/svgicon/GeofenceTabicon/fenceCircle.svg' alt='fence circle' title='Circle Shape' />
                            </button>

                            <button
                                onClick={() => handleShapeSelect('Polygon')}
                                className={cx(styles.shapeControl, { [styles.shapeControlSelected]: selectedShape === 'Polygon' })}
                            >
                                <img src='/images/svgicon/GeofenceTabicon/fencePolygon.svg' alt='fence polygon' title='Polygon Shape' />
                            </button>
                        </div>
                    </div>

                    <div className={styles.editControlsWrapper}>
                        <button
                            onClick={handleEditClick}
                            className={styles.editControl}
                        >
                            <img src={actionState.shapeCreated ? '/images/svgicon/GeofenceTabicon/fenceEditActive.svg' : '/images/svgicon/GeofenceTabicon/fenceEdit.svg'} alt='fence edit' title='Edit Fence' />
                        </button>

                        <button
                            onClick={handleDeleteClick}
                            className={styles.editControl}
                        >
                            <img src={actionState.shapeCreated ? '/images/svgicon/GeofenceTabicon/fenceDeleteActive.svg' : '/images/svgicon/GeofenceTabicon/fenceDelete.svg'} alt='fence delete' title='Delete Fence' />
                        </button>

                        <button
                            onClick={handleMarkerClick}
                            className={cx(styles.editControl)}
                        >
                            <img src={actionState.shapeCreated ? '/images/svgicon/GeofenceTabicon/fenceMapMarkerActive.svg' : '/images/svgicon/GeofenceTabicon/fenceMapMarker.svg'} alt='fence marker' title='Map Marker' />
                        </button>
                    </div>

                    {actionState.editEnabled && (
                        <div className={styles.editControlsWrapper}>
                            <button
                                onClick={handleEditCancelClick}
                                className={styles.editControl}
                            >
                                Cancel
                            </button>

                            <button
                                onClick={handleEditSaveClick}
                                className={styles.editControl}
                            >
                                Save
                            </button>
                        </div>
                    )}
                </div>
            )}

            {enableMap && (
                <div className={styles.searchControl}>
                    <GFPlacesAutocomplete onAddressSelect={handleAddressSelect} />
                </div>
            )}
        </div>
    )
}

GFEditMap.propTypes = {
    mapConfig: PropTypes.object.isRequired,
    enableMap: PropTypes.bool.isRequired,
    mapRef: PropTypes.object.isRequired,
    onGeofenceChange: PropTypes.func.isRequired,
}

export default GFEditMap