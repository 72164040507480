import api from "../../../service/api";
import ActionTypes from "../../actionTypes";
import API_BOOK from "../../../service/endpoints";
import { getFormattedDate, fileDownloader } from "../../../utils/common-methods";
import { customToast } from "../../../utils/toasts"
import { NotificationMessage } from "../../../utils/constants";
import {  errorToast } from '../../../utils/toasts';
const nonCommLogsActionTypes = ActionTypes.REPORTS.NON_COMM_LOGS;
const getAllNonCommLogsApiConfig = API_BOOK.REPORTS.NON_COMM_LOGS;
const getAllNonCommAnalyticsApiConfig =API_BOOK.REPORTS.NON_COMM_ANALYTICS_DAY_WISE;
const nonCommAnalyticsActionTpes = ActionTypes.REPORTS.NON_COMM_ANALYTICS_DAY_WISE;

const getAllNonCommAnalyticsSourceApiConfig =API_BOOK.REPORTS.NON_COMM_ANALYTICS_SOURCE_WISE;
const nonCommAnalyticsSourceActionTpes = ActionTypes.REPORTS.NON_COMM_ANALYTICS_SOURCE_WISE;


/* Action dispatchers starts here */ 

const getAllNonCommLogsActionDispatcher = (payload) => ({
  type: nonCommLogsActionTypes.GET_ALL_NON_COMM_LOGS_SUCCESS,
  payload,
});
const getAllNonCommReportActionDispatcher = (payload) => ({
  type: nonCommLogsActionTypes.GET_ALL_NON_COMM_REPORT_SUCCESS,
  payload,
});

const getAllNonCommAnalyticsDayWiseActionDispatcher = (payload) => ({
  
  
  type: nonCommAnalyticsActionTpes.GET_ALL_NON_COMM_ANALYTICS_DAY_WISE_SUCCEESS,
  payload,
});

const getAllNonCommAnalyticsSourceWiseActionDispatcher = (payload) => ({
  
  
  type: nonCommAnalyticsSourceActionTpes.GET_ALL_NON_COMM_ANALYTICS_SOURCE_WISE_SUCCEESS,
  payload,
});



/* Action dispatchers ends here */ 
/* Actions starts here */ 

/**
 *  @desc         Get all non communication logs api action
 *  @author       Deepak Prajapati
 *  @api          /reports/non-comm-logs
 *  @method       GET
 *  @createdDate  23-Jun-2023
 *  @modifiedDate 23-Jun-2023
 **/
export const GetAllNonCommLogs = ({ status, searchKey, registrationNumbers, fromDate, toDate, pageSize, pageIndex ,intervalDuration, oemModels, cities, dataSources, durations }) => async (dispatch) => {
  try {
    const resp = await api({
      method: getAllNonCommLogsApiConfig.GET_ALL_NON_COMM_LOGS.method,
      url: getAllNonCommLogsApiConfig.GET_ALL_NON_COMM_LOGS.url,
      data: { 
        status, 
        searchKey, 
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate, 
        toDate, 
        pageSize, 
        intervalDuration,
        pageIndex,
        oemModels,
        cities,
        dataSources,
        durations,
     }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      if (status === 'Open') {
        dispatch(getAllNonCommReportActionDispatcher(response))
      } else {
        dispatch(getAllNonCommLogsActionDispatcher(response));
      }
    }
    else{
      resp.data.error.forEach(error => {
        errorToast({ message: error.message })
    });
    }
  } catch (error) {
    console.log("error:", error);
  }
};


/**
 *  @desc         Export non communication logs report api action
 *  @author       Deepak Prajapati
 *  @api          /reports/non-comm-logs/export
 *  @method       GET
 *  @createdDate  26-Jun-2023
 *  @modifiedDate 26-Jun-2023
 **/
export const ExportNonCommLogsReport = ({ searchKey, status, registrationNumbers, fromDate, toDate, oemModels, cities, durations, dataSources }) => async (dispatch) => {
  try {
    let resp;

    if ( status === 'Open' ) {
      resp = await api({
        method: getAllNonCommLogsApiConfig.EXPORT_NON_COMM_LOGS.method,
        url: getAllNonCommLogsApiConfig.EXPORT_NON_COMM_LOGS.url,
        responseType: 'arraybuffer',
        data: { 
          searchKey,
          status,
          registrationNumbers: registrationNumbers?.map((val)=> val.label),
          fromDate, 
          toDate,
          timezoneOffset: new Date().getTimezoneOffset(),
          oemModels,
          cities,
          durations,
          dataSources,
        }
      });
    } else {
      resp = await api({
        method: getAllNonCommLogsApiConfig.EXPORT_NON_COMM_LOGS.method,
        url: getAllNonCommLogsApiConfig.EXPORT_NON_COMM_LOGS.url,
        data: { 
          searchKey,
          status,
          registrationNumbers: registrationNumbers?.map((val)=> val.label),
          fromDate, 
          toDate,
          timezoneOffset: new Date().getTimezoneOffset(),
          oemModels,
          cities
        }
      });
    }
  
    if (status === 'Open') {
      if (resp.status === 200) {
        const response = resp?.data
        fileDownloader({ arrayBuffer: response, fileName: `Non_communication_report_${getFormattedDate(new Date()).replace(/,/g, '' ).replace(':', ' ').split(' ').join('_')}.xlsx` })
      }
    } else {
      if (resp.status === 200 && resp?.data) {
        const respData = resp?.data?.data
        dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
        customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
      } else {
        customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
      }
    }
  } catch (error) {
    console.log("error:", error);
  }
};

/* Actions ends here */ 




/* Action dispatchers ends here */ 
/* Actions starts here */ 

/**
 *  @desc         Get all non communication Analytics day wise api action
 *  @author      Ankit Kushwaha
 *  @api          /reports/non-comm-analytics-day-wise
 *  @method       POST
 *  @createdDate  20-Sep-2024
 *  @modifiedDate 
 **/
export const GetAllNonAnalyticsDayWise = ({   cities,
  dataSources,
  nonCommTypes}) => async (dispatch) => {
  try {
    const resp = await api({
      method: getAllNonCommAnalyticsApiConfig.GET_NON_COMM_ANALYTICS_DAY_WISE.method,
      url: getAllNonCommAnalyticsApiConfig.GET_NON_COMM_ANALYTICS_DAY_WISE.url,
      data: { 
        cities,
        dataSources,
        nonCommTypes
     }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
        dispatch(getAllNonCommAnalyticsDayWiseActionDispatcher(response))
    }
    else{
      resp.data.error.forEach(error => {
        errorToast({ message: error.message })
    });
    }
  } catch (error) {
    console.log("error:", error);
  }
};




/**
 *  @desc         Get all non communication Analytics source wise api action
 *  @author      Ankit Kushwaha
 *  @api          /reports/non-comm-analytics-source-wise
 *  @method       POST
 *  @createdDate  20-Sep-2024
 *  @modifiedDate 
 **/
export const GetAllNonAnalyticsSourceWise = ({nonCommTypes}) => async (dispatch) => {
  try {
    const resp = await api({
      method: getAllNonCommAnalyticsSourceApiConfig.GET_NON_COMM_ANALYTICS_SOURCE_WISE.method,
      url: getAllNonCommAnalyticsSourceApiConfig.GET_NON_COMM_ANALYTICS_SOURCE_WISE.url,
      data: { 
        nonCommTypes
     }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
        dispatch(getAllNonCommAnalyticsSourceWiseActionDispatcher(response))
    }
    else{
      resp.data.error.forEach(error => {
        errorToast({ message: error.message })
    });
    }
  } catch (error) {
    console.log("error:", error);
  }
};
