
const mapper = {}

mapper.mapVehicleDataOptions = (vehicleData, takeAll = false) => {
  const options = {
    oemModel: [],
    location: [],
    registrationNumber: [],
    vehicleCategory:[],
    chargingType:[],
    customDataSource: [{
      label: 'TFT100',
      value: 'TFT100'
    }, {
      label: 'OEM API',
      value: 'OEM_API',
    }],
  }

  if (!vehicleData?.length) {
    return options
  }

  vehicleData.forEach(vehicle => {
    if (vehicle.active || takeAll) {    
      const oemModel = `${vehicle.oem} ${vehicle.model}`
      const vehicleType = vehicle?.type
      
      if (options.oemModel.findIndex(d => d.value === oemModel) === -1) {
        options.oemModel.push({ label: oemModel, value: oemModel, ...vehicle })
      }

      if (options.location.findIndex(d => d.value === vehicle.location) === -1) {
        options.location.push({ label: vehicle.location, value: vehicle.location, ...vehicle })
      }
      if(options.vehicleCategory.findIndex(d=> d.value === vehicleType )=== -1){
        options.vehicleCategory.push({label:vehicleType, value:vehicleType, ...vehicle})

      } if(options.registrationNumber.findIndex(d=> d.value === vehicle.registration_number )=== -1){
        options.registrationNumber.push({ label: vehicle.registration_number, value: vehicle._id, ...vehicle })

      }if(options.chargingType.findIndex(d=> d.value === vehicle.charging_type )=== -1){
        options.chargingType.push({label:vehicle.charging_type, value:vehicle.charging_type, ...vehicle})
      }
    }
  })

  return options
   
}

const mapLabelValue = (array) => {
  const map = {}
  array.forEach(a => map[a.value] = a.label)
  return map
}

mapper.mapGeofencesWithMasters = (geofences, masters) => {
  const fenceTypeMap = mapLabelValue(masters.FENCE_TYPE)
  const fenceOwnerNameMap = mapLabelValue(masters.FENCE_OWNER_NAME)
  const fenceOwnerTypeMap = mapLabelValue(masters.FENCE_OWNER_TYPE)
  const amenitiesMap = mapLabelValue(masters.AMENITIES)
  
  return geofences?.map(fence => {
    return {
      ...fence,
      fence_identifiers: {
        ...fence.fence_identifiers,
        fence_type_label: fenceTypeMap[fence.fence_identifiers.fence_type],
        owner_type_label: fenceOwnerTypeMap[fence.fence_identifiers.owner_type],
        owner_name_label: fenceOwnerNameMap[fence.fence_identifiers.owner_name],
      },
      amenities_label: fence.amenities.length ? fence.amenities.map(a => amenitiesMap[a]) : [],
    }
  })
}

mapper.mapGeofenceReportWithMasters = (reportsData, masters) => {
  const fenceTypeMap = mapLabelValue(masters.FENCE_TYPE)
  const fenceOwnerNameMap = mapLabelValue(masters.FENCE_OWNER_NAME)
  const fenceOwnerTypeMap = mapLabelValue(masters.FENCE_OWNER_TYPE)

  return reportsData?.map(report => {
    return {
      ...report,
      fence_type_label: fenceTypeMap[report.fence_type],
      owner_type_label: fenceOwnerTypeMap[report.owner_type],
      owner_name_label: fenceOwnerNameMap[report.owner_name],
    }
  })
}

export { mapper }