import api from "../../../service/api";
import ActionTypes from "../../actionTypes";
import API_BOOK from "../../../service/endpoints";
import { customToast } from "../../../utils/toasts";
import { NotificationMessage } from "../../../utils/constants";
import {  errorToast } from '../../../utils/toasts';
const { GET_ALL_DATA_SUCCESS, GET_ALL_DATA_FAIL, GET_ALL_IMMOBILE_SUCCESS, GET_ALL_IMMOBILE_FAIL, GET_ALL_NON_COMM_LOGS_SUCCESS, GET_ALL_NON_COMM_LOGS_FAIL, GET_ALL_ANALYTICS_DATA_FAIL, GET_ALL_ANALYTICS_DATA_SUCCESS, GET_ALL_DATA_RESET, GET_IMMOBILE_COUNT_SUCCESS, SET_IMMOBILE_TABLE_DATA, GET_NON_COMM_COUNT_SUCCESS, SET_NON_COMM_TABLE_DATA ,  SET_ALARM_TABLE_DATA} = ActionTypes.REPORTS.ALARM;
const { GET_ALL_ALARMS, EXPORT_ALARMS_DATA, GET_ALL_IMMOBILE, GET_ALL_NON_COMM_LOGS, GET_CURRENT_IMMOBILE, GET_CURRENT_NON_COMM_LOGS, GET_ALL_ANALYTICS_ALARMS, GET_ALL_IMMOBILE_COUNT, GET_ALL_NON_COMM_COUNT, GET_NON_COMM_LIST, GET_ALL_ALARMS_BY_TYPE, EXPORT_PARKED_VEHICLES_FOR_DURATION, EXPORT_NON_COMM_LOGS_FOR_DURATION} = API_BOOK.REPORTS.ALARM;


/* Action dispatchers (AD) starts here */ 

const getAllAlarmsSuccessAD = (payload) => ({
  type: GET_ALL_DATA_SUCCESS,
  payload,
});

const getAllAlarmsFailAD = () => ({
  type: GET_ALL_DATA_FAIL
});

export const getAllAlarmReset = () => ({
  type: GET_ALL_DATA_RESET
})

const getAllImmobileSuccessAD = (payload) => ({
  type: GET_ALL_IMMOBILE_SUCCESS,
  payload,
});

const getAllImmobileFailAD = () => ({
  type: GET_ALL_IMMOBILE_FAIL
});

const getAllNonCommLogsSuccessAD = (payload) => ({
  type: GET_ALL_NON_COMM_LOGS_SUCCESS,
  payload,
});

const getAllNonCommLogsFailAD = () => ({
  type: GET_ALL_NON_COMM_LOGS_FAIL
});

const getAllAnalyticsAlarmsSuccessAD = (payload) => ({
  type: GET_ALL_ANALYTICS_DATA_SUCCESS,
  payload,
});

const getAllAnalyticsAlarmsFailAD = () => ({
  type: GET_ALL_ANALYTICS_DATA_FAIL
});

const setImmobileTableData = (payload) => ({
  type: SET_IMMOBILE_TABLE_DATA,
  payload
})

const setNonCommTableData = (payload) => ({
  type: SET_NON_COMM_TABLE_DATA,
  payload
})

/* Action dispatchers ends here */ 
/* Actions starts here */ 

/**
 *  @desc         Get all alarms api action
 *  @author       Deepak Prajapati
 *  @api          /reports/alarm
 *  @method       POST
 *  @createdDate  17-Jul-2023
 *  @modifiedDate 17-Jul-2023
 **/
export const getAllAlarms = ({ registrationNumbers, alarmType, fromDate, toDate, pageSize, pageIndex, intervalDuration, oemModels, cities, alarmTypes }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_ALARMS.method,
      url: GET_ALL_ALARMS.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        pageSize,
        pageIndex,
        intervalDuration,
        alarmType,
        oemModels,
        cities,
        alarmTypes
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllAlarmsSuccessAD(response));
    } else {
      resp.data.error.forEach(error => {
        errorToast({ message: error.message })
    });
      dispatch(getAllAlarmsFailAD());
    }
  } catch (error) {
    dispatch(getAllAlarmsFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         Get alarms filtered by alarm type api action
 *  @author       Kailash Desiti
 *  @api          /reports/alarm/by-type
 *  @method       POST
 *  @createdDate  28-Dec-2023
 *  @modifiedDate 28-Dec-2023
 **/
export const getAlarmTableDataForType = ({ alarmType, fromDate, toDate, pageSize, pageIndex }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_ALARMS_BY_TYPE.method,
      url: GET_ALL_ALARMS_BY_TYPE.url,
      data: {
        fromDate,
        toDate,
        pageSize,
        pageIndex,
        alarmType,
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch({type: SET_ALARM_TABLE_DATA, payload: response});
    }
  } catch (error) {
    dispatch(getAllAlarmsFailAD());
    console.log("error:", error);
  }
}


/**
 *  @desc         Export alarms data report api action
 *  @author       Deepak Prajapati
 *  @api          /reports/alarm/export
 *  @method       POST
 *  @createdDate  17-Jul-2023
 *  @modifiedDate 17-Jul-2023
 **/
export const ExportAlarmsReport = ({ registrationNumbers, fromDate, toDate, oemModels, cities, alarmType, alarmTypes }) => async (dispatch) => {
  try {
    const resp = await api({
      method: EXPORT_ALARMS_DATA.method,
      url: EXPORT_ALARMS_DATA.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        timezoneOffset: new Date().getTimezoneOffset(),
        oemModels,
        cities,
        alarmType,
        alarmTypes
       }
    });
  
    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    console.log("error:", error);
  }
};

/**
 *  @desc         Export alarms filtered by alarm type api action
 *  @author       Kailash Desiti
 *  @api          /reports/alarm/export
 *  @method       POST
 *  @createdDate  28-Dec-2023
 *  @modifiedDate 28-Dec-2023
 **/
export const exportAlarmsReportByType = () => async (dispatch, getState) => {
  try {
    const resp = await api({
      method: EXPORT_ALARMS_DATA.method,
      url: EXPORT_ALARMS_DATA.url,
      data: {
        alarmType: getState().alarm.alarmTableData.alertType,
        fromDate: getState().alarm.selectedFromDate,
        toDate: getState().alarm.selectedToDate,
        timezoneOffset: new Date().getTimezoneOffset()
       }
    });
  
    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    console.log("error:", error);
  }
};

/**
 *  @desc         Export parked sessions for selected duration api action
 *  @author       Kailash Desiti
 *  @api          /reports/current-immobile/export
 *  @method       POST
 *  @createdDate  28-Dec-2023
 *  @modifiedDate 28-Dec-2023
 **/
export const exportParkedVehiclesForDuration = () => async (dispatch, getState) => {
  try {
    const resp = await api({
      method: EXPORT_PARKED_VEHICLES_FOR_DURATION.method,
      url: EXPORT_PARKED_VEHICLES_FOR_DURATION.url,
      data: {
        fromDate: getState().alarm.selectedFromDate,
        toDate: getState().alarm.selectedToDate,
        durationInterval: getState().alarm.immobileDataTable.durationInterval,
        showCurrentSessionOnly: getState().alarm.showCurrentImmobileData,
       }
    });
  
    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    console.log("error:", error);
  }
}

/**
 *  @desc         Export non communicating sessions for selected duration api action
 *  @author       Kailash Desiti
 *  @api          /reports/non-comm-logs/by-duration/export
 *  @method       POST
 *  @createdDate  28-Dec-2023
 *  @modifiedDate 28-Dec-2023
 **/
export const exportNonCommLogsForDuration = () => async (dispatch, getState) => {
  try {
    const resp = await api({
      method: EXPORT_NON_COMM_LOGS_FOR_DURATION.method,
      url: EXPORT_NON_COMM_LOGS_FOR_DURATION.url,
      data: {
        fromDate: getState().alarm.selectedFromDate,
        toDate: getState().alarm.selectedToDate,
        durationInterval: getState().alarm.nonCommDataTable.durationInterval,
        showCurrentSessionOnly: getState().alarm.showCurrentNonCommLogsData,
       }
    });
  
    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    console.log("error:", error);
  }
}

/**
 *  @desc         Get all parked vehicles
 *  @author       Mukesh Fulwariya
 *  @api          /reports/allImmobile
 *  @method       POST
 *  @createdDate  21-SEPT-2023
 *  @modifiedDate 21-SEPT-2023
 **/
export const getAllImmobile = ({fromDate, toDate, showCurrentSessionOnly }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_IMMOBILE_COUNT.method,
      url: GET_ALL_IMMOBILE_COUNT.url,
      data: {
        fromDate,
        toDate,
        showCurrentSessionOnly
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch({type: GET_IMMOBILE_COUNT_SUCCESS, payload: response})
      // dispatch(getAllImmobileSuccessAD(response));
    } else {
      dispatch(getAllImmobileFailAD());
    }
  } catch (error) {
    dispatch(getAllImmobileFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         Get all parked vehicles table data for selected duration api action
 *  @author       Kailash Desiti
 *  @api          /reports/current-immobile/list
 *  @method       POST
 *  @createdDate  28-Dec-2023
 *  @modifiedDate 28-Dec-2023
 **/
export const fetchImmobilieListTableData = ({ durationInterval, fromDate, toDate, pageIndex, pageSize, showCurrentSessionOnly }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_IMMOBILE.method,
      url: GET_ALL_IMMOBILE.url,
      data: {
        durationInterval,
        fromDate,
        toDate,
        pageIndex,
        pageSize,
        showCurrentSessionOnly
       }
    });
  

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(setImmobileTableData(response));
    } else {
      dispatch(getAllImmobileFailAD());
    }
  } catch (error) {
    dispatch(getAllImmobileFailAD());
    console.log("error:", error);
  }
}


/**
 *  @desc         Get all parked vehicles table data for selected duration api action
 *  @author       Kailash Desiti
 *  @api          /reports/non-comm-logs/list
 *  @method       POST
 *  @createdDate  28-Dec-2023
 *  @modifiedDate 28-Dec-2023
 **/
export const fetchNonCommListTableData = ({ durationInterval, fromDate, toDate, pageIndex, pageSize, showCurrentSessionOnly }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_NON_COMM_LIST.method,
      url: GET_NON_COMM_LIST.url,
      data: {
        durationInterval,
        fromDate,
        toDate,
        pageIndex,
        pageSize,
        showCurrentSessionOnly
       }
    });

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(setNonCommTableData(response));
    } else {
      dispatch(getAllImmobileFailAD());
    }
  } catch (error) {
    dispatch(getAllImmobileFailAD());
    console.log("error:", error);
  }
}

/**
 *  @desc         Get all parked vehicles
 *  @author       Mukesh Fulwariya
 *  @api          /reports/allImmobile
 *  @method       POST
 *  @createdDate  21-SEPT-2023
 *  @modifiedDate 21-SEPT-2023
 **/
export const getCurrentImmobileData = () => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_CURRENT_IMMOBILE.method,
      url: GET_CURRENT_IMMOBILE.url
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllImmobileSuccessAD(response));
    } else {
      dispatch(getAllImmobileFailAD());
    }
  } catch (error) {
    dispatch(getAllImmobileFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         Get all parked vehicles
 *  @author       Mukesh Fulwariya
 *  @api          /reports/all-non-comm-logs
 *  @method       POST
 *  @createdDate  21-SEPT-2023
 *  @modifiedDate 21-SEPT-2023
 **/
export const getAllNonCommLogs = ({fromDate, toDate }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_NON_COMM_LOGS.method,
      url: GET_ALL_NON_COMM_LOGS.url,
      data: {
        fromDate,
        toDate
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllNonCommLogsSuccessAD(response));
    } else {
      dispatch(getAllNonCommLogsFailAD());
    }
  } catch (error) {
    dispatch(getAllNonCommLogsFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         Get all non coomunicating sessions count api action
 *  @author       Kailash Desiti
 *  @api          /reports/non-comm-logs/count
 *  @method       POST
 *  @createdDate  28-Dec-2023
 *  @modifiedDate 28-Dec-2023
 **/
export const getAllNonCommSessionsCount = ({fromDate, toDate, showCurrentSessionOnly }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_NON_COMM_COUNT.method,
      url: GET_ALL_NON_COMM_COUNT.url,
      data: {
        fromDate,
        toDate,
        showCurrentSessionOnly
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch({type: GET_NON_COMM_COUNT_SUCCESS, payload: response})
      // dispatch(getAllImmobileSuccessAD(response));
    } else {
      dispatch(getAllImmobileFailAD());
    }
  } catch (error) {
    dispatch(getAllImmobileFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         Get all parked vehicles
 *  @author       Mukesh Fulwariya
 *  @api          /reports/all-non-comm-logs
 *  @method       POST
 *  @createdDate  21-SEPT-2023
 *  @modifiedDate 21-SEPT-2023
 **/
export const getCurrentNonCommData = () => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_CURRENT_NON_COMM_LOGS.method,
      url: GET_CURRENT_NON_COMM_LOGS.url
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllNonCommLogsSuccessAD(response));
    } else {
      dispatch(getAllNonCommLogsFailAD());
    }
  } catch (error) {
    dispatch(getAllNonCommLogsFailAD());
  }
};

/**
 *  @desc         Get all parked vehicles
 *  @author       Mukesh Fulwariya
 *  @api          /reports/allAlarms
 *  @method       POST
 *  @createdDate  21-SEPT-2023
 *  @modifiedDate 21-SEPT-2023
 **/
export const getAllAnalyticsAlarms = ({fromDate, toDate }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_ANALYTICS_ALARMS.method,
      url: GET_ALL_ANALYTICS_ALARMS.url,
      data: {
        fromDate,
        toDate
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data?.rows
      dispatch(getAllAnalyticsAlarmsSuccessAD(response));
    } else {
      dispatch(getAllAnalyticsAlarmsFailAD());
    }
  } catch (error) {
    dispatch(getAllAnalyticsAlarmsFailAD());
  }
};

/* Actions ends here */ 