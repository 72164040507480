import React, { useRef, useState, useEffect } from "react";
import withGeofenceHOC from "../geofenceHOC";
import GFCreateFenceForm from "./gf-create-fence-form/GFCreateFenceForm";
import GFConfigureAlertForm from "./gf-configure-alert-form/GFConfigureAlertForm";
import GFCreateMap from "./gf-create-map/GFCreateMap";
import { Tab, Tabs } from "react-bootstrap";
import styles from './GFCreate.module.css';
import { fetchGroupList } from "../../../../store/actions/groups";
import geofenceAction from "../../../../store/actions/geofence";
import { useNavigate } from "react-router-dom";

const defaultMapConfig = {
    center: [20.5937, 78.9629],
    zoom: 5,
    attributionControl: false,
    maxZoom: 22,
    maxNativeZoom: 22,
    minZoom: 5,
    zoomControl: false,
    enableMarkerCluster: true,
    zoomControlPosition: "topright",
};

const GFCreate = (props) => {
    const { mappedOptionsByVehicle, refreshGeofences, masters } = props;
    const mapRef = useRef(null);
    const [mapConfig, setMapConfig] = useState(defaultMapConfig);
    const [activeTab, setActiveTab] = useState("CREATE_FENCE");
    const [enableMap, setEnableMap] = useState(false);
    const [fenceLocation, setFenceLocation] = useState(null);
    const createdFenceId = null
    const [userList, setUserList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        // Fetch geofences users
        const fetchGeofencesUsers = async () => {
            try {
                const {
                    data: { data: users },
                } = await geofenceAction.getUsers();

                setUserList(users);
            } catch (error) {
                console.error("Error fetching geofences:", error);
            }
        };
        fetchGeofencesUsers();

        const fetchGroupsList = async () => {
            const userId = JSON.parse(localStorage.getItem("user"))?._id;
            if (userId) {
                const result = await fetchGroupList(userId);
                setGroupList(result);
            }
        };
        fetchGroupsList();
    }, []);

    const handleTabSelect = (tabKey) => {
        if (tabKey === 'CREATE_FENCE' && createdFenceId) {
            return
        }
        setActiveTab(tabKey);
    };

    const handleCreateFenceId = (newFenceId) => {
        navigate(`/geo-fence/edit/${newFenceId}?action=CONFIGURE_ALERT`, { replace: true })
    };

    const handleFenceDetailsSubmit = (formData) => {
        setEnableMap(true)
        const selectedLocationData = masters.LOCATION.find(l => l.value === formData.city.value)
        
        if (mapRef?.current && selectedLocationData) {
            mapRef.current.setView([selectedLocationData.meta.lat, selectedLocationData.meta.long], selectedLocationData.meta.zoom)
            setMapConfig({ ...mapConfig, zoom: selectedLocationData.meta.zoom, center: [selectedLocationData.meta.lat, selectedLocationData.meta.long] })
        }
    }

    const usersMapped = userList.map((u) => ({ label: u.name, value: u.email }));

    return (
        <div className={styles.container}>
            <div className={styles.formWrapper}>
                <div className={styles.formContainer}>
                    <div className={styles.tabsBorder}></div>
                    <Tabs
                        id="geo-fence-create-tabs"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className={styles.alertNavLink}
                    >
                        <Tab
                            eventKey="CREATE_FENCE"
                            title={
                                <span className={`${styles.tabLink} ${activeTab === "CREATE_FENCE" ? styles.activeTab : ""}`}>
                                    Create Fence
                                </span>
                            }
                            className={styles.tabPanel}
                        >
                            {activeTab === "CREATE_FENCE" && (
                                <GFCreateFenceForm
                                    masterData={props?.masters}
                                    cityOptions={mappedOptionsByVehicle.location}
                                    fenceLocationData={fenceLocation}
                                    setEnableMap={setEnableMap}
                                    onFenceDetailsSubmit={handleFenceDetailsSubmit}
                                    setCreateFenceId={handleCreateFenceId}
                                    refreshGeofences={refreshGeofences}
                                />
                            )}
                        </Tab>
                        <Tab
                            eventKey="ADD_ALERT"
                            title={
                                <span
                                    className={`${styles.tabLink} ${activeTab === "ADD_ALERT" ? styles.activeTab : ""} ${createdFenceId ? "" : styles.disabledTab}`}
                                >
                                    Configure Alert
                                </span>
                            }
                            className={`${styles.tabPanel} ${createdFenceId ? "" : styles.disabledTabPanel}`}
                            disabled={!createdFenceId}
                        >
                            {activeTab === "ADD_ALERT" && createdFenceId && (
                                <GFConfigureAlertForm
                                    createdFenceId={createdFenceId}
                                    mappedOptionsByVehicle={mappedOptionsByVehicle}
                                    userList={usersMapped}
                                    groupList={groupList}
                                    refreshGeofences={refreshGeofences}
                                />
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <div className={styles.mapWrapper}>
                {!enableMap && <div className={styles.mapWrapperDisabled} />}
                <GFCreateMap
                    enableMap={enableMap}
                    mapConfig={mapConfig}
                    setMapConfig={setMapConfig}
                    mapRef={mapRef}
                    onGeofenceChange={setFenceLocation}
                />
            </div>
        </div>
    );
};

export default withGeofenceHOC(GFCreate);
