import React, { useEffect, useState } from "react";
import styles from "./AssignVehicleModel.module.css";
import { FaSearch } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import * as FileSaver from "file-saver";
import Pagination from "../../../../../helpers/hoc/paginator/Pagination";
import GeofenceBulkMappingTemplate from '../../../../../../assets/templates/Geofence_Bulk_Mapping_Template.xlsx'
import MultiSelect from "../../../../../helpers/hoc/mult-select/MultiSelect";

const GFAssignVehicleManually = ({
  onClose,
  handleNextClick,
  vehicleList,
  oemModel,
  cityOptions,
  alertDetails,
  onUploadFileClick
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedOEM, setSelectedOEM] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);

  const ITEMS_PER_PAGE = 8;

  const [vehicles, setVehicles] = useState(
    vehicleList.map((vehicle) => ({
      ...vehicle,
      isSelected: false,
    }))
  );

  useEffect(() => {
    if (alertDetails.vehicle && alertDetails.vehicle.length) {
      setVehicles(vehicleList.filter(v => !alertDetails.vehicle.includes(v.registration_number)).map((vehicle) => ({
        ...vehicle,
        isSelected: false,
      })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertDetails])

  const filteredData = vehicles.sort(item => item.isSelected ? -1 : 1).filter((item) => {
    const matchesSearchText = item.registration_number
      ?.toLowerCase()
      .includes(searchText?.toLowerCase());
    const matchesOEM = selectedOEM.length
      ? selectedOEM.some(v => `${item.oem} ${item.model}` === v.value)
      : true;
    const matchesCity = selectedCity.length
      ? selectedCity.some(v => item.location === v.value) 
      : true;
    return matchesSearchText && matchesOEM && matchesCity;
  });

  const manualVehicleData = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const isNextButtonEnabled = vehicles.some(
    (vehicle) => vehicle.isSelected
  );

  const downloadOnboardingTemplate = () => {
    FileSaver.saveAs(
      GeofenceBulkMappingTemplate,
      'Geofence_Bulk_Mapping_Template.xlsx'
    )
  }

  const handlePageChange = (page) => setCurrentPage(page);

  const handleSelectAll = () => {
    const isCurrentlySelected = filteredData.every(vehicle => vehicle.isSelected);
    const updatedVehicles = vehicles.map((vehicle) => {

      const isInFilteredData = filteredData.some(
        (filteredVehicle) => filteredVehicle.registration_number === vehicle.registration_number
      );

      return isInFilteredData
        ? { ...vehicle, isSelected: !isCurrentlySelected }
        : vehicle;
    });

    setVehicles(updatedVehicles);
    setSelectAll(!isCurrentlySelected);
  };

  const handleVehicleSelect = (index) => {
    const updatedVehicles = [...manualVehicleData];
    updatedVehicles[index].isSelected = !updatedVehicles[index].isSelected;

    setVehicles(
      vehicles.map((vehicle) =>
        vehicle.registration_number === updatedVehicles[index].registration_number
          ? { ...vehicle, isSelected: updatedVehicles[index].isSelected }
          : vehicle
      )
    );

    // Reset selectAll if any vehicle is deselected
    if (updatedVehicles[index].isSelected === false) {
      setSelectAll(false);
    }
  };

  const handleSubmit = () => {
    const selectedVehicles = vehicles
      .filter((vehicle) => vehicle.isSelected)
      .map((vehicle) => vehicle.registration_number);
    handleNextClick(selectedVehicles);
  };

  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.closeBtn} onClick={onClose}>
            <img src="/images/geofenceModuleIcon/closeBtn.svg" alt="close" />
          </div>

          <div className={styles.manualVehicleContainer}>
            <div className={styles.manualVehicleCard}>
              <h3 className={styles.vechicleCardTitle}>Add Vehicles</h3>
            </div>

            <div className={styles.filterHeaderBox}>
              <div className={styles.searchContainerGeofence}>
                <FaSearch className={styles.vobSearchIcon} />
                <input
                  type="text"
                  className={styles.vobSearchInput}
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                    handlePageChange(1)
                  }}
                />
              </div>
              <div>
                <MultiSelect
                  valueContainerWidthInPx="200px"
                  valueContainerMaxWidthInPx="200px"
                  menuListPaddingX="px-2"
                  okButtonPaddingEnd="pe-2"
                  placeholderAlignment='flex-start'
                  optionPosition="ps-2 justify-content-start"
                  placeholder="Select OEM and Model"
                  placeholderColor='#1F2A5D'
                  options={oemModel}
                  selectedOptions={selectedOEM}
                  isMultiSelect={true}
                  indicatorSeparatorDisplay="none"
                  minHeight="45px"
                  valueContainerColor="#1F2A5D"
                  customBackgroundColor='#EFF3F7'
                  setSelectedOptions={(options) => {
                    setSelectedOEM(options)
                    handlePageChange(1)
                  }}
                />
              </div>
              <div>
                <MultiSelect
                  valueContainerWidthInPx="200px"
                  valueContainerMaxWidthInPx="200px"
                  menuListPaddingX="px-2"
                  okButtonPaddingEnd="pe-2"
                  placeholderAlignment='flex-start'
                  optionPosition="ps-2 justify-content-start"
                  placeholder="Select City"
                  placeholderColor='#1F2A5D'
                  options={cityOptions}
                  selectedOptions={selectedCity}
                  isMultiSelect={true}
                  indicatorSeparatorDisplay="none"
                  minHeight="45px"
                  valueContainerColor="#1F2A5D"
                  customBackgroundColor='#EFF3F7'
                  setSelectedOptions={(options) => {
                    setSelectedCity(options)
                    handlePageChange(1)
                  }}
                />
              </div>

              <div className="d-flex flex-row justify-content-end ">
                <div className="me-2">
                  <Dropdown className="bulk-upload-dropdown">
                    <Dropdown.Toggle className="vob-btns" id="dropdown-basic">
                      Bulk Upload
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        style={{ color: "#22046B" }}
                        onClick={downloadOnboardingTemplate}
                      >
                        Download Template
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: "#22046B" }}
                        onClick={onUploadFileClick}
                      >
                        Upload File
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <table className={styles.manualVehicleTable}>
              <thead className={styles.groupDataTable}>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className={styles.checkedBoxColor}
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>Vehicle No.</th>
                  <th>City</th>
                  <th>OEM & Model</th>
                </tr>
              </thead>
              <tbody>
                {manualVehicleData.length > 0 ? (
                  manualVehicleData.map((item, index) => (
                    <tr key={`manual_${item._id}`} className={styles.AssignVehicltableList}>
                      <td>
                        <input
                          className={styles.checkedBox}
                          type="checkbox"
                          checked={item.isSelected}
                          onChange={() => handleVehicleSelect(index)}
                        />
                      </td>
                      <td>{item.registration_number}</td>
                      <td>{item.location}</td>
                      <td>
                        {item.oem} {item.model}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <div className='no-record-found-label d-flex flex-row justify-content-center align-items-center'>
                      <span className='no-data-text'>No data found</span>
                    </div>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              <Pagination
                disabled={false}
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={handlePageChange}
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className={styles.assignVehicleSaveButton}
              style={{
                backgroundColor: isNextButtonEnabled ? "#22046B" : "#9E9E9E",
                cursor: isNextButtonEnabled ? "pointer" : "not-allowed",
              }}
              disabled={!isNextButtonEnabled}
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GFAssignVehicleManually;
