import React, { useState, useEffect } from "react";
import SingleSelect from "../../../../helpers/hoc/single-select/SingleSelect";
import styles from "./GFEditFenceForm.module.css";
import GFCreateSuccessModel from "../gf-configure-alert-form/modals/GFCreateSuccessModel";

const decimalToDMS = (decimal) => {
  const degrees = Math.floor(decimal)
  const minutesFloat = (decimal - degrees) * 60
  const minutes = Math.floor(minutesFloat)
  const seconds = (minutesFloat - minutes) * 60

  return `${degrees}° ${minutes}' ${seconds.toFixed(2)}"`
}

const populateCoordinates = (coordinates) => {
  if (!coordinates || coordinates.length !== 2) {
    return '-';
  }
  const lat = coordinates[0];
  const lng = coordinates[1];
  return `${decimalToDMS(Math.abs(lat)) + (lat >= 0 ? ' N' : ' S')}, ${decimalToDMS(Math.abs(lng)) + (lng >= 0 ? ' E' : ' W')}`;
};


const GFEditFenceForm = ({ masterData, cityOptions, selectedGeofenceData}) => {
  const [openSection, setOpenSection] = useState([]);
  const [isGFCreateSuccessModelOpen, setIsGFCreateSuccessModelOpen] = useState(false);

  const [formData, setFormData] = useState({
    city: null,
    fenceName: "",
    fenceType: null,
    fenceOwnerType: null,
    fenceOwnerName: null,
    amenities: []
  });


  useEffect(() => {
    if (selectedGeofenceData) {
      const { fence_name, fence_identifiers, amenities } = selectedGeofenceData;
      const cityOption = cityOptions.find(option => option.value === fence_identifiers?.city);
      const fenceTypeOption = masterData.FENCE_TYPE.find(option => option.value === fence_identifiers?.fence_type);
      const fenceOwnerTypeOption = masterData.FENCE_OWNER_TYPE.find(option => option.value === fence_identifiers?.owner_type);
      const fenceOwnerNameOption = masterData.FENCE_OWNER_NAME.find(option => option.value === fence_identifiers?.owner_name);

      setFormData({
        city: cityOption || null,
        fenceName: fence_name || "",
        fenceType: fenceTypeOption || null,
        fenceOwnerType: fenceOwnerTypeOption || null,
        fenceOwnerName: fenceOwnerNameOption || null,
        amenities: amenities || []
      });
    }
  }, [selectedGeofenceData, cityOptions, masterData]);

  const isDetailsComplete = () => {
    const { city, fenceName, fenceType, fenceOwnerType, fenceOwnerName } = formData;
    if (!city || !fenceName ) {
      return false;
    }

    const isFenceTypeValid = fenceType && fenceType.value;
    const isFenceOwnerTypeValid = fenceOwnerType && fenceOwnerType.value;
    const isFenceOwnerNameValid = fenceOwnerName && fenceOwnerName.value;

    return isFenceTypeValid && isFenceOwnerTypeValid && isFenceOwnerNameValid;
  };

  const toggleAccordion = (section) => {
    if (section === "location" && !isDetailsComplete()) {
      alert("Please complete the Fence Details section before proceeding.");
      return;
    }
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };


  const GFCreateSuccessModelClose = () => {
    setIsGFCreateSuccessModelOpen(false);
  };

  const AMENITIES_ICONS = {
    CHARGING: "/images/geofenceModuleIcon/chargingIcon.svg",
    PARKING: "/images/geofenceModuleIcon/parkingIcon.svg",
    SERVICING: "/images/geofenceModuleIcon/servicingIcon.svg",
    STORAGE: "/images/geofenceModuleIcon/storageIcon.svg",
    HIRING_DESK: "/images/geofenceModuleIcon/hiringDeskIcon.svg",
    LOADING_UNLOADING: "/images/geofenceModuleIcon/loadingUnload.svg",
  };

  const FENCE_OWNER_TYPE = masterData.FENCE_OWNER_TYPE.filter(f => {
    if (!formData.fenceType) {
      return false
    }

    return f.deps.includes(`FENCE_TYPE:${formData.fenceType.value}`)
  })

  const FENCE_OWNER_NAME = masterData.FENCE_OWNER_NAME.filter(f => {
    if (!formData.fenceOwnerType) {
      return false
    }

    return f.deps.includes(`FENCE_TYPE:${formData.fenceType.value}&&FENCE_OWNER_TYPE:${formData.fenceOwnerType.value}`)
  })

  return (
    <div className={styles.accordionContainer}>
      {/* Fence Details Section */}
      <div className={styles.accordionSection}>
        <div
          className={styles.accordionHeader}
          onClick={() => toggleAccordion("details")}
        >
          <h3 className={styles.accordionHeadTitle}>Fence Details</h3>
          <span>
            <img
              src={
                openSection === "details"
                  ? "/images/geofenceModuleIcon/upArrow.svg"
                  : "/images/geofenceModuleIcon/downArrow.svg"
              }
              alt="toggle icon"
            />
          </span>
        </div>
        {openSection === "details" && (
          <div className={styles.accordionContent}>
            {/* City Select */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>City</label>
              <span>:</span>
              <SingleSelect
                placeholder="Select"
                placeholderOnError=""
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="166px"
                valueContainerMaxWidthInPx="166px"
                optionPosition="justify-content-start"
                maxMenuHeight={140}
                customBackgroundColor="#E7ECF7"
                data-testid="fence-labelowner-select"
                options={cityOptions}
                selectedOptions={formData.city}
                onSelectHandler={(data) => {
                  // handleInputChange("city", data)
                  console.log({ city: data })
                }}
              />
            </div>

            {/* Fence Name Input */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>Fence Name</label>
              <span>:</span>
              <input
                type="text"
                placeholder="Enter"
                value={formData.fenceName}
                onChange={(e) => {
                  // handleInputChange("fenceName", e.target.value)
                  console.log({ fenceName: e.target.value })
                }}
              />
            </div>


       

            {/* Fence Type Select */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>Fence Type</label>
              <span>:</span>
              <SingleSelect
                placeholder="Select"
                placeholderOnError=""
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="166px"
                valueContainerMaxWidthInPx="166px"
                optionPosition="justify-content-start"
                maxMenuHeight={140}
                customBackgroundColor="#E7ECF7"
                data-testid="fence-labelowner-select"
                options={masterData.FENCE_TYPE}
                selectedOptions={formData.fenceType}
                onSelectHandler={(selectedOption) => {
                  // handleInputChange("fenceType", selectedOption)
                  console.log({ fenceType: selectedOption })
                }}
              />
            </div>

            {/* Fence Owner Type Select */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>Fence Owner Type</label>
              <span>:</span>
              <SingleSelect
                placeholder="Select"
                placeholderOnError=""
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="166px"
                valueContainerMaxWidthInPx="166px"
                optionPosition="justify-content-start"
                maxMenuHeight={140}
                customBackgroundColor="#E7ECF7"
                data-testid="fence-labelowner-select"
                options={FENCE_OWNER_TYPE}
                selectedOptions={formData.fenceOwnerType}
                onSelectHandler={(selectedOption) => {
                  // handleInputChange("fenceOwnerType", selectedOption)
                  console.log({ fenceOwnerType: selectedOption })
                }}
              />
            </div>

            {/* Fence Owner Name Select */}
            <div className={styles.inputGroup}>
              <label className={styles.fenceDetailsLabel}>Fence Owner Name</label>
              <span>:</span>
              <SingleSelect
                placeholder="Select"
                placeholderOnError=""
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="166px"
                valueContainerMaxWidthInPx="166px"
                optionPosition="justify-content-start"
                maxMenuHeight={140}
                selectedValueMarginLeft="8px"
                customBackgroundColor="#E7ECF7"
                data-testid="fence-labelowner-select"
                options={FENCE_OWNER_NAME}
                selectedOptions={formData.fenceOwnerName}
                onSelectHandler={(selectedOption) =>
                  // handleInputChange("fenceOwnerName", selectedOption)
                  console.log({ fenceOwnerName: selectedOption })
                }
              />
            </div>

            {/* Amenities Section */}
            <div className={styles.amenities}>
              <h4 className={`${styles.accordionHeadTitle} ${styles.amenitiesTitle}`}>Amenities</h4>
              <div className={styles.amenitiesCheckbox}>
                {masterData.AMENITIES.map((amenity) => (
                  <div className={styles.amenitiesIconContainer} key={amenity.value}>
                    <input
                      type="checkbox"
                      checked={formData.amenities.includes(amenity.value)}
                      onChange={() => {
                        console.log({ amenity: amenity.value })
                        // handleCheckboxChange(amenity.value)
                      }}
                    />
                    <img
                      src={AMENITIES_ICONS[amenity.value]}
                      alt={`${amenity.label} icon`}
                    />
                    <p>{amenity.label}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* <div className={styles.saveBtnBox}>
              <button
                className={` ${!isDetailsComplete() ? styles.saveBtnDisabled : styles.saveBtn}`}
                onClick={() => {
                  setOpenSection('location')
                  setEnableMap(true)
                }}
                disabled={!isDetailsComplete()}
              >
                Save
              </button>
            </div> */}
          </div>
        )}
      </div>

      {/* Fence Location Section */}
      <div className={styles.accordionSection}>
        <div
          className={styles.accordionHeader}
          onClick={() => toggleAccordion("location")}
        >
          <h3 className={styles.accordionHeadTitle}>Fence Location</h3>
          <span>
            <img
              src={
                openSection === "location"
                  ? "/images/geofenceModuleIcon/upArrow.svg"
                  : "/images/geofenceModuleIcon/downArrow.svg"
              }
              alt="toggle icon"
            />
          </span>
        </div>
        {openSection === "location" && (
          <>
            <div className={styles.locationCordinatesBox}>
              <div className={styles.locationCordinatesContent}>
                <p className={styles.locationCordinatesLabel}>Coordinates</p>
                <span>:</span>
                <p className={styles.locationCordinatesDetail}>{populateCoordinates(selectedGeofenceData?.center_coordinates)}</p>

                         </div>
              <div className={styles.locationCordinatesContent}>
                <p className={styles.locationCordinatesLabel}>Fence Shape</p>
                <span>:</span>
                <p className={styles.locationCordinatesDetail}>{selectedGeofenceData?.fence_geometry?.type || '-'}</p>
              </div>

              <div className={styles.locationCordinatesContent}>
                <p className={styles.locationCordinatesLabel}>Fence Area</p>
                <span>:</span>
                <p className={styles.locationCordinatesDetail}>
                  {selectedGeofenceData?.fence_geometry?.area? `${selectedGeofenceData?.fence_geometry?.area?.value.toFixed(2)} Sq.m.` : '-'}
                </p>
              </div>
            </div>


            {/* <div className={`${styles.saveBtnBox} ${styles.saveButtonLocation}`}>
              <button
              className={`${!fenceLocationData ? styles.saveBtnDisabled : styles.saveBtn}`}
              disabled={!fenceLocationData} onClick={() => setOpenSection('')}>
                Save
              </button>
            </div> */}
          </>
        )}
      </div>

      {/* Final Submit Button */}
      {/* <div className={`${styles.saveBtnBox} ${styles.submitButtonStyles}`}>
        <button disabled className={styles.saveBtnLocation} onClick={handleSubmit}>
          Submit
        </button>
      </div> */}
      {isGFCreateSuccessModelOpen && (

        <GFCreateSuccessModel
          isOpen={isGFCreateSuccessModelOpen}
          onClose={GFCreateSuccessModelClose}
          GFCreateSuccessModelClose={GFCreateSuccessModelClose}
        />
      )}
    </div>
  );
};

export default GFEditFenceForm;
