import React from "react";
import styles from "./SuccessModel.module.css";

const GFCreateSuccessModel = ({ GFCreateSuccessModelClose }) => {
  return (
    <>
      <div className={styles.sucessfullModel}>
        <div className={styles.sucessfullModelContent}>
          <div className={styles.sucessfullModelCloseBox}>
            <img
              className={styles.AddAlertcloseBtn}
              src="/images/geofenceModuleIcon/closeBtn.svg"
              alt="close icon"
              onClick={GFCreateSuccessModelClose}
            />
          </div>

          <img
            src="/images/geofenceModuleIcon/successfullCheck.svg"
            alt="successfullCheck"
          />
          <p className={styles.sucessfullModelLabel}>
            Successfully created the fence
          </p>
        </div>
      </div>
    </>
  );
};

export default GFCreateSuccessModel;
