import React, { useState, useMemo } from "react";
import styles from "./GFAddAlertModel.module.css";
import MultiSelect from "../../../../../helpers/hoc/multi-select/MultiSelect";

const GFAddAlertModel = ({ toggleAddAlert, handleSaveAlerts, userList }) => {
  const [alerts, setAlerts] = useState([
    {
      id: 1,
      selectedIngressData: { label: "Ingress", value: "ingress" },
      selectedDwellData: null,
      notification_users: [],
      timeStart: '',
      timeEnd: '',
      time: ''
    },
  ]);
  const [alertError, setAlertError] = useState([
    {
      time: '',
    }
  ])

  const dwellOptions = useMemo(
    () => [
      { label: ">", value: "GREATER_THAN" },
      { label: "<", value: "LESS_THAN" },
      { label: "=", value: "EQUAL_TO" },
      { label: "<=", value: "LESS_THAN_OR_EQUAL_TO" },
      { label: ">=", value: "GREATER_THAN_OR_EQUAL_TO" },
    ],
    []
  );

  const ingressOptions = useMemo(
    () => [
      { label: "Ingress", value: "ingress" },
      { label: "Egress", value: "egress" },
      { label: "Dwell Time", value: "dwell_time" },
      { label: "Return to Base Alert", value: "return_to_base" },
    ],
    []
  );

  const deleteAlert = (index) => {
    setAlerts((prevAlerts) => prevAlerts.filter((_, i) => i !== index));
    setAlertError((prevState) => prevState.filter((_, i) => i !== index));
  };


  const addAlert = () => {
    setAlerts((prevAlerts) => [
      ...prevAlerts,
      {
        id: prevAlerts.length + 1,
        selectedIngressData: { label: "Ingress", value: "ingress" },
        selectedDwellData: null,
        notification_users: [],
        timeStart: '',
        timeEnd: '',
        time: ''
      },
    ]);
    setAlertError((prevState) => [
      ...prevState,
      {
        time: ''
      },
    ]);
  };

  const handleSelectChange = (index, value, type) => {
    const newAlerts = [...new Set(alerts)]
    const newAlertError = [...new Set(alertError)]

    newAlerts[index] = {
      ...newAlerts[index],
      [type]: value
    }

    newAlertError[index] = {
      time: ''
    }

    if (type === 'selectedIngressData' && value.value === 'dwell_time') {
      newAlerts[index] = {
        ...newAlerts[index],
        selectedDwellData: newAlerts[index].selectedDwellData ? newAlerts[index].selectedDwellData : dwellOptions[0],
      }
    }

    if (['dwell_time', 'return_to_base'].includes(value.value)) {
      newAlerts[index] = {
        ...newAlerts[index],
        time: ''
      }
    }

    if (['ingress', 'egress'].includes(value.value)) {
      newAlerts[index] = {
        ...newAlerts[index],
        timeStart: '',
        timeEnd: ''
      }
    }

    setAlerts([...newAlerts])
    setAlertError([...newAlertError])
  };

  const handleUserSelectChange = (index, updatedOptions) => {
    setAlerts((prevAlerts) =>
      prevAlerts.map((alert, i) =>
        i === index ? { ...alert, notification_users: updatedOptions } : alert
      )
    );
  };

  const handleTimeChange = (index, value, type) => {
    const newAlerts = [...new Set(alerts)]
    const newAlertError = [...new Set(alertError)]

    newAlertError[index] = {
      ...newAlertError[index],
      time: ''
    }

    if (type === 'time' && value && (!/^\d+$/.test(value) || value < 1 || value > 24)) {
      newAlertError[index] = {
        ...newAlertError[index],
        time: 'Only numeric values from 1 to 24 are allowed'
      }
    }

    newAlerts[index] = {
      ...newAlerts[index],
      [type]: value
    }

    if (['timeStart', 'timeEnd'].includes(type) && newAlerts[index].timeStart && newAlerts[index].timeEnd && newAlerts[index].timeStart === newAlerts[index].timeEnd) {
      newAlertError[index] = {
        ...newAlertError[index],
        time: 'Start and end time can not be same'
      }
    }

    if (!newAlertError[index].time) {
      setAlerts([...newAlerts]);
    }

    setAlertError([...newAlertError])
  };

  const isSaveEnabled = alerts.every((alert) => {
    const isDwellTimeSelected =
      alert.selectedIngressData?.value === "dwell_time";
    const isIngressOrEgressSelected =
      alert.selectedIngressData?.value === "ingress" ||
      alert.selectedIngressData?.value === "egress";

    const isDwellTimeValid = isDwellTimeSelected
      ? alert.selectedDwellData
      : true;
    const isTimeRangeValid = isIngressOrEgressSelected
      ? alert.timeStart && alert.timeEnd
      : true;

    return (
      alert.selectedIngressData &&
      isDwellTimeValid &&
      isTimeRangeValid &&
      alert.notification_users.length > 0
    );
  });

  const renderTimeInputs = (index, { timeStart, timeEnd }) => {
    const errorMessage = alertError[index].time
    return (
      <div className={styles.alertDetailBox}>
        <label className={styles.fenceDetailsLabel}>Set Time:</label>
        <input
          className={styles.alertTimeInput}
          type="time"
          value={timeStart}
          onChange={(e) => handleTimeChange(index, e.target.value, "timeStart")}
        />
        to
        <input
          className={styles.alertTimeInput}
          type="time"
          value={timeEnd}
          onChange={(e) => handleTimeChange(index, e.target.value, "timeEnd")}
        />
        {errorMessage && <span className={styles.alertTimeError}>{errorMessage}</span>}
      </div>
    )
  };

  const renderDwellTimeInputs = (index, { selectedDwellData, time }) => {
    const errorMessage = alertError[index].time
    return (
      <div className={styles.alertDetailBox}>
        <label className={styles.fenceDetailsLabel}>Set Time:</label>
        <MultiSelect
          isMultiSelect={false}
          placeholder="Select"
          options={dwellOptions}
          selectedOptions={[selectedDwellData]}
          onChange={(value) =>
            handleSelectChange(index, value[0], "selectedDwellData")
          }
          customBackgroundColor="#E7ECF7"
          webkitScrollbarWidth="5px"
          singleValueColor="#1F2A5D"
          valueContainerJustifyContent="start"
          valueContainerWidthInPx="52px"
          valueContainerMaxWidthInPx="52px"
          optionPosition="ps-2 justify-content-start"
          placeholderAlignment="flex-start"
          indicatorSeparatorDisplay="none"
          maxMenuHeight={160}
          isClearable={false}
          isSearchable={false}
        />
        <div className={styles.timeInputContainer}>
          <input
            id={`time-input-${index}`}
            className={styles.alertTimeInput}
            value={time}
            onChange={(e) => handleTimeChange(index, e.target.value, "time")}
            style={{ width: '46px' }}
          />
          <span className={styles.hrsLabel}>Hrs</span>
        </div>

        {errorMessage && <span className={styles.alertTimeError}>{errorMessage}</span>}
      </div>
    )
  };

  const renderReturnToBaseInputs = (index, alert) => {
    const errorMessage = alertError[index].time
    return (
      <div className={`${styles.alertDetailBox} ml-5`}>
        <label
          htmlFor={`time-input-${index}`}
          className={styles.fenceDetailsLabel}
        >
          Set Time:
        </label>

        <div className={styles.timeInputContainer}>
          <input
            id={`time-input-${index}`}
            className={styles.alertTimeInput}
            value={alert.time}
            onChange={(e) => handleTimeChange(index, e.target.value, "time")}
            style={{ width: '46px' }}
          />
          <span className={styles.hrsLabel}>Hrs</span>
        </div>

        {errorMessage && <span className={styles.alertTimeError}>{errorMessage}</span>}
      </div>
    );
  };

  const renderUserSelection = (index, { notification_users }) => (
    <div className={styles.selectUserLabel}>
      {index === 0 && (
        <label className={styles.fenceDetailsLabel}>Select User</label>
      )}

      <div style={{ width: "240px" }}>
        <MultiSelect
          options={userList}
          selectedOptions={notification_users}
          placeholder="Select"
          customBackgroundColor="#E9EDF8"
          isMultiSelect
          setSelectedOptions={(updatedOptions) =>
            handleUserSelectChange(index, updatedOptions)
          }
          webkitScrollbarWidth="5px"
          webkitScrollbarHeight="5px"
          minHeight="40px"
          placeholderAlignment="flex-start"
          indicatorSeparatorDisplay="none"
          placeholderColor="#C6C6C6"
          valueContainerColor="#797694"
          maxMenuHeight={160}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.addAlertModel}>
      <div className={styles.addAlertModelContent}>
        <div className={styles.alertModelClose}>
          <img
            className={styles.AddAlertCloseBtn}
            src="/images/geofenceModuleIcon/closeBtn.svg"
            alt="Close"
            onClick={toggleAddAlert}
          />
        </div>

        <div className={styles.alertHeader}>
          <h4>Add Alert</h4>
          <button className={styles.AddAlertButton} onClick={addAlert}>
            <img
              className={styles.AddAlertIcon}
              src="/images/geofenceModuleIcon/AddAlertIcon.svg"
              alt="Add"
            />
            Add
          </button>
        </div>

        <div className={styles.alertDetailContainer}>
          {alerts.map((alert, index) => (
            <div key={alert.id} className={styles.alertBoxWrapper}>
              <div className={styles.alertDetailBox}>
                <label className={styles.fenceDetailsLabel} style={{ width: '55px' }}>
                  {`Alert ${index + 1}`}
                </label>
                <MultiSelect
                  isMultiSelect={false}
                  placeholder="Select"
                  options={ingressOptions}
                  selectedOptions={[alert.selectedIngressData]}
                  onChange={(value) => {
                    handleSelectChange(index, value[0], "selectedIngressData")
                  }}
                  customBackgroundColor="#E7ECF7"
                  webkitScrollbarWidth="5px"
                  singleValueColor="#1F2A5D"
                  valueContainerJustifyContent="start"
                  valueContainerWidthInPx="166px"
                  valueContainerMaxWidthInPx="166px"
                  optionPosition="ps-2 justify-content-start"
                  placeholderAlignment="flex-start"
                  indicatorSeparatorDisplay="none"
                  maxMenuHeight={160}
                  isClearable={false}
                  isSearchable={false}

                />
              </div>

              {(alert.selectedIngressData?.value === "ingress" ||
                alert.selectedIngressData?.value === "egress") &&
                renderTimeInputs(index, alert)}

              {alert.selectedIngressData?.value === "dwell_time" &&
                renderDwellTimeInputs(index, alert)}

              {alert.selectedIngressData?.value === "return_to_base" &&
                renderReturnToBaseInputs(index, alert)}

              {renderUserSelection(index, alert)}

              <div className={styles.deleteIconBox}>
                {alerts.length > 1 && (
                  <img
                    src="/images/geofenceModuleIcon/alertDeleteIcon.svg"
                    alt="Delete"
                    onClick={() => deleteAlert(index)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={() => handleSaveAlerts(alerts)}
          className={
            isSaveEnabled ? styles.enabledSaveButton : styles.disabledSaveButton
          }
          disabled={!isSaveEnabled}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default GFAddAlertModel;
