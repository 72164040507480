import React, { useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell, Label } from "recharts";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DrivingPIChartAlertConfiguration } from "../../../../../../utils/constants";

const BarChartComponent = ({
  //action

  //state
  allAnalyticsAlarmsData,
}) => {
  const [tooltipColor, setTooltipColor] = useState("");
  const [alarmType, setAlarmType] = useState("");
  const [alarmValue, setAlarmValue] = useState("");

  const [drivingTooltipColor, setDrivingTooltipColor] = useState("");
  const [drigingAlarmType, setDrivingAlarmType] = useState("");
  const [drivingAlarmValue, setDrivingAlarmValue] = useState("");

  const CustomLabel = ({ variant }) => {
    let oemAndModels = [];
    if (
      variant === "BATTERY_ALERT" &&
      allAnalyticsAlarmsData.batteryAlerts
    ) {
      oemAndModels = Object.keys(
        allAnalyticsAlarmsData.batteryAlerts.countByOemModel
      );
    }
    if (
      variant === "DRIVING_ALERT" &&
      allAnalyticsAlarmsData.drivingAlerts
    ) {
      oemAndModels = Object.keys(
        allAnalyticsAlarmsData.drivingAlerts.countByOemModel
      );
    }

    const boldStyle = { fontWeight: "normal" };
    return (
      <text textAnchor="end" fill="#22046B">
        {oemAndModels.map((e, i) => {
          return (
            <tspan
              x={130}
              dy={`${i === 0 ? 36 : 30}px`}
              style={boldStyle}
              fontSize={12}
            >
              {e}
            </tspan>
          );
        })}
      </text>
    );
  };

  const CustomTooltip = ({ payload, color, alarmType, value }) => {
    if (!payload || payload.length === 0 || value === "" || value === "NA")
      return null;
    const { name } = payload[0].payload;

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          border: "1px solid #ccc",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#939393",
          borderRadius: 5,
          opacity: 0.9,
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              height: 10,
              width: 10,
              borderRadius: "50%",
              backgroundColor: color,
              color,
              display: "inline-flex",
            }}
          ></span>
          <div
            style={{
              marginLeft: 10,
              color: "white",
              fontSize: "0.70em",
            }}
          >
            {name}
          </div>
        </span>

        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              height: 10,
              width: 10,
              borderRadius: "50%",
              backgroundColor: "transparent",
              color,
              display: "inline-flex",
            }}
          ></span>
          <div
            style={{
              color: "white",
              marginLeft: 10,
              fontSize: "0.70em",
            }}
          >{`${value} ${alarmType}`}</div>
        </span>
      </div>
    );
  };

  const CustomTooltipDriving = ({ payload, color, alarmType, value }) => {
    if (!payload || payload.length === 0 || value === "") return null;
    const { name } = payload[0].payload;

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          border: "1px solid #ccc",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#939393",
          borderRadius: 5,
          opacity: 0.9,
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              height: 10,
              width: 10,
              borderRadius: "50%",
              backgroundColor: color,
              color,
              display: "inline-flex",
            }}
          ></span>
          <div
            style={{
              marginLeft: 10,
              color: "white",
              fontSize: "0.70em",
            }}
          >
            {name}
          </div>
        </span>

        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              height: 10,
              width: 10,
              borderRadius: "50%",
              backgroundColor: "transparent",
              color,
              display: "inline-flex",
            }}
          ></span>
          <div
            style={{
              color: "white",
              marginLeft: 10,
              fontSize: "0.70em",
            }}
          >{`${value} ${alarmType}`}</div>
        </span>
      </div>
    );
  };

  function calculateMultiplier(count) {
    let value = (0.5).toFixed(5);
    for (let i = 1; i <= count; i++) {
      if (i > 4) {
        value /= 2;
      } else {
        value /= 2;
      }
    }
    return (1 + parseFloat(value)).toFixed(5);
  }

  const calculateBarHeight = (length) => {
    if (length === 0) {
      return 0;
    }

    if (length === 1) {
      return 36 * 2.25;
    }

    const multiplier = calculateMultiplier(length - 2);
    return 36 * multiplier * length;
  };

  let batteryAlertData = [];

  if (allAnalyticsAlarmsData.batteryAlerts) {
    Object.keys(
      allAnalyticsAlarmsData.batteryAlerts.countByOemModel
    ).forEach((model) => {
      const batteryDataByModel =
        allAnalyticsAlarmsData.batteryAlerts.countByOemModel[model]
          .countByType;
      batteryAlertData.push({
        name: model,
        divisions: [
          {
            name: "Low SoC",
            color: "#F34848",
            value: batteryDataByModel["Low SoC"],
          },
          {
            name: "Deep Discharge",
            color: "#F15858",
            value: batteryDataByModel["Deep Discharge Warning"],
          },
          {
            name: "Battery Under Voltage",
            color: "#FF8484",
            value: batteryDataByModel["Battery Under Voltage"],
          },
          {
            name: "Aux. Battery Under Voltage",
            color: "#FCD1D1",
            value: batteryDataByModel["Aux. Battery Under Voltage"],
          },
          // {
          //   name: "Battery Over Heat",
          //   color: "#FFA7A7",
          //   value: batteryDataByModel["Battery Overheat"],
          // },
        ],
      });
    });
  }

  const batteryAlertsHeight = calculateBarHeight(batteryAlertData.length);

  let drivingAlertData = [];
  const getColor = (typeKey) => {
    const currentType = DrivingPIChartAlertConfiguration.filter(
      (x) => x.type.key === typeKey
    );
    if (currentType && currentType?.length > 0) {
      return currentType[0]?.color;
    }
    return "#FFF";
  };

  if (allAnalyticsAlarmsData.drivingAlerts) {
    Object.keys(
      allAnalyticsAlarmsData.drivingAlerts.countByOemModel
    ).forEach((model) => {
      const drivingDataByModel =
        allAnalyticsAlarmsData.drivingAlerts.countByOemModel[model]
          .countByType;

      drivingAlertData.push({
        name: model,
        divisions: [
          {
            name: "OverSpeed",
            value: drivingDataByModel["OverSpeed"],
            color: getColor("OverSpeed"),
          },
          {
            name: "Harsh Acceleration",
            value: drivingDataByModel["Harsh Acceleration"],
            color: getColor("Harsh Acceleration"),
          },
          {
            name: "Harsh Braking",
            value: drivingDataByModel["Harsh Braking"],
            color: getColor("Harsh Braking"),
          },
          {
            name: "Harsh Cornering",
            value: drivingDataByModel["Harsh Cornering"],
            color: getColor("Harsh Cornering"),
          },
        ],
      });
    });
  }

  const height = calculateBarHeight(drivingAlertData.length);

  return (
    <div
      style={{
        marginTop: 1,
        marginBottom: 20,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "unset",
      }}
    >
      <div
        style={{
          boxShadow: "0 2px 5px 2px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
          width: "48%",
          borderRadius: "2px",
          padding: "10px",
        }}
        className="al__battery-graph-container"
      >
        <div
          style={{
            fontWeight: "500",
            color: "#1F2A5D ",
          }}
        >
          Driving Alert Graph
        </div>
        <div className="container-fuild-hr">
          <div className="row row-hr"></div>
        </div>
        <BarChart
          width={480}
          height={height}
          layout="vertical"
          data={drivingAlertData}
          margin={{ top: 20, right: 10, left: 75, bottom: 5 }}
          color="#1F2a5d"
        >
          <XAxis
            type="number"
            axisLine={{ strokeWidth: 2 }}
            label={{
              value: "No. of Alert Count",
              position: "insideBottom",
              fontSize: "14px",
              fill: "#1F2A5D",
              dy: 5,
              fontWeight: "500",
              color: "#1f2a5d ",
            }}
          />
          <YAxis
            type="category"
            tickMargin={2}
            axisLine={{ strokeWidth: 2 }}
            tick={false}
          >
            <Label
              content={<CustomLabel variant="DRIVING_ALERT" />}
              offset={100}
              position={"insideLeft"}
            />
          </YAxis>
          <Tooltip
            content={
              <CustomTooltip
                color={tooltipColor}
                alarmType={alarmType}
                value={alarmValue}
              />
            }
            isAnimationActive={false}
            cursor={{ fill: "transparent" }}
          />
          {drivingAlertData[0] &&
            drivingAlertData[0].divisions.map((division, index) => (
              <Bar
                key={division.name}
                dataKey={`divisions[${index}].value`}
                stackId="a"
                barSize={50}
              >
                {drivingAlertData.map((entry, subIndex) => (
                  <>
                    <Cell
                      key={`cell-${subIndex}`}
                      fill={entry.divisions[index].color}
                      stroke="white"
                      strokeWidth={0.2}
                      onMouseEnter={() => {
                        setTooltipColor(
                          entry.divisions[index].color
                        );
                        setAlarmType(
                          entry.divisions[index].name
                        );
                        setAlarmValue(
                          entry.divisions[index].value
                        );
                      }}
                      onMouseLeave={() => {
                        setTooltipColor("");
                        setAlarmType("");
                        setAlarmValue("");
                      }}
                    />
                  </>
                ))}
              </Bar>
            ))}
        </BarChart>
      </div>

      <div style={{ padding: "unset", width: "4%" }}></div>

      <div
        style={{
          boxShadow: "0 2px 5px 2px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
          width: "48%",
          borderRadius: "2px",
          padding: "10px",
        }}
        className="al__battery-graph-container"
      >
        <div
          style={{
            fontWeight: "500",
            color: "#1F2A5D ",
          }}
        >
          Battery Alert Graph
        </div>
        <div className="container-fuild-hr">
          <div className="row row-hr"></div>
        </div>
        <BarChart
          width={480}
          height={batteryAlertsHeight}
          layout="vertical"
          data={batteryAlertData}
          margin={{ top: 20, right: 30, left: 75, bottom: 5 }}
          color="#1F2a5d"
        >
          <XAxis
            type="number"
            axisLine={{ strokeWidth: 2 }}
            label={{
              value: "No. of Alert Count",
              position: "insideBottom",
              fontSize: "14px",
              fill: "#1F2A5D",
              dy: 5,
              fontWeight: "500",
              color: "#1f2a5d ",
            }}
          />
          <YAxis
            type="category"
            tick={false}
            axisLine={{ strokeWidth: 2 }}
          >
            <Label
              content={<CustomLabel variant="BATTERY_ALERT" />}
              offset={100}
              position={"insideLeft"}
            />
          </YAxis>
          <Tooltip
            content={
              <CustomTooltipDriving
                color={drivingTooltipColor}
                alarmType={drigingAlarmType}
                value={drivingAlarmValue}
              />
            }
            isAnimationActive={false}
            cursor={{ fill: "transparent" }}
          />
          {batteryAlertData[0] &&
            batteryAlertData[0].divisions.map((division, index) => (
              <Bar
                key={division.name}
                dataKey={`divisions[${index}].value`}
                stackId="a"
                barSize={50}
              >
                {batteryAlertData.map((entry, subIndex) => (
                  <>
                    <Cell
                      key={`cell-${subIndex}`}
                      fill={entry.divisions[index].color}
                      stroke="white"
                      strokeWidth={0.2}
                      onMouseEnter={() => {
                        setDrivingTooltipColor(
                          entry.divisions[index].color
                        );
                        setDrivingAlarmType(
                          entry.divisions[index].name
                        );
                        setDrivingAlarmValue(
                          entry.divisions[index].value
                        );
                      }}
                      onMouseLeave={() => {
                        setDrivingTooltipColor("");
                        setDrivingAlarmType("");
                        setDrivingAlarmValue("");
                      }}
                    />
                  </>
                ))}
              </Bar>
            ))}
        </BarChart>
      </div>
    </div>
  );
};

BarChartComponent.propTypes = {
  allAnalyticsAlarmsData: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  allAnalyticsAlarmsData: state.alarm.allAnalyticsAlarmsData,
});
export default connect(mapStateToProps, {})(BarChartComponent);
