import React, {  useEffect, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import GeofenceAnalytics from '../../Analytics/GeofenceAnalytics/GeoFenceAnalytics';
import GeofanceAlert from '../../Geo-fencing/reports/geofanceAlert';
import withGeofenceAlertHOC from './geofenceAlertHOC';
import { useDispatch } from 'react-redux';
import { resetGeofenceFilters } from '../../../store/actions/geo-fence/analytics/analyticsActions';

const Geofance = (props) => {
	const [activeTab, setActiveTab] = useState('charge_analytics_chats');
	const dispatch = useDispatch()

	useEffect(() => {
		return () => {
			dispatch(resetGeofenceFilters())
		}
	}, [dispatch])

	const onTabSelectHandler = async (event) => {
		setActiveTab(event);
	
		if (event === 'charge_analytics_chats') {
			dispatch(resetGeofenceFilters())
			return;
		} else {
		
		}
	};

	return (
		<>
			<div className='col-12 bg-white'>
				<div className='bg-white mb-1 position-relative tabbed-content'>
					
					<div className='col-12'>
						<Container fluid>
							<div className='charging-tab-container'>
								<Tabs
									className='alert-nav-link'
									defaultActiveKey='non_comm_report'
									id='uncontrolled-tab-example'
									onSelect={(event) => {
										onTabSelectHandler(event);
									}}
									activeKey={activeTab}
								>
									<Tab eventKey='charge_analytics_chats' title='Analytics'>
                                        <GeofenceAnalytics masters={props.masters} />
									
									</Tab>
									<Tab eventKey='charge_report' title='Reports' >
										{activeTab ==='charge_report' && (
											<GeofanceAlert masters={props.masters} geofences={props.geofences} />
										)}
									</Tab>
								</Tabs>
							</div>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};

export default withGeofenceAlertHOC(Geofance)
