import React, { useState } from 'react'
import * as FileSaver from 'file-saver'
import { FaSearch } from 'react-icons/fa';
import filterIcon from '../../../../../assets/GeoFencingIcons/filter.svg';
import { Accordion, Dropdown, Table } from 'react-bootstrap';
import Pagination from '../../../../helpers/hoc/paginator/Pagination';
import { BsTrash } from 'react-icons/bs';
import ExcelJS from 'exceljs';
import GroupVehicleOnboardingTemplate from '../../../../../assets/templates/Groups_Filter_Upload_Template.xlsx'
// import { RiFolderTransferLine } from 'react-icons/ri';

import { mapper } from '../../../../../utils/mapper';

import './GPAddVehicleTable.css'
import BrowseAndUploadFileModal from './child components/Bulk upload modal/BrowseAndUploadFileModal';
import MultiSelect from '../../../../helpers/hoc/mult-select/MultiSelect';
import InformativeModal from '../../../../shared/modals/InformativeModal/InformativeModel';

const GPAddVehicleTable = (props, ref) => {
    const {currentPage , setCurrentPage, selectedChargingType,selectedVehicleNumber,selectedVehicleType,selectedCity,selectedOEM,setSelectedChargingType,setSelectedVehicleNumber,setSelectedVehicleType,setSelectedCity,setSelectedOEM,savedFilters,setSavedFilters,setSearchQuery,searchQuery} = props
  
    

    const userRole = JSON.parse(localStorage.getItem('user'))?.userRole
    
    const { mode, activeState, onCreateSubgroupClick, onExistingSubgroupClick, vehicleList = [], selectedVehicleIds, onVehicleChange, onSelectAllChange, selectedAllVehicle, selectAllVehicleRef, onSaveClick, onCancelClick, onDeleteVehicleClick, onAddVehicleClick ,groupsAssignDepartmentAdmin,onAssginDAChange, selectedAssignees } = props


    

    const cityCheckboxRef = React.useRef();

    const oemCheckboxRef = React.useRef();
    
    const vehicleTypeCheckboxRef =React.useRef()

    const vehicleNumberCheckboxRef= React.useRef()

    const chargingTypeCheckboxRef = React.useRef()


    // const [searchQuery, setSearchQuery] = React.useState('');

    const [filterOpen, setFilterOpen] = React.useState(false);
    // const [savedFilters, setSavedFilters] = React.useState({
    //     oem: [],
    //     city: [],
    //     vehicleTypes:[],
    //     vehicleNumber:[],
    //     chargingType:[]
    // });


    // const [selectedOEM, setSelectedOEM] = React.useState([]);
    // const [selectedCity, setSelectedCity] = React.useState([]);
    // const [selectedVehicleType, setSelectedVehicleType] = React.useState([])
    // const [selectedVehicleNumber, setSelectedVehicleNumber] = React.useState([])
    // const [selectedChargingType, setSelectedChargingType] = React.useState([])

    const [subgroupPopup, setSubgroupPopup] = React.useState(null);

    const [isShowModal, setIsShowModal] = useState(false)

    const [priorityVehicles, setPriorityVehicles] = useState([])


    

    const resetPagination = () => {
        setCurrentPage(1)
    }
    const resetSearchQuery = () => {
        setSearchQuery('')
    }
    const resetSelectAllVehicle = () => {
        selectAllVehicleRef.current.indeterminate = false;
    }

 
    // expose methods from child to parent
    React.useImperativeHandle(ref, () => ({
        resetPagination,
        resetSearchQuery,
        resetSelectAllVehicle,
    }))

    const mappedOptions = mapper.mapVehicleDataOptions(vehicleList);
    
    

    let filteredVehicleList = vehicleList.filter(vehicle => {
        if (!savedFilters.city.length) {
            return true;
        }

        return savedFilters.city.includes(`${vehicle?.location}`)
    }).filter(vehicle => {
        if (!savedFilters.oem.length) {
            return true;
        }

        return savedFilters.oem.includes(`${vehicle?.oem} ${vehicle?.model}`);
    }).filter(vehicle=>{
        if(!savedFilters.vehicleTypes.length){
            return true;
        }

    return savedFilters.vehicleTypes.includes(`${vehicle?.type}`)
    }).filter(vehicle=>{
        if(!savedFilters.vehicleNumber.length){
            return true;
        }

    return savedFilters.vehicleNumber.includes(`${vehicle?.registration_number}`)
    }).filter(vehicle=>{
        if(!savedFilters.chargingType.length){
            return true;
        }

    return savedFilters.chargingType.includes(`${vehicle?.charging_type}`)
    }).filter(vehicle => {
        if (!searchQuery) {
            return true;
        }

        return `${vehicle?.registration_number}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
               `${vehicle?.oem} ${vehicle?.model}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
               `${vehicle?.location}`.toLowerCase().includes(searchQuery.toLowerCase())||
               `${vehicle?.type}`.toLowerCase().includes(searchQuery.toLowerCase())||
               `${vehicle?.charging_type}`.toLowerCase().includes(searchQuery.toLowerCase())



    })

    React.useEffect(() => {
        if (!selectedVehicleIds?.length) {
            selectAllVehicleRef.current.indeterminate = false;
        } else {
            if (selectedVehicleIds?.length === filteredVehicleList?.length) {
                selectAllVehicleRef.current.indeterminate = false;
            } else {
                selectAllVehicleRef.current.indeterminate = true;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVehicleIds])

    React.useEffect(() => {
        if (selectedOEM?.length && selectedOEM?.length !== mappedOptions.oemModel.length) {
            oemCheckboxRef.current.indeterminate = true;
        } else {
            oemCheckboxRef.current.indeterminate = false;
        }

        if (selectedCity.length && selectedCity.length !== mappedOptions.location?.length) {
            cityCheckboxRef.current.indeterminate = true;
        }
        if (selectedVehicleType.length && selectedVehicleType.lenght !== mappedOptions.vehicleCategory?.length)
        {
            vehicleTypeCheckboxRef.current.indeterminate = true;

        } if (selectedVehicleNumber.length && selectedVehicleNumber.lenght !== mappedOptions.registrationNumber?.length)
            {
                vehicleNumberCheckboxRef.current.indeterminate = true;
    
            }if (selectedChargingType.length && selectedChargingType.length !== mappedOptions.chargingType?.length){
                chargingTypeCheckboxRef.current.indeterminate = true;
            }
        else {
            cityCheckboxRef.current.indeterminate = false;
        }

        if (selectedVehicleType.length && selectedVehicleType !== mappedOptions.vehicleCategory?.length){
            vehicleTypeCheckboxRef.current.indeterminate = true;
        } else {
            vehicleTypeCheckboxRef.current.indeterminate = false;
        }
        if (selectedVehicleNumber.length && selectedVehicleNumber !== mappedOptions.registrationNumber?.length){
                vehicleNumberCheckboxRef.current.indeterminate = true;
        } else {
                vehicleNumberCheckboxRef.current.indeterminate = false;
        }
        if (selectedChargingType.length && selectedChargingType.length !== mappedOptions.chargingType?.length){
                chargingTypeCheckboxRef.current.indeterminate = true;
        }else {
                chargingTypeCheckboxRef.current.indeterminate = false;
        }
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCity, selectedOEM, selectedVehicleType, selectedVehicleNumber, selectedChargingType])

    const handleApplyFilter = () => {
        let cityFilters = [...selectedCity];
        let oemFilters = [...selectedOEM];
        let vehicleTypeFilters =[...selectedVehicleType]
        let vehicleNoFilters =[...selectedVehicleNumber]
        let chargingTypeFilters =[...selectedChargingType]

      
        

        if (currentPage > 1) {
            setCurrentPage(1);
        }
        setSavedFilters({
            oem: oemFilters,
            city: cityFilters,
            vehicleTypes:vehicleTypeFilters,
            vehicleNumber:vehicleNoFilters,
            chargingType:chargingTypeFilters
        })
        setFilterOpen(false);
    }

    const handleRevertFilter = () => {
        if (currentPage > 1) {
            setCurrentPage(1);
        }
        setSavedFilters({
            oem: [],
            city: [],
            vehicleTypes:[],
            vehicleNumber:[],
            chargingType:[]
        })
        onSelectAllChange(false,[]);
        setSelectedCity([]);
        setSelectedOEM([]);
        setSelectedVehicleType([]);
        setSelectedVehicleNumber([])
        setSelectedChargingType([])
        

        oemCheckboxRef.current.indeterminate = false;
        oemCheckboxRef.current.checked = false;

        cityCheckboxRef.current.indeterminate = false;
        cityCheckboxRef.current.checked = false;

        vehicleTypeCheckboxRef.current.indeterminate = false;
        vehicleTypeCheckboxRef.current.checked = false;

        vehicleNumberCheckboxRef.current.indeterminate = false;
        vehicleNumberCheckboxRef.current.checked = false;

        chargingTypeCheckboxRef.current.indeterminate =false;
        chargingTypeCheckboxRef.current.checked = false
    }

    const handleFilterChange = ({ name, value }) => {
        
        
        if (name === 'selectedOEM') {
            let checked = [...selectedOEM]
            
            if (checked.includes(value)) {
                checked = checked.filter(v => v !== value);
            } else {
                checked.push(value);
            }

            setSelectedOEM([...checked]);
        }

        if (name === 'selectedCity') {
            let checked = [...selectedCity]
            
            if (checked.includes(value)) {
                checked = checked.filter(v => v !== value);
            } else {
                checked.push(value);
            }

            setSelectedCity([...checked]);
        }
        if (name === 'selectedVehicleType') {
            let checked =[...selectedVehicleType]
             
            if (checked.includes(value)) {
                checked = checked.filter(v => v !== value);
            } else {
                checked.push(value);
            }
            setSelectedVehicleType([...checked])
        }
        if (name === 'selectedVehicleNumber') {
            let checked =[...selectedVehicleNumber]
        
            
             
            if (checked.includes(value)) {
                checked = checked.filter(v => v !== value);
            } else {
                checked.push(value);
            }
            setSelectedVehicleNumber([...checked])
        }
        if (name === 'selectedChargingType') {
            let checked =[...selectedChargingType]
        
            
             
            if (checked.includes(value)) {
                checked = checked.filter(v => v !== value);
            } else {
                checked.push(value);
            }
            setSelectedChargingType([...checked])
        }
        
       
    }

    const ITEMS_PER_PAGE = 8;
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const totalPages = Math.ceil(filteredVehicleList.length / ITEMS_PER_PAGE)



  let reorderedVehicles = [];


  priorityVehicles?.forEach(registrationNumber => {
    const index = filteredVehicleList?.findIndex(vehicle => vehicle.registration_number === registrationNumber);
    if (index !== -1) {
        reorderedVehicles?.push(filteredVehicleList[index]); 
        filteredVehicleList?.splice(index, 1);  
    }
});

filteredVehicleList = reorderedVehicles?.concat(filteredVehicleList);




const paginatedVehicleList = filteredVehicleList?.slice(startIndex, endIndex);




    React.useEffect(() => {
		const handleAddVehicleRowAction = (event) => {
			if (!event?.target?.classList.contains('grp__add_vehicle_action')) {
				setSubgroupPopup(null)
			}

            if(!event?.target?.classList.contains('grp__header_move_group')) {
                setSubgroupPopup(null)
            }
		}
		document.addEventListener('click', handleAddVehicleRowAction)
		return () => {
		  document.removeEventListener('click', handleAddVehicleRowAction)
		}
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    const isAllOEMChecked = selectedOEM.length === mappedOptions.oemModel.length
    const isAllCityChecked = selectedCity.length === mappedOptions.location?.length
    const isAllVehicleTypeChecked =selectedVehicleType.length === mappedOptions.vehicleCategory?.length
    const isAllVehicleNumberChecked =selectedVehicleNumber.length === mappedOptions.registrationNumber?.length
    const isAllChargingTypeChecked =selectedChargingType.length === mappedOptions.chargingType?.length


    
    const [apiResponse, setApiResponse] = useState({
        isOpen: false,
        message: '',
        variant: ''
    })


    const handleFileUpload = async (file) => {
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();
    
        reader.onload = async (e) => {
            const buffer = e.target.result;
            await workbook.xlsx.load(buffer);
            const worksheet = workbook.getWorksheet(1);
            const data = [];
    
            worksheet.eachRow((row, rowNumber) => {
                data.push(row.values);
            });

            
            // const registrationNumbers = data.map(row => row[2]);
            const registrationNumbers = data?.slice(1).map(row => row[2]); 

            if(!registrationNumbers.length) {
                setApiResponse({
                    ...apiResponse,
                    isOpen: true,
                    message: "Empty Sheet Uploaded!",
                    variant: 'warning'
                })
                return
            }


            const bulkUploadedVehicleNos = registrationNumbers.map(x => {
                return x?.richText?.[0]?.text
            })

            const filteredActiveVehicles = filteredVehicleList.map(x => x.registration_number)
            if(bulkUploadedVehicleNos?.length) {
                let areVehiclesValid = true
                bulkUploadedVehicleNos.forEach(x => {
                if(!filteredActiveVehicles?.includes(x)) {
                    areVehiclesValid = false
                }
                })

                if(!areVehiclesValid) {
                    setApiResponse({
                        ...apiResponse,
                        isOpen: true,
                        message: "Invalid Vehicle Data!",
                        variant: 'error'
                    })
                    return
                }
                setPriorityVehicles(bulkUploadedVehicleNos);
                onSelectAllChange(true, bulkUploadedVehicleNos);
            }
            
        };
        reader.readAsArrayBuffer(file); 
    
    };
    

  

    const downloadOnboardingTemplate = () => {
        FileSaver.saveAs(
            GroupVehicleOnboardingTemplate,
          'GroupVehicleOnboardingTemplate.xlsx'
        )
      }

   


    return (
        <>
            <div className='d-flex align-items-center flex-row gap-2' style={{paddingLeft:'0px'}}>
                <div className='col-auto'>
                    <div className='search-bar-div search-input'>
                        <FaSearch className='vob-search-icon' />
                        <input
                            className='vob-search-input search-input'
                            placeholder='Search'
                            onChange={(e) => {
                                setSearchQuery(e.target.value)
                                if (currentPage > 1) {
                                    setCurrentPage(1);
                                }
                            }}
                            value={searchQuery}
                            data-testid='add-vehicle-search-input'
                        />
                    </div>
                </div>

                <InformativeModal
                isOpen={apiResponse.isOpen}
                onClose={() => setApiResponse({
                    ...apiResponse,
                    isOpen: false
                })}
                message={apiResponse.message}
                variant={apiResponse.variant}
            />

                <div className='col d-flex groups__add__count__text gap-3'>
                    <div className='d-flex justify-content-between '>
                        <p className='groups__edit__conut__para'>Total Vehicle Count </p>
                        <span className='group__edit__arrow'>:</span>
                        <span className='groups__edit__conut__span'>({selectedVehicleIds.length}/{filteredVehicleList.length})</span>
                    </div>
                </div>

               
                <div className='col-auto d-flex position-relative'>
                {(userRole === 'DU' || (['ADD_VEHICLE'].includes(activeState) || ["EDIT_SUBGROUP"].includes(mode))) ? null : (
 
                    
                    <div style={{display:'block', marginTop:'-24px', marginRight:'10px'}}>
                    <label>{userRole === 'CA' ? 'Assign Department Admin' : 'Assign Department User'}</label><span 
                        style={{
                        fontSize: '16px',
                         fontWeight: 700,
                         color: '#1F2A5D',
                         marginLeft: '5px'
                         }}>
                        {selectedAssignees?.length > 0 && `(${selectedAssignees?.length})` }
                      </span>

                        <MultiSelect
                        placeholder='Select'
                        selectedOptions={selectedAssignees}
                        setSelectedOptions={onAssginDAChange}
                        isMultiSelect={true}
                        options={groupsAssignDepartmentAdmin}
                        valueContainerWidthInPx='200px'
                        valueContainerMaxWidthInPx='200px'
                        menuListPaddingX='px-2'
                        okButtonPaddingEnd='pe-2'
                        optionPosition='justify-content-start'
                        customBackgroundColor='#EFF3F7'
                        minHeight='28px'
                        webkitScrollbarWidth='5px'
                        webkitScrollbarHeight='5px'
                        placeholderAlignment='flex-start'
                        indicatorSeparatorDisplay='none'
                        placeholderColor='#C6C6C6'
                        valueContainerColor='#797694'
                        data-testid='history-fencename-select'
                        />
                    </div>
                )}

                  {mode === "EDIT_GROUP"&& <div className='col-auto gap-2 d-flex align-items-center' style={{marginRight:'10px'}}>
                        <button className='dwn-btn' style={{ fontSize: '12px' }}
                            data-testid='add-vehicle-button'
                            onClick={onCreateSubgroupClick}
                        >
                         + Create sub group
                        </button>
                </div>}
                        

                {['MOVE_VEHICLE', 'EDIT'].includes(activeState) && ["EDIT_GROUP", "EDIT_SUBGROUP"].includes(mode) ? (
                    <div className='col-auto gap-2 d-flex align-items-center'>
                        <button
                            style={{ fontSize: '12px' }}
                            className='dwn-btn'
                            onClick={onAddVehicleClick}
                            data-testid='add-vehicle-button'
                        >
                            Add Vehicle
                        </button>

                        <button
                            style={{ fontSize: '16px',marginRight:'7px' }}
                            className={`dwn-btn ${selectedVehicleIds.length < 2 && 'dwn-btn-disabled'}`}
                            disabled={selectedVehicleIds.length < 2}
                            onClick={onDeleteVehicleClick}
                            data-testid='delete-vehicle-button'
                        >
                            <BsTrash />
                        </button>

                        {mode === "EDIT_GROUP" && (
                            <div className='position-relative'>
                               

                                {subgroupPopup === 'MAIN' && (
                                    <div className='popupGroupsData subgroup-popup' style={{ fontSize: '0.75rem' }}>
                                        <ul className='popupSubGroups_ul'>
                                            <li
                                                onClick={() => {
                                                    setSubgroupPopup(null)
                                                    onCreateSubgroupClick()
                                                }}
                                                data-testid='create-subgroup-button'
                                            >
                                                Create Sub Group
                                            </li>
                                        </ul>
                                        <ul className='popupSubGroups_ul'>
                                            <li
                                                onClick={() => {
                                                    setSubgroupPopup(null)
                                                    onExistingSubgroupClick()
                                                }}
                                                data-testid='add-existing-subgroup-button'
                                            >
                                                Add in Existing Sub Group
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : null}

            {['ADD_VEHICLE', 'EDIT','MOVE_VEHICLE'].includes(activeState) && ["EDIT_GROUP", "EDIT_SUBGROUP"].includes(mode) ? null :
              <Dropdown className='bulk-upload-dropdown' style={{marginRight:'10px'}}>
                    <Dropdown.Toggle className="vob-btns" id="dropdown-basic" data-testid='bulk-download-dropdown'>
                      Upload File
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item
                          style={{color: '#22046B'}}
                          onClick={() => {
                             downloadOnboardingTemplate();
                          }}
                          data-testid='download-template-button'
                    >
                        Download Template
                    </Dropdown.Item>
                    <Dropdown.Item
                        style={{color: '#22046B'}}
                        onClick={() => {
                        isShowModal ? setIsShowModal(false) : setIsShowModal(true);
                        }}
                        data-testid='upload-file-button'
                    >
                        Bulk Upload
                    </Dropdown.Item>
                    </Dropdown.Menu>
              </Dropdown>}

                    <button
                        className='dwn-btn filter-icon'
                        onClick={() => setFilterOpen(!filterOpen)}
                        data-testid='filter-menu-button'
                    >
                        <img src={filterIcon} alt='' title='' />
                    </button>

                    <div className={`geofence-dropdown-content groupsAccordion dropdown-custom-style-geofence ${filterOpen ? '' : 'd-none'}`}>                    
                        <Accordion className='acc'>
                            <Accordion.Item eventKey='0'>
                                <div
                                className='oemAndModelDiv'>

                                <div className='groupsOemDiv'>
                                        <input
                                            ref={oemCheckboxRef}
                                            checked={isAllOEMChecked}
                                            type='checkbox'
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onChange={() => {
                                                setSelectedOEM(isAllOEMChecked ? [] : [...mappedOptions.oemModel.map(d => `${d.oem} ${d.model}`)]);
                                            }}
                                            className='grp__checkbox-accent-color'
                                            data-testid='model-filter-checkbox-all'
                                        />
                                    </div>
                                <Accordion.Header className='custom-header-geofence'>                                    
                                    OEM & Model
                                </Accordion.Header>
                                </div>                                                               
                                <Accordion.Body>
                                    {mappedOptions.oemModel?.map((labelData) => (
                                        <div key={`oem_${labelData.value}`} className='geofance'>
                                            <input
                                                className='grp__checkbox-accent-color'
                                                type='checkbox'
                                                checked={selectedOEM.includes(labelData.value)}
                                                onChange={() => handleFilterChange({ name: 'selectedOEM', value: labelData.value })}
                                                data-testid={`model-filter-checkbox-${labelData?.label}`}
                                            />
                                            <label />
                                            <div className=' geofance_box'>
                                                <p className='text-style-accordian'>{labelData?.label}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>

                            <hr>
                            </hr>

                            <Accordion.Item eventKey='1'>
                            <div className='cityDiv'> 
                                   <div className='groupsCityDiv'>
                                        <input
                                            ref={vehicleTypeCheckboxRef}
                                            checked={isAllVehicleTypeChecked}
                                            type='checkbox'
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setSelectedVehicleType(isAllVehicleTypeChecked ? []  : [...mappedOptions.vehicleCategory?.map(d => `${d.label}`)]);
                                            }}
                                            className='grp__checkbox-accent-color'
                                            data-testid='city-filter-checkbox-all'
                                        />
                                    </div>
                                    <Accordion.Header className='custom-header-geofence'>
                                    Vehicle Category
                                    </Accordion.Header>
                                </div>
                                
                                <Accordion.Body>
                                    {mappedOptions.vehicleCategory?.map((vehicleTypeData) => {

                                        return (
                                            <div key={`city_${vehicleTypeData.value}`} className=' geofance'>
                                                <input
                                                    className='grp__checkbox-accent-color'
                                                    type='checkbox'
                                                    checked={selectedVehicleType.includes(vehicleTypeData.label)}
                                                    onChange={() => handleFilterChange({ name: 'selectedVehicleType', value: vehicleTypeData.label })}
                                                    data-testid={`city-filter-checkbox-${vehicleTypeData?.label}`}
                                                />
                                                <div className=' geofance_box'>
                                                    <p className='text-style-accordian'>{vehicleTypeData?.label}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Accordion.Body>
                            </Accordion.Item>
                            <hr></hr>

                            <Accordion.Item eventKey='2'>
                            <div className='cityDiv'> 
                                   <div className='groupsCityDiv'>
                                        <input
                                            ref={cityCheckboxRef}
                                            checked={isAllCityChecked}
                                            type='checkbox'
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setSelectedCity(isAllCityChecked ? []  : [...mappedOptions.location?.map(d => `${d.label}`)]);
                                            }}
                                            className='grp__checkbox-accent-color'
                                            data-testid='city-filter-checkbox-all'
                                        />
                                    </div>
                                    <Accordion.Header className='custom-header-geofence'>
                                    City
                                    </Accordion.Header>
                                </div>
                                
                                <Accordion.Body>
                                    {mappedOptions.location?.map((cityData) => {        
                                        return (
                                            <div key={`city_${cityData.value}`} className=' geofance'>
                                                <input
                                                    className='grp__checkbox-accent-color'
                                                    type='checkbox'
                                                    checked={selectedCity.includes(cityData.label)}
                                                    onChange={() => handleFilterChange({ name: 'selectedCity', value: cityData.label })}
                                                    data-testid={`city-filter-checkbox-${cityData?.label}`}
                                                />
                                                <div className=' geofance_box'>
                                                    <p className='text-style-accordian'>{cityData?.label}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Accordion.Body>
                            </Accordion.Item>
                            <hr></hr>


                            <Accordion.Item eventKey='3'>
                                <div
                                className='oemAndModelDiv'>

                                <div className='groupsOemDiv'>
                                        <input
                                            ref={vehicleNumberCheckboxRef}
                                            checked={isAllVehicleNumberChecked}
                                            type='checkbox'
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onChange={() => {
                                                setSelectedVehicleNumber(isAllVehicleNumberChecked ? [] : [...filteredVehicleList?.map(d => `${d.registration_number}`)]);
                                            }}
                                            className='grp__checkbox-accent-color'
                                            data-testid='model-filter-checkbox-all'
                                        />
                                    </div>
                                <Accordion.Header className='custom-header-geofence'>                                    
                                   Vehicle No.
                                </Accordion.Header>
                                </div>                                                               
                                <Accordion.Body>
                                    {filteredVehicleList?.map((labelData ) => (
                                       
                                        
                                        <div key={`oem_${labelData.registration_number}`} className='geofance'>
                                            <input
                                                className='grp__checkbox-accent-color'
                                                type='checkbox'
                                                checked={selectedVehicleNumber.includes(labelData.registration_number)}
                                                onChange={() => handleFilterChange({ name: 'selectedVehicleNumber', value: labelData.registration_number })}
                                                data-testid={`model-filter-checkbox-${labelData?.registration_number}`}
                                            />
                                            <label />
                                            <div className=' geofance_box'>
                                                <p className='text-style-accordian'>{labelData?.registration_number}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>

                            <hr>
                            </hr>


                            <Accordion.Item eventKey='4'>
                                <div
                                className='oemAndModelDiv'>

                                <div className='groupsOemDiv'>
                                        <input
                                            ref={chargingTypeCheckboxRef}
                                            checked={isAllChargingTypeChecked}
                                            type='checkbox'
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onChange={() => {
                                                setSelectedChargingType(isAllChargingTypeChecked ? [] : [...mappedOptions.chargingType?.map(d => `${d.charging_type}`)]);
                                            }}
                                            className='grp__checkbox-accent-color'
                                            data-testid='model-filter-checkbox-all'
                                        />
                                    </div>
                                <Accordion.Header className='custom-header-geofence'>                                    
                                Charging Type
                                </Accordion.Header>
                                </div>                                                               
                                <Accordion.Body>
                                    {mappedOptions.chargingType?.map((labelData ) => (
                                       
                                        
                                        <div key={`oem_${labelData.charging_type}`} className='geofance'>
                                            <input
                                                className='grp__checkbox-accent-color'
                                                type='checkbox'
                                                checked={selectedChargingType.includes(labelData.charging_type)}
                                                onChange={() => handleFilterChange({ name: 'selectedChargingType', value: labelData.charging_type })}
                                                data-testid={`model-filter-checkbox-${labelData?.charging_type}`}
                                            />
                                            <label />
                                            <div className=' geofance_box'>
                                                <p className='text-style-accordian'>{labelData?.charging_type}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>

                            <hr>
                            </hr>

                         
                        </Accordion>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                            }}
                        >
                            <span
                                onClick={handleApplyFilter}
                                data-testid='apply-filter-button'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#22046B',
                                    color: 'white',
                                    padding: 4,
                                    paddingLeft: 13,
                                    paddingRight: 13,
                                    borderRadius: 3,
                                    fontSize: 15,
                                    cursor: 'pointer'
                                }}
                            >
                                Apply
                            </span>
                            <span
                                onClick={handleRevertFilter}
                                data-testid='reset-filter-button'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#EFF3F8',
                                    color: '#22046B',
                                    padding: 4,
                                    paddingLeft: 13,
                                    paddingRight: 13,
                                    borderRadius: 3,
                                    fontSize: 15,
                                    cursor: 'pointer'
                                }}
                            >
                                Reset
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sub__group__table">
                <div className="bg-white mb-1 position-relative groups__table--wrapper">
                    <Table borderless className="mt-4" id="groups__add__table">
                        <thead>
                            <tr>
                                <th className="text-nowrap">
                                    <input
                                        className='grp__checkbox-accent-color'
                                        ref={selectAllVehicleRef}
                                        type="checkbox"
                                        checked={selectedAllVehicle}
                                        onChange={() => onSelectAllChange(!selectedAllVehicle, selectedAllVehicle ? [] : filteredVehicleList.map(v => v.registration_number))}
                                        data-testid='vehicle-select-all'
                                    />
                                </th>
                                <th className="text-nowrap">
                                    <p className="fw500">Vehicle No</p>
                                </th>
                                <th className="text-nowrap">
                                    <p className="fw500">OEM & Model</p>
                                </th>
                                <th className="text-nowrap">
                                    <p className="fw500">Location</p>
                                </th>
                                <th className="text-nowrap">
                                    <p className="fw500">Vehicle Category</p>
                                </th>
                                <th className="text-nowrap">
                                    <p className="fw500">Charging Type</p>
                                </th>
                                {['MOVE_VEHICLE', 'EDIT'].includes(activeState) && ["EDIT_GROUP", "EDIT_SUBGROUP"].includes(mode) ? (
                                    <th className="text-nowrap">
                                        <p className="fw500">Action</p>
                                    </th>
                                ) : null}
                            </tr>
                        </thead>

                        <tbody className="alert-configuration-table-body grp__add-table-body">
                            {paginatedVehicleList.map((vehicle, vehicleIndex) => {

            // const matchingVehicleData .find(
            //     (data) => data.rn === vehicle.registration_number
            // );


            // // const chargingType = matchingVehicleData?.charging_type || 'NA';


                return (
                                                
                    <tr
                        key={`vehicle-row-${vehicleIndex}`}
                        className={selectedVehicleIds.includes(vehicle?.registration_number) ? 'group__active_tr' : ''}
                    >
                        <td className="select-col">
                            <input
                                type="checkbox"
                                checked={selectedVehicleIds.includes(vehicle?.registration_number)}
                                onChange={() => onVehicleChange(vehicle?.registration_number)}
                                className='grp__checkbox-accent-color'
                                data-testid={`vehicle-select-${vehicle.registration_number}`}
                            />
                        </td>
                        <td>{vehicle.registration_number}</td>
                        <td>{`${vehicle.oem} ${vehicle.model}`}</td>
                        <td>{vehicle.location}</td>
                        <td>{vehicle.type}</td>
                        <td>{vehicle.charging_type}</td>
                        {['MOVE_VEHICLE', 'EDIT'].includes(activeState) && ["EDIT_GROUP", "EDIT_SUBGROUP"].includes(mode) ? (
                            <td className="ps-3 text-wrap">
                                <div className="d-flex justify-content-center gap-2">
                                    <button
                                        className={`row-btn ${!(selectedVehicleIds.length === 1 && selectedVehicleIds.includes(vehicle?.registration_number)) && 'disabledbutton'}`}
                                        disabled={
                                            !(selectedVehicleIds.length === 1 && selectedVehicleIds.includes(vehicle?.registration_number))
                                        }
                                        onClick={onDeleteVehicleClick}
                                        data-testid={`vehicle-delete-${vehicle.registration_number}`}
                                    >
                                        <BsTrash />
                                    </button>

                                    {mode === "EDIT_GROUP" && (
                                        <div className='position-relative'>
                                            {/* <button
                                                className={`row-btn ${!(selectedVehicleIds.length === 1 && selectedVehicleIds.includes(vehicle?._id)) && 'disabledbutton'}`}
                                                disabled={
                                                    !(selectedVehicleIds.length === 1 && selectedVehicleIds.includes(vehicle?._id))
                                                }
                                                onClick={(e) => 
                                                {
                                                    e.stopPropagation()
                                                    setSubgroupPopup(subgroupPopup === vehicle._id ? null : vehicle._id)
                                                }}
                                                data-testid={`vehicle-move-${vehicle.registration_number}`}
                                            >
                                                <RiFolderTransferLine />
                                            </button> */}
                                            {subgroupPopup === vehicle._id && (
                                                <div className='popupGroupsData subgroup-popup'>
                                                    <ul className='popupSubGroups_ul'>
                                                        <li className='grp__add_vehicle_action' onClick={(e) => {
                                                            setSubgroupPopup(null);
                                                            onCreateSubgroupClick()
                                                        }}
                                                        data-testid={`create-subgroup-${vehicle.registration_number}`}
                                                        >Create sub group</li>
                                                    </ul>
                                                    <ul className='popupSubGroups_ul'>
                                                        <li
                                                            onClick={() => {
                                                                setSubgroupPopup(null)
                                                                onExistingSubgroupClick()
                                                            }}
                                                            data-testid={`add-existing-subgroup-${vehicle.registration_number}`}
                                                        >
                                                            Add in Existing Sub Group
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </td>
                        ) : null}
                    </tr>
                )
                            }
                            
                           )}
                        </tbody>
                    </Table>
                </div>

               

                    <div>
                    {isShowModal && (
                        <BrowseAndUploadFileModal
                        showMoal={isShowModal}
                        setIsShowModal={setIsShowModal}
                        onFileUpload={handleFileUpload}
                        />
                    )}
                    </div>

                <div className='d-flex justify-content-end align-items-center flex-row '>
                   <div style={{marginRight:'14rem'}}>
                   <button
                        disabled={['MOVE_VEHICLE', 'EDIT'].includes(activeState) && ["EDIT_GROUP", "EDIT_SUBGROUP"].includes(mode) ? false : !selectedVehicleIds.length}
                        onClick={onSaveClick}
                        className={`create-btn me-4 ${(selectedVehicleIds.length || (['MOVE_VEHICLE', 'EDIT'].includes(activeState) && ["EDIT_GROUP", "EDIT_SUBGROUP"].includes(mode))) ? '' : 'disabledbutton'}`}
                        data-testid='vehicle-save-button'
                    >
                        Save
                    </button>

                    <button
                        onClick={onCancelClick}
                        className='groups-cancel-btn'
                        data-testid='vehicle-cancel-button'
                    >
                        Cancel
                    </button>
                   </div>

                    <Pagination
                    disabled={false}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setPage={setCurrentPage}
                    data-testid='group-vehicle'
                    />
                </div>
            </div>
        </>
    )
}

export default React.forwardRef(GPAddVehicleTable)