import React from 'react';
import Form from 'react-bootstrap/Form';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './sidepanelDark.css'


const SubMenuPopupDark = ({ onMouseEnter, onMouseLeave, id, items, showPopup, menuId }) => {
	let menuTopMargin = 130;
	let menuBoxHeight = 65; 

	
	if (menuId === "652b70a4bde922959168f19d") {
		menuTopMargin = 150;
		menuBoxHeight = 64;
	} 

	const dispatch = useDispatch();
	const selectedCity = useSelector((s) => s?.cityLocationSelectReducer?.selectedCity);
	const activePagePath = useLocation();
	const calculateArrowPosition = (id) => {
		const divHeight = calculateDivHeight();
		const position = menuTopMargin + (id - 1) * menuBoxHeight;
		// Calculate bottom position for:
		// Geofence, RA User, Customization
		if (['652b70a4bde922959168f19d', '652b70a4bde922959168f1a2', '652b70a4bde922959168f1a7'].includes(menuId)) {
			return position - (divHeight - 30)
		}
		return position;
	};

	const calculateDivHeight = () => {
		switch (menuId) {
			// Record
			case '652b70a2472f1590f49856db': {
				return 360;
			}
			// Alert
			case '652b70a2472f1590f49856w2': {
				return 135;
			}
			// Analytics:
			case '652b70a2472f1590f49856h7': {
				return 190;
			}
			// Fleet:
			case '652b70a2472f1590f49856f2': {
				return 135;
			}
			// Geofence:
			case '652b70a4bde922959168f19d': {
				return 130;
			}
			// RA User:
			case '652b70a4bde922959168f1a2': {
				return  190;
			}
			default: {
				return 75;
			}
		}
	};

	const calculateDivWidth = () => {
		return 'max-content';
	};

	const calculateDivPosition = (id) => {
		const position = menuTopMargin + (id * menuBoxHeight - 22.5);
		return position - 26;
	};

	let subMenuState = {
		submenuPosition: calculateArrowPosition(id),
		arrowPosition: ['652b70a4bde922959168f19d', '652b70a4bde922959168f1a2', '652b70a4bde922959168f1a7'].includes(menuId) ? { bottom: 0 } : { top: 0 },
		showPopup: showPopup,
		subMenuDivHeight: calculateDivHeight(items),
		subMenuWidth: calculateDivWidth(),
		divTopPosition: calculateDivPosition(id),
		items: items,
	};

	return (
		<>
			<div
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className='popover-container'
				style={{
					top: subMenuState.submenuPosition + 'px',
					height: subMenuState.subMenuDivHeight,
					display: subMenuState.showPopup ? 'block' : 'none',
				}}
			>
				<div className='arrow-dark' style={{ display: subMenuState.showPopup ? 'block' : 'block', ...subMenuState.arrowPosition }}></div>
				<div
					className='custom-div-dark'
					style={{
						height:'auto',
						// height: subMenuState.subMenuDivHeight + 'px',
						display: subMenuState.showPopup ? 'block' : 'none',

					}}
				>
					<ul style={{ paddingLeft: '5px' }}>
						{items.map((item, index) => {
							return (
								<React.Fragment key={index}>
									{item.isSelect ? (
										<li>
											<Form.Check
												className='location-checkbox'
												type='radio'
												label={<span className='submenu-dark-label'>{item.name}</span>}
												id={index + 1}
												name='selectCity'
												value={item.name}
												checked={selectedCity === item.name}
												onChange={() => {
													dispatch({ type: 'location_select', payload: item.name });
												}}
											/>
										</li>
									) : (
										<NavLink
											to={item.path}
											key={index + 1}
											className={`sub-link ${activePagePath.path === item.path ? 'active-submenu' : ''
												}`}
											{...{ 'data-testid': item.name !== 'Logout' ? `sidebar-submenu-link-${item.name}` : undefined }}
										>
											{item.name === 'Logout' ?
												<li
													className={`submenu-dark-label ${activePagePath.pathname === item.path ? 'active-submenu' : ''
														}`}
													style={{ padding: '2px', fontSize: '11px' }}
													onClick={() => dispatch({ type: 'logoutClicked', payload: true })}
													data-testid='sidebar-submenu-logout'
												>
													{item.name}
												</li>
												:
												<li
													className={`submenu-dark-label ${activePagePath.pathname === item.path ? 'active-submenu' : ''
														}`}
													style={{ padding: '2px', fontSize: '11px' }}
												>
													{item.name}
												</li>
											}
										</NavLink>
									)}
								</React.Fragment>
							);
						})}
					</ul>
				</div>
			</div>
		</>
	);
};

export default SubMenuPopupDark;
