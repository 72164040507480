import api from '../../../service/api';
import API_BOOK from '../../../service/endpoints';
import ActionTypes from '../../actionTypes';
import { fileDownloader, getFormattedDate } from '../../../utils/common-methods';
const getGeoFanceTypeList = ActionTypes.GEO_FENCE_REPORT;
const getGeoFanceApiConfig = API_BOOK.GEO_FENCE.GEO_FENCE_REPORTS;

const getGeoFanceReport = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_REPORT_SUCCESS,
	payload,
});

const getGeoFanceHistoryReport = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_REPORT_HISTORY_SUCCESS,
	payload,
});


const getGeoFanceOwners = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_OWNERS_SUCCESS,
	payload,
});

const getGeoFanceLabelOwners = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_LABEL_OWNERS_SUCCESS,
	payload,
});

const getGeoFanceLabel = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_LABEL_SUCCESS,
	payload,
});

const getGeoFanceCity = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_CITY_SUCCESS,
	payload,
});
const getGeoFanceName = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_NAME_SUCCESS,
	payload,
});

const getUniqueGeoFanceName = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_UNIQUE_NAME_SUCCESS,
	payload,
});

const getUniqueGeoFanceOwners = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_UNIQUE_OWNERS,
	payload,
});

const getUniqueGeoFanceLabels = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_UNIQUE_LABELS,
	payload,
});

const getUniqueGeoFanceLabelOwners = (payload) => ({
	type: getGeoFanceTypeList.GEO_FANCE_UNIQUE_LABEL_OWNERS,
	payload,
});

export const resetGeoFanceReport = () => ({
	type: getGeoFanceTypeList.RESET_GEO_FANCE_REPORT
})


/**
 *  @desc         Telematics API for getting Geofence reports
 *  @author       Ankit Kushwaha
 *  @api          /geo-fence/alert/report
 *  @method       POST
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/
export const getGeoFanReports =
	({
		fromDate = new Date(),
		toDate = new Date(),
		pageSize = 8,
		pageIndex = 0,
		fenceIds = [],
		vehicleRnNumbers = [],
		fenceTypes = [],
		fenceOwnerTypes = [],
		fenceOwnerNames = [],
		cities = [],
	}) =>
	async (dispatch) => {
		
		try {
			
			const res = await api({
				url: getGeoFanceApiConfig.GEO_FENCE_REPORT.url,
				method: getGeoFanceApiConfig.GEO_FENCE_REPORT.method,
				data: {
					fromDate,
					toDate,
					pageSize,
					pageIndex,
					fenceIds,
					vehicleRnNumbers,
					fenceTypes,
					fenceOwnerTypes,
					fenceOwnerNames,
					cities,
				},
			});
		
			if (res.status === 200) {
				const response = res?.data?.data;
				
				dispatch(getGeoFanceReport(response));
			}
		} catch (error) {
			console.log('Error getting ', error);
		}
	};

/**
 *  @desc         Telematics API for history report of geo fence 
 *  @author       Ankit Kushwaha
 *  @api         /geo-fence/alert/report
 *  @method       POST
 *  @createdDate  AUG 16, 2023
 *  @modifiedDate AUG 18, 2023
 **/

export const getGeoFanceHistoryReports =
({
	fromDate = new Date(),
	toDate = new Date(),
	pageSize = 10,
	pageIndex = 0,
	fenceIds = [],
	vehicleRnNumbers = [],
	fenceTypes = [],
	fenceOwnerTypes = [],
	fenceOwnerNames = [],
	cities = [],
}) =>
async (dispatch) => {
	
	try {
		
		const res = await api({
			url: getGeoFanceApiConfig.GEO_FENCE_REPORT.url,
			method: getGeoFanceApiConfig.GEO_FENCE_REPORT.method,
			data: {
				fromDate,
				toDate,
				pageSize,
				pageIndex,
				fenceIds,
				vehicleRnNumbers,
				fenceTypes,
				fenceOwnerTypes,
				fenceOwnerNames,
				cities,
			},
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;
			
			dispatch(getGeoFanceHistoryReport(response));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
};


/**
 *  @desc         Telematics API for history report of geo fence owners
 *  @author       Ankit Kushwaha
 *  @api          /geo-fence/configuration/fence-owners
 *  @method       GET
 *  @createdDate  AUG 16, 2023
 *  @modifiedDate AUG 18, 2023
 **/

export const GetGeoFanceOwners = () => async (dispatch) => {
	try {
		const res = await api({
			url: getGeoFanceApiConfig.GEO_FENCE_OWNERS.url,
			method: getGeoFanceApiConfig.GEO_FENCE_OWNERS.method
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;
			const labelData = response.map((item) => ({
				label: item.label,
				value: item.value,
				_id: item._id
			}));
			dispatch(getGeoFanceOwners(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}

/**
 *  @desc         Telematics API for unique geofence owners
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /geo-fence/configuration/unique-fence-owners
 *  @method       GET
 *  @createdDate  DEC 16, 2023
 *  @modifiedDate DEC 18, 2023
 **/

export const GetUniqueGeoFanceOwners = () => async (dispatch) => {
	try {
		const res = await api({
			url: getGeoFanceApiConfig.UNIQUE_GEO_FENCE_OWNERS.url,
			method: getGeoFanceApiConfig.UNIQUE_GEO_FENCE_OWNERS.method
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;
			const labelData = response.map((item) => ({
				label: item.label,
				value: item.value,
				_id: item._id
			}));
			dispatch(getUniqueGeoFanceOwners(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}

/**
 *  @desc         Telematics API for history report of geo fence label owers
 *  @author       Ankit Kushwaha
 *  @api          /geo-fence/configuration/label-owners
 *  @method       GET
 *  @createdDate  AUG 16, 2023
 *  @modifiedDate AUG 18, 2023
 **/

export const GetGeoFanceLabelOwners = () => async (dispatch) => {	
	try {
		const res = await api({
			url: getGeoFanceApiConfig.GEO_FENCE_LABEL_OWNERS.url,
			method: getGeoFanceApiConfig.GEO_FENCE_LABEL_OWNERS.method
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;			
			const labelData = response.map((item) => ({
				label: item.label,
				value: item.value,
				_id: item._id
			}));
			dispatch(getGeoFanceLabelOwners(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}

/**
 *  @desc         Telematics API for getting Unique geofence label owners
 *  @author       Nilesh Salunkhe
 *  @api          /geo-fence/configuration/unique-label-owners
 *  @method       GET
 *  @createdDate  DEC 16, 2023
 *  @modifiedDate DEC 18, 2023
 **/


export const GetUniqueGeoFanceLabelOwners = () => async (dispatch) => {	
	try {
		const res = await api({
			url: getGeoFanceApiConfig.UNIQUE_GEO_FENCE_LABEL_OWNERS.url,
			method: getGeoFanceApiConfig.UNIQUE_GEO_FENCE_LABEL_OWNERS.method
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;			
			const labelData = response.map((item) => ({
				label: item.label,
				value: item.value,
				_id: item._id
			}));
			dispatch(getUniqueGeoFanceLabelOwners(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}

/**
 *  @desc         Telematics API for getting geo fence label
 *  @author       Ankit Kushwaha
 *  @api          /geo-fence/configuration/labels
 *  @method       GET
 *  @createdDate  AUG 16, 2023
 *  @modifiedDate AUG 18, 2023
 **/

export const GetGeoFanceLabel = () => async (dispatch) => {
	try {
		const res = await api({
			url: getGeoFanceApiConfig.GEO_FENCE_LABLE.url,
			method: getGeoFanceApiConfig.GEO_FENCE_LABLE.method
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;

			const labelData = response.map((item) => ({
				label: item.label,
				value: item.value,
				_id: item._id
			}));


			dispatch(getGeoFanceLabel(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}



/**
 *  @desc         Telematics API for getting Unique geofence labels
 *  @author       Nilesh Salunkhe
 *  @api         /geo-fence/configuration/unique-labels
 *  @method       GET
 *  @createdDate  DEC 16, 2023
 *  @modifiedDate DEC 18, 2023
 **/


export const GetUniqueGeoFanceLabel = () => async (dispatch) => {
	try {
		const res = await api({
			url: getGeoFanceApiConfig.UNIQUE_GEO_FENCE_LABLE.url,
			method: getGeoFanceApiConfig.UNIQUE_GEO_FENCE_LABLE.method
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;

			const labelData = response.map((item) => ({
				label: item.label,
				value: item.value,
				_id: item._id
			}));


			dispatch(getUniqueGeoFanceLabels(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}

/**
 *  @desc         Telematics API for getting geo fence city
 *  @author       Ankit Kushwaha
 *  @api          /geo-fence/get-distinct-cities
 *  @method       GET
 *  @createdDate  AUG 16, 2023
 *  @modifiedDate AUG 18, 2023
 **/

export const GetGeoFanceCity = () => async (dispatch) => {
	try {
		const res = await api({
			url: getGeoFanceApiConfig.GEO_FENCE_CITY.url,
			method: getGeoFanceApiConfig.GEO_FENCE_CITY.method,
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;
			const labelData = response.map((item) => ({
				label: item,
				value: item,
				
			}));
			dispatch(getGeoFanceCity(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}

/**
 *  @desc         Telematics API for getting  geofence name
 *  @author       Nilesh Salunkhe
 *  @api         /geo-fence/fence-name
 *  @method       GET
 *  @createdDate  DEC 16, 2023
 *  @modifiedDate DEC 18, 2023
 **/

export const GetGeoFanceName = () => async (dispatch) => {
	try {
		const res = await api({
			url: getGeoFanceApiConfig.GEO_FENCE_NAME.url,
			method: getGeoFanceApiConfig.GEO_FENCE_NAME.method
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;			
			const labelData = response.map((item) => ({
				label: item.fence_name,
				value: item.fence_name,
				_id: item._id
			}));
			dispatch(getGeoFanceName(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}


/**
 *  @desc         Telematics API for getting Unique geofence name
 *  @author       Nilesh Salunkhe
 *  @api         /geo-fence/unique-fence-names
 *  @method       GET
 *  @createdDate  DEC 16, 2023
 *  @modifiedDate DEC 18, 2023
 **/


export const GetUniqueGeoFanceName = () => async (dispatch) => {
	try {
		const res = await api({
			url: getGeoFanceApiConfig.GEO_UNIQUE_FENCE_NAME.url,
			method: getGeoFanceApiConfig.GEO_FENCE_NAME.method,
		});
	
		if (res.status === 200) {
			const response = res?.data?.data;			
			const labelData = response.map((item) => ({
				label: item.fence_name,
				value: item.fence_name,
				_id: item._id
			}));
			dispatch(getUniqueGeoFanceName(labelData));
		}
	} catch (error) {
		console.log('Error getting ', error);
	}
}

/**
 *  @desc         Telematics API for Export geo fence alerts report api action
 *  @author       Deepak Prajapati
 *  @api          /geo-fence/alert/export
 *  @method       POST
 *  @createdDate  01-Sep-2023
 *  @modifiedDate 01-Sep-2023
 **/
export const ExportGeoFenceAlertsReport = ({
	fromDate = new Date(),
	toDate = new Date(),
	fenceIds = [],
	vehicleRnNumbers = [],
	fenceTypes = [],
	fenceOwnerTypes = [],
	fenceOwnerNames = [],
	cities = []
}) => async () => {
  try {
    const resp = await api({
      method: getGeoFanceApiConfig.GEO_FENCE_EXPORT_ALERTS.method,
      url: getGeoFanceApiConfig.GEO_FENCE_EXPORT_ALERTS.url,
      responseType: 'arraybuffer',
      data: {
			fromDate,
			toDate,
			fenceIds,
			vehicleRnNumbers,
			fenceTypes,
			fenceOwnerTypes,
			fenceOwnerNames,
			cities,
        timezoneOffset: new Date().getTimezoneOffset()
       }
    });
  
    if (resp.status === 200) {
      const response = resp?.data
      fileDownloader({ arrayBuffer: response, fileName: `geo_fence_alerts_report_${getFormattedDate(new Date()).replace(/,/g, '' ).replace(':', ' ').split(' ').join('_')}.xlsx` })
    }
  } catch (error) {
    console.log("error:", error);
  }
};

