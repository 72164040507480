import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import geofenceAction from '../../../store/actions/geofence';
import { mapper } from '../../../utils/mapper';

const withGeofenceAlertHOC = (WrappedComponent) => {
    const GeofenceAlertHOC = (props) => {
        const dispatch = useDispatch();
        const masters = useSelector(state => state.geofence.masters);
        const list = useSelector(state => state.geofence.list)
        
        const fetchMasters = async ({ force = false } = {}) => {
            if (force || !masters) {
                const result = await geofenceAction.getMasters();

                const masters = result.data.data.masters
                const masterMap = {}

                masters.forEach(m => {
                    if (!masterMap[m.type]) {
                        masterMap[m.type] = []
                    }

                    masterMap[m.type].push(m)
                })

                dispatch(geofenceAction.setMasters(masterMap))
            }
            return true
        }

        const fetchGeofences = async ({ force = false } = {}) => {
            if (force || !list?.length) {
                const result = await geofenceAction.getGeofences();

                const geofences = result.data.data

                dispatch(geofenceAction.setGeofences(geofences))
            }
            return true
        }

        useEffect(() => {
            fetchGeofences().then(fetchMasters)
            // eslint-disable-next-line
        }, [dispatch]);

        if (!masters) {
            return <></>
        }

        const mappedGeofences = mapper?.mapGeofencesWithMasters(list, masters)

        return <WrappedComponent
            {...props}
            masters={masters || {}}
            geofences={mappedGeofences || []}
        />
    };

    return GeofenceAlertHOC;
};

export default withGeofenceAlertHOC;
