
import ActionTypes from "../../actionTypes";
const geofenceActionTypes = ActionTypes.GEO_FENCE_REPORT;

const initialState = {
  geoFanceTypeData: [],
  geoFancecCityTypeData: [],
  geoFanceLabelTypeTypeData: [],
  geoFanceLabelOwnerTypeData: [],
  geoFanceReportData: [],
  geoFanceReportDataCount: 0,
  geoFanceReportHistoryData: [],
  geoFanceReportHistoryDataCount: 0,
  geoFanceReportHistoryDataLoading: false,
  geoFanceReportDataLoading: true,
  geoFanceOwnersData: [],
  geoFanceOwnersDataLoading: true,
  geoFanceLabelOwnersData: [],
  geoFanceLabelOwnersDataLoading: true,
  geoFanceLabelData: [],
  geoFanceLabelDataLoading: true,
  geoFanceCityData: [],
  geoFanceCityDataLoading: true,
  geoFanceNameData: [],
  geoFanceNameDataLoading: true,
  uniqueGeoFanceNameData: [],
  uniqueGeoFanceOwnersData: [],
  uniqueGeoFanceLabelsData: [],
  uniqueGeoFanceLabelOwnersData: [],
};
const geoFanceReportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case geofenceActionTypes.RESET_GEO_FANCE_REPORT: {
      return {
        ...state,
        geoFanceReportHistoryData: initialState.geoFanceReportHistoryData,
        geoFanceReportHistoryDataCount: initialState.geoFanceReportHistoryDataCount,
        geoFanceOwnersData: initialState.geoFanceOwnersData,
        geoFanceLabelOwnersData: initialState.geoFanceLabelOwnersData,
        geoFanceLabelData: initialState.geoFanceLabelData,
        geoFanceCityData: initialState.geoFanceCityData,
        geoFanceNameData: initialState.geoFanceNameData,
      }
    }

    case geofenceActionTypes.GEO_FANCE_REPORT_SUCCESS:
      return {
        ...state,
        geoFanceReportData: payload.rows || [],
        geoFanceReportDataCount: payload.count || 0,
        geoFanceReportDataLoading: false,
      };

      case geofenceActionTypes.GEO_FANCE_REPORT_HISTORY_SUCCESS:
        return {
          ...state,
          geoFanceReportHistoryData: payload.rows || [],
          geoFanceReportHistoryDataCount: payload.count || 0,
          geoFanceReportDataLoading: false,
        };

      case geofenceActionTypes.GEO_FANCE_OWNERS_SUCCESS :
        return {
          ...state,
          geoFanceOwnersData: payload,
          geoFanceOwnersDataLoading: false,
        };
      case geofenceActionTypes.GEO_FANCE_LABEL_OWNERS_SUCCESS :
        return {
          ...state,
          geoFanceLabelOwnersData: payload,
          geoFanceLabelOwnersDataLoading: false,
        };
      case geofenceActionTypes.GEO_FANCE_LABEL_SUCCESS :
        return {
          ...state,
          geoFanceLabelData: payload,
          geoFanceLabelDataLoading: false,
        };
      case geofenceActionTypes.GEO_FANCE_CITY_SUCCESS :
        return {
          ...state,
          geoFanceCityData: payload,
          geoFanceCityDataLoading: false,
        };

        case geofenceActionTypes.GEO_FANCE_NAME_SUCCESS :
          return {
            ...state,
            geoFanceNameData: payload,
            geoFanceNameDataLoading: false,
          };
        
        case geofenceActionTypes.GEO_FANCE_UNIQUE_NAME_SUCCESS :
          return {
            ...state,
            uniqueGeoFanceNameData : [...payload]
          };
        
        case geofenceActionTypes.GEO_FANCE_UNIQUE_OWNERS :
          return {
            ...state,
            uniqueGeoFanceOwnersData : [...payload]
          };
        
        case geofenceActionTypes.GEO_FANCE_UNIQUE_LABELS :
          return {
            ...state,
            uniqueGeoFanceLabelsData : [...payload]
          };
        
        case geofenceActionTypes.GEO_FANCE_UNIQUE_LABEL_OWNERS :
          return {
            ...state,
            uniqueGeoFanceLabelOwnersData : [...payload]
          };

    default:
      return state;
  }
};





export default geoFanceReportReducer;