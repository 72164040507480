import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./UploadFileModel.module.css";
import FileUploadProgress from "../../../../../pages/groups/gp-tables/gp-add-vehicle-table/child components/file upload progress bar/FileUploadProgress";
import FileDrawer from "../../../../../pages/groups/gp-tables/gp-add-vehicle-table/child components/file drawer/FIleDrawer";

const UploadFileModel = ({
  showUploadModal,
  setShowUploadModal,
  handleFileUpload,  
}) => {
  const [file, setFile] = useState(null);

  const closeModal = useCallback(() => {
    setShowUploadModal(false);
  }, [setShowUploadModal]);

  return (
    <Modal
      show={showUploadModal}
      onHide={closeModal}
      className={styles.UploadFileContainer}
    >
      <Modal.Header className={styles.modelHeader}>
        <Modal.Title>Upload File</Modal.Title>
        <div className={styles.modelHeaderClose}>
          <img
            src="/images/geofenceModuleIcon/closeBtn.svg"
            alt="close"
            onClick={closeModal}
          />
        </div>

        <div>
          <FileDrawer
            setFile={setFile}  // Sets the file to the state
            data-testid="vehicle-onboarding-file-drawer"
          />
          {file && (
            <FileUploadProgress
              setFile={setFile}
              file={file}  // Passes file to progress bar
              data-testid="vehicle-onboarding-file-progress"
            />
          )}
        </div>
      </Modal.Header>
      <Modal.Body></Modal.Body>
      <div className={styles.modelFooter}>
        <button
          variant="secondary"
          onClick={closeModal}
          className={styles.modelCancelButton}
        >
          Cancel
        </button>
      
        <button
          onClick={() => handleFileUpload(file)}  
          variant="secondary"
          className={styles.modelSaveButton}
          disabled={!file}  
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default UploadFileModel;
