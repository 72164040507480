/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { MapContainer, TileLayer, FeatureGroup, useMap, Marker } from 'react-leaflet'
import L, { Icon } from 'leaflet'
import 'leaflet-draw'
import 'leaflet-geometryutil'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'

import GFPlacesAutocomplete from '../gf-places-autocomplete/GFPlacesAutocomplete'

import styles from './GFCreateMap.module.css'

// Set default icon for Leaflet markers
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: require('../../../../../assets/GeoFencingIcons/shapes/pinPoint.png'),
    iconRetinaUrl: require('../../../../../assets/GeoFencingIcons/shapes/pinPoint.png'),
    iconSize: [30, 30]
});

const locationPinIcon = new Icon({
    iconUrl: require('../../../../../assets/GeoFencingIcons/shapes/locationPin.png'),
    iconRetinaUrl: require('../../../../../assets/GeoFencingIcons/shapes/locationPin.png'),
    iconSize: [35, 35]
})

const logger = (message, data) => {
    console.log(`[GFCreateMap] ${message}`, data)
}

const DrawControl = ({ enableMap, featureGroupRef, drawControlRef, editToolRef, controlRefs, onLayerCreation, onLayerEdition }) => {
    const map = useMap()

    useEffect(() => {
        if (!drawControlRef.current) {
            logger('features created', featureGroupRef.current, map)
            drawControlRef.current = new L.Control.Draw({
                draw: {
                    polygon: true,
                    circle: true,
                    rectangle: true,
                    marker: false,
                    polyline: false,
                    circlemarker: false,
                },
                edit: {
                    featureGroup: featureGroupRef.current,
                    edit: false,
                    remove: false,
                    poly: {
                        allowIntersection: false
                    }
                },
            })

            editToolRef.current = new L.EditToolbar.Edit(map, { featureGroup: featureGroupRef.current })

            map.addControl(drawControlRef.current)

            map.on(L.Draw.Event.CREATED, (e) => {
                featureGroupRef.current.addLayer(e.layer)
                onLayerCreation(e)
            })

            map.on(L.Draw.Event.EDITED, (e) => {
                onLayerEdition(e)
            })

            controlRefs.rectangle.current = new L.Draw.Rectangle(map)
            controlRefs.circle.current = new L.Draw.Circle(map)
            controlRefs.polygon.current = new L.Draw.Polygon(map)
        }

        return () => {
            if (map && drawControlRef.current) {
                map.removeControl(drawControlRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, featureGroupRef.current])

    useEffect(() => {
        if (enableMap) {
            map._handlers.forEach((h) => h.enable())
        } else {
            map._handlers.forEach((h) => h.disable())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableMap])

    return null
}

const GFCreateMap = (props) => {
    const { mapRef, mapConfig, setMapConfig, enableMap, onGeofenceChange } = props

    const [selectedShape, setSelectedShape] = useState(null)
    const [geofenceData, setGeofenceData] = useState(null)
    const [searchPlaceData, setSearchPlaceData] = useState({ address: '', latLong: {} })
    const [actionState, setActionState] = useState({
        shapeSelected: false,
        shapeCreated: false,
        editEnabled: false,
        editSaved: false,
    })

    const featureGroupRef = useRef()
    const drawControlRef = useRef(null)
    const editToolRef = useRef(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const controlRefs = {
        rectangle: useRef(null),
        circle: useRef(null),
        polygon: useRef(null)
    }

    useEffect(() => {
        updateGeofenceData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geofenceData])

    const handleShapeSelect = useCallback((shape) => {
        logger('shape change', { shape, selectedShape, actionState })
        if (shape === selectedShape) { 
            if (!actionState.shapeCreated) {
                controlRefs[shape.toLowerCase()].current.disable()
                setActionState((prevState) => ({ ...prevState, shapeSelected: false }))
                setSelectedShape(null)
            }
        } else {
            if (selectedShape) {
                controlRefs[selectedShape.toLowerCase()].current.disable()
            }
            if (featureGroupRef.current) {
                featureGroupRef.current.clearLayers()
            }
            controlRefs[shape.toLowerCase()].current.enable()

            setGeofenceData(null)
            setActionState((prevState) => ({ ...prevState, shapeSelected: true, shapeCreated: false }))
            setSelectedShape(shape)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureGroupRef, selectedShape, actionState])

    const handleLayerCreation = useCallback((e) => {
        const { layer, layerType } = e
        logger('shape layer created', { layer, layerType })
        if (['rectangle', 'circle', 'polygon'].includes(layerType)) {
            setGeofenceData({ layer, layerType })
            setActionState((prevState) => ({ ...prevState, shapeCreated: true }))
        }

        controlRefs[layerType].current.disable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShape, controlRefs, actionState, geofenceData])

    const handleLayerEdition = useCallback((e) => {
        logger('edit saved', e)
        const layers = e.layers
        layers.eachLayer(layer => {
            if (layer instanceof L.Rectangle) {
                setGeofenceData((prevState) => ({ ...prevState, layer }))
            }

            if (layer instanceof L.Circle) {
                setGeofenceData((prevState) => ({ ...prevState, layer }))
            }

            if (layer instanceof L.Polygon) {
                setGeofenceData((prevState) => ({ ...prevState, layer }))
            }

            if (layer instanceof L.Marker) {
                const coordinates = layer.getLatLng()
                setGeofenceData((prevState) => ({ ...prevState, markerCoordinates: coordinates }))
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geofenceData])

    const handleEditClick = useCallback(() => {
        if (featureGroupRef.current && geofenceData && !actionState.editEnabled) {
            setActionState((prevState) => ({ ...prevState, editEnabled: true }))
            editToolRef.current.enable()
        } else {
            logger('No geofence shape created, cannot edit');
        }
    }, [featureGroupRef, geofenceData, editToolRef, actionState]);

    const handleEditCancelClick = useCallback(() => {
        setActionState((prevState) => ({ ...prevState, editEnabled: false }))
        editToolRef.current.revertLayers()
        editToolRef.current.disable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionState, editToolRef])

    const handleEditSaveClick = useCallback(() => {
        setActionState((prevState) => ({ ...prevState, editEnabled: false, editSaved: true }))
        editToolRef.current.save()
        editToolRef.current.disable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionState, editToolRef])

    const handleDeleteClick = useCallback(() => {
        controlRefs[selectedShape.toLowerCase()].current.disable()
        editToolRef.current.disable()

        featureGroupRef.current.clearLayers()

        setSelectedShape(null)
        setGeofenceData(null)
        setSearchPlaceData({ address: '', latLong: {} })
        setActionState({
            shapeSelected: false,
            shapeCreated: false,
            editEnabled: false,
            editSaved: false,
        })

    }, [featureGroupRef, selectedShape, controlRefs, editToolRef])

    const handleAddressSelect = useCallback(({ address, latLong }) => {
        mapRef.current.setView([latLong.lat, latLong.lng], 18)
        setMapConfig({ ...mapConfig, zoom: 18, center: [latLong.lat, latLong.lng] })
        setSearchPlaceData({ address, latLong })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef, mapConfig])

    const calculateArea = useCallback(({ layer, layerType }) => {
        logger('Calculating area for layer', layer)
        if (layerType === 'circle') {
            const radius = layer.getRadius()
            if (typeof radius === 'number') {
                return Math.PI * radius * radius
            }
            logger('Invalid radius for circle', radius)
        } else if (layerType === 'polygon' || layerType === 'rectangle') {
            return L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
        }
        return 0
    }, [])

    const getCoordinates = useCallback((layer) => {
        logger('Getting coordinates for layer', layer)
        if (layer instanceof L.Circle) {
            const center = layer.getLatLng()
            const radius = layer.getRadius()
            return { center: [center.lat, center.lng], radius }
        } else if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
            return layer.getLatLngs()[0].map(({ lat, lng }) => [lat, lng])
        }
        return []
    }, [])

    const getMarkerCoordinates = useCallback((layer) => {
        logger('Getting marker coordiantes for layer', layer)
        if (layer instanceof L.Circle) {
            return layer.getLatLng()
        }

        if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
            const latLngs = layer.getLatLngs()[0]
            const latSum = latLngs.reduce((sum, latLng) => sum + latLng.lat, 0)
            const lngSum = latLngs.reduce((sum, latLng) => sum + latLng.lng, 0)
            const centerLat = latSum / latLngs.length
            const centerLng = lngSum / latLngs.length
            return { lat: centerLat, lng: centerLng }
        }

        return null
    }, [])

    const updateGeofenceData = useCallback(() => {
        logger('update geofence called', geofenceData)
        if (!geofenceData) {
            return onGeofenceChange(null)
        }

        const { layer, layerType } = geofenceData
        const geoJSON = layer.toGeoJSON()
        const area = calculateArea({ layer, layerType })
        const coordinates = getCoordinates(layer)
        const markerCoordinates = getMarkerCoordinates(layer)
        logger('calculated marker coordinates', markerCoordinates)

        onGeofenceChange({
            layerType,
            area,
            geoJSON,
            coordinates,
            markerCoordinates,
            address: searchPlaceData.address,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculateArea, getCoordinates, onGeofenceChange, geofenceData])

    return (
        <div className={styles.createMapWrapper}>
            <MapContainer
                style={{ height: '100%', width: '100%' }}
                center={[51.505, -0.09]}
                zoom={13}
                ref={mapRef}
                {...mapConfig}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                    maxZoom={mapConfig.maxZoom}
                    maxNativeZoom={mapConfig.maxNativeZoom}
                />
                {searchPlaceData.address && (
                    <Marker
                        position={[searchPlaceData.latLong.lat, searchPlaceData.latLong.lng]}
                        icon={locationPinIcon}
                    />
                )}
                <FeatureGroup ref={featureGroupRef}>
                    <DrawControl
                        enableMap={enableMap}
                        drawControlRef={drawControlRef}
                        editToolRef={editToolRef}
                        controlRefs={controlRefs}
                        featureGroupRef={featureGroupRef}
                        onLayerCreation={handleLayerCreation}
                        onLayerEdition={handleLayerEdition}
                    />
                </FeatureGroup>
            </MapContainer>

            {enableMap && (
                <div className={styles.controlsRow}>
                    <div className={styles.shapeControlsWrapper}>
                        <p>Draw fencing shape</p>
                        <div className={styles.shapeControls}>
                            <button
                                onClick={() => handleShapeSelect('Rectangle')}
                                className={cx(styles.shapeControl, { [styles.shapeControlSelected]: selectedShape === 'Rectangle' })}
                            >
                                <img src={selectedShape === 'Rectangle' ? '/images/svgicon/GeofenceTabicon/fenceRectangleActive.svg' : '/images/svgicon/GeofenceTabicon/fenceRectangle.svg'} alt='fence rectangle' title='Rectangle Shape' />
                            </button>

                            <button
                                onClick={() => handleShapeSelect('Circle')}
                                className={cx(styles.shapeControl, { [styles.shapeControlSelected]: selectedShape === 'Circle' })}
                            >
                                <img src={selectedShape === 'Circle' ? '/images/svgicon/GeofenceTabicon/fenceCircleActive.svg' : '/images/svgicon/GeofenceTabicon/fenceCircle.svg'} alt='fence circle' title='Circle Shape' />
                            </button>

                            <button
                                onClick={() => handleShapeSelect('Polygon')}
                                className={cx(styles.shapeControl, { [styles.shapeControlSelected]: selectedShape === 'Polygon' })}
                            >
                                <img src={selectedShape === 'Polygon' ? '/images/svgicon/GeofenceTabicon/fencePolygonActive.svg' : '/images/svgicon/GeofenceTabicon/fencePolygon.svg'} alt='fence polygon' title='Polygon Shape' />
                            </button>
                        </div>
                    </div>

                    <div className={styles.editControlsWrapper}>
                        <button
                            onClick={handleEditClick}
                            className={styles.editControl}
                            disabled={!actionState.shapeCreated}
                        >
                            <img src={actionState.shapeCreated ? '/images/svgicon/GeofenceTabicon/fenceEditActive.svg' : '/images/svgicon/GeofenceTabicon/fenceEdit.svg'} alt='fence edit' title='Edit Fence' />
                        </button>

                        <button
                            onClick={handleDeleteClick}
                            className={styles.editControl}
                            disabled={!actionState.shapeCreated}
                        >
                            <img src={actionState.shapeCreated ? '/images/svgicon/GeofenceTabicon/fenceDeleteActive.svg' : '/images/svgicon/GeofenceTabicon/fenceDelete.svg'} alt='fence delete' title='Delete Fence' />
                        </button>
                    </div>

                    {actionState.editEnabled && (
                        <div className={styles.editSaveControlsWrapper}>
                            <button
                                onClick={handleEditCancelClick}
                                className={styles.editControl}
                            >
                                Cancel
                            </button>

                            <button
                                onClick={handleEditSaveClick}
                                className={styles.editControl}
                            >
                                Save
                            </button>
                        </div>
                    )}
                </div>
            )}

            {enableMap && (
                <div className={styles.searchControl}>
                    <GFPlacesAutocomplete onAddressSelect={handleAddressSelect} />
                </div>
            )}
        </div>
    )
}

GFCreateMap.propTypes = {
    mapConfig: PropTypes.object.isRequired,
    enableMap: PropTypes.bool.isRequired,
    mapRef: PropTypes.object.isRequired,
    onGeofenceChange: PropTypes.func.isRequired,
}

export default GFCreateMap