import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import geofenceAction from '../../../store/actions/geofence';
import { mapper } from '../../../utils/mapper';

const fenceTypeIconMap = {
    CHARGING_DEPOT: '/images/svgicon/GeofenceTabicon/ChargingDepot.svg',
    CLIENT_LOCATION: '/images/svgicon/GeofenceTabicon/ClientHub.svg',
    RESTRICTED_AREA: '/images/svgicon/GeofenceTabicon/RestrictedArea.svg',
    OFFICE: '/images/svgicon/GeofenceTabicon/office.svg',
    SERVICE_CENTER: '/images/svgicon/GeofenceTabicon/ServiceCenter.svg',
    CITY_BOUNDARY: '/images/svgicon/GeofenceTabicon/CityBoundary.svg',
}

const withGeofenceHOC = (WrappedComponent) => {
    const GeofenceHOC = (props) => {
        const dispatch = useDispatch();
        const masters = useSelector(state => state.geofence.masters);
        const list = useSelector(state => state.geofence.list)
        const subscribedVehiclesByCI = useSelector(state => state.vehicle.subscribedVehiclesByCI)
        
        const fetchMasters = async ({ force = false } = {}) => {
            if (force || !masters) {
                const result = await geofenceAction.getMasters();

                const masters = result.data.data.masters
                const masterMap = {}

                masters.forEach(m => {
                    if (!masterMap[m.type]) {
                        masterMap[m.type] = []
                    }

                    masterMap[m.type].push(m)
                })

                dispatch(geofenceAction.setMasters(masterMap))
            }
            return true
        }

        const fetchGeofences = async ({ force = false } = {}) => {
            if (force || !list?.length) {
                const result = await geofenceAction.getGeofences();

                const geofences = result.data.data

                dispatch(geofenceAction.setGeofences(geofences))
            }
            return true
        }

        const refreshGeofences = async ({ withMaster = false } = {}) => {
            await fetchGeofences({ force: true })
            if (withMaster) {
                await fetchMasters({ force: true })
            }
        }

        useEffect(() => {
            fetchGeofences().then(fetchMasters)
            // eslint-disable-next-line
        }, [dispatch]);

        if (!masters) {
            return <></>
        }

        const mappedOptions = mapper?.mapVehicleDataOptions(subscribedVehiclesByCI)
        const mappedGeofences = mapper?.mapGeofencesWithMasters(list, masters)

        return <WrappedComponent
            {...props}
            masters={masters || {}}
            fenceTypeIconMap={fenceTypeIconMap}
            geofences={mappedGeofences || []}
            mappedOptionsByVehicle={mappedOptions}
            refreshGeofences={refreshGeofences}
        />
    };

    return GeofenceHOC;
};

export default withGeofenceHOC;
